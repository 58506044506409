// ========== Imports ========== //

import "./FormAnalysisTable.css";

import React, { useState, useEffect } from "react";
import Loggito from "../../../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { Spinner } from "../../../../Common";

// ========== Component ========== //

// This logger created outside of component so that it is also available to the helper printEvents
const logger = new Loggito("FormsTable");

function FormAnalysisTable({ calculatedScores }) {
  // ========== Hook consts ========== //

  const [tableData, setTableData] = useState(null);
  const [totalAdjustedScore, setTotalAdjustedScore] = useState(0);

  // ========== other consts ========== //

  // ========== useEffects ========== //

  useEffect(() => {
    if (calculatedScores && Object.keys(calculatedScores).length > 0) {
      generateTableData();
      setTotalAdjustedScore(calculatedScores.sectionAdjustedScores.total);
    }
  }, [calculatedScores]);

  const generateTableData = () => {
    const analysedResults = [
      {
        section: "Section A - Purpose",
        sectionScore: calculatedScores.sectionTotals.sectionA,
        possibleScore: "21",
        percentageScore:
          calculatedScores.sectionPercentageScores.sectionA * 100,
        adjustWith: 0.175,
        adjustedPercentageScore:
          calculatedScores.sectionAdjustedScores.sectionA,
      },
      {
        section: "Section B - Perseverance",
        sectionScore: calculatedScores.sectionTotals.sectionB,
        possibleScore: "42",
        percentageScore:
          calculatedScores.sectionPercentageScores.sectionB * 100,
        adjustWith: 0.175,
        adjustedPercentageScore:
          calculatedScores.sectionAdjustedScores.sectionB,
      },
      {
        section: "Section C - Growth Mindset",
        sectionScore: calculatedScores.sectionTotals.sectionC,
        possibleScore: "36",
        percentageScore:
          calculatedScores.sectionPercentageScores.sectionC * 100,
        adjustWith: 0.175,
        adjustedPercentageScore:
          calculatedScores.sectionAdjustedScores.sectionC,
      },
      {
        section: "Section D - Ownership",
        sectionScore: calculatedScores.sectionTotals.sectionD,
        possibleScore: "30",
        percentageScore:
          calculatedScores.sectionPercentageScores.sectionD * 100,
        adjustWith: 0.175,
        adjustedPercentageScore:
          calculatedScores.sectionAdjustedScores.sectionD,
      },
      {
        section: "Section E - Supportive-Demanding (Personal development)",
        sectionScore: calculatedScores.sectionTotals.sectionE,
        possibleScore: "48",
        percentageScore:
          calculatedScores.sectionPercentageScores.sectionE * 100,
        adjustWith: 0.1,
        adjustedPercentageScore:
          calculatedScores.sectionAdjustedScores.sectionE,
      },
      {
        section: "Section F - Supportive-Demanding (professional development)",
        sectionScore: calculatedScores.sectionTotals.sectionF,
        possibleScore: "48",
        percentageScore:
          calculatedScores.sectionPercentageScores.sectionF * 100,
        adjustWith: 0.2,
        adjustedPercentageScore:
          calculatedScores.sectionAdjustedScores.sectionF,
      },
    ];

    setTableData(analysedResults.map(rowTable));
  };

  function rowTable(formSection, key) {
    // ========== JSX ========== //
    return (
      <tr key={formSection.section}>
        <td className="p--left">{formSection.section}</td>
        <td>{formSection.sectionScore}</td>
        <td>{formSection.possibleScore}</td>
        <td>{formSection.percentageScore.toFixed(2)}%</td>
        <td>{formSection.adjustWith}</td>
        <td>{formSection.adjustedPercentageScore.toFixed(2)}%</td>
      </tr>
    );
  }

  // ========== JSX ========== //

  if (tableData !== "") {
    return (
      <div className="analysis-table">
        <h3 className="h3">Overall Scoring</h3>
        <div className="table-container">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Section</th>
                <th>Section Score</th>
                <th>Possible score</th>
                <th>% Score</th>
                <th>Adjust with</th>
                <th>Adj % Score</th>
              </tr>
            </thead>
            <tbody>
              {tableData ? tableData : null}

              <tr>
                <td>TOTAL SCORE:</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{totalAdjustedScore.toFixed(2)}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  } else {
    return <Spinner />;
  }
}

export default FormAnalysisTable;
