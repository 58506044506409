const Joi = require("joi");

function validateFormDataAutomated(requestData, explain = "form data") {
  const denestedData = {};

  for (const [key, value] of Object.entries(requestData)) {
    if (typeof value !== "object" || value === null) {
      denestedData[key] = value;
    } else {
      const prefix = key;
      for (const [nestedKey, nestedValue] of Object.entries(value)) {
        if (nestedKey === "response") {
          denestedData[key] = nestedValue;
        } else denestedData[prefix + "_" + nestedKey] = nestedValue;
      }
    }
  }

  const schema = Joi.object({
    // OTHER SCHEMA
    privacyPolicyConsent: Joi.boolean().allow(true).valid(true).messages({
      // TODO: add this any.only message to the other validators - used when value does not match allowed values, in this case 'true'
      "any.only": "You must agree to the privacy policy to proceed.",
      "string.base": "You must agree to the privacy policy to proceed",
      "string.empty": "You must agree to the privacy policy to proceed",
    }),
    detailsFormSectionApproved: Joi.string()
      .trim()
      .max(20)
      .allow("")
      .allow(null)
      .messages({
        "string.base": "form section approved not valid",
        "string.empty": "form section approved not valid",
        "string.max": "form section approved not valid",
      }),
    detailsStatus: Joi.string().trim().max(20).allow("").allow(null).messages({
      "string.base": "status details not valid",
      "string.empty": "status details not valid",
      "string.max": "status details not valid",
    }),
    surveyState: Joi.string().trim().max(20).allow("").allow(null).messages({
      "string.base": "survey state not valid",
      "string.empty": "survey state not valid",
      "string.max": "survey state not valid",
    }),
    // SCHEMA DEVELOPED FROM FORM SCHEMAS - DESIGNED TO ALLOW EMPTY STRINGS AND NULL VALUES
    detailsFullName: Joi.string()
      .trim()
      .max(100)
      .allow("")
      .allow(null)
      .messages({
        "string.base": "please write your full name",
        "string.empty": "please write your full name",
        "string.max": "your response must be 100 characters or less",
      }),
    detailsCandidateEmail: Joi.string()
      .trim()
      .pattern(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      )
      .max(254)
      .allow("")
      .allow(null)
      .messages({
        "string.pattern.base": `candidate email is not in the correct format`,
        "string.base": `candidate email is not the correct type`,
        "string.empty": `candidate email cannot be empty`,
        "string.max": "candidate email must be 254 characters or less",
      }),
    detailsOrganisation: Joi.string()
      .trim()
      .max(100)
      .allow("")
      .allow(null)
      .messages({
        "string.base":
          "please write the name of the organisation that you work for",
        "string.empty":
          "please write the name of the organisation that you work for",
        "string.max": "your response must be 100 characters or less",
      }),
    detailsRole: Joi.string()
      .trim()
      .max(100)
      .valid(
        "",
        null,
        "entry1",
        "entry2",
        "senior",
        "manager",
        "seniormanager",
        "managingdirector",
        "ceo"
      )
      .messages({
        "string.base": "please select your current role at work",
        "string.empty": "please select your current role at work",
        "string.max": "your response must be 100 characters or less",
      }),
    detailsGender: Joi.string().trim().max(100).allow("").allow(null).messages({
      "string.base": "please select a gender",
      "string.empty": "please select a gender",
      "string.max": "your response must be 100 characters or less",
    }),
    detailsAssessor: Joi.string()
      .trim()
      .max(100)
      .allow("")
      .allow(null)
      .messages({
        "string.base": "please write the full name of the assessor",
        "string.empty": "please write the full name of the assessor",
        "string.max": "your response must be 100 characters or less",
      }),
    detailsDateAssessed: Joi.string()
      .trim()
      .max(100)
      .allow("")
      .allow(null)
      .messages({
        "string.base": "please select a date",
        "string.empty": "please select a date",
        "string.max": "your response must be 100 characters or less",
      }),
    A1a: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    A1a_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    A1a_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    A1b: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    A1b_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    A1b_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    A2: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    A2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    A2_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    A3: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    A3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    A3_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    A4: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    A4_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    A4_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    A5: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    A5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    A5_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    A6: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    A6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    A6_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B1a: Joi.number().integer().allow(null, "").max(60).messages({
      "number.base": "please select a valid response",
      "number.empty": "please select a valid response",
    }),
    B1a_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B1a_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B1b: Joi.number().integer().allow(null, "").max(20).messages({
      "number.base": "please select a valid response",
      "number.empty": "please select a valid response",
    }),
    B1b_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B1b_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B1c: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    B1c_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B1c_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B1d: Joi.string().trim().max(100).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 100 characters",
      "string.empty":
        "please write your response to a maximum of 100 characters",
      "string.max": "your response must be 100 characters or less",
    }),
    B1d_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B1d_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B1e: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    B1e_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B1e_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B1f: Joi.string().trim().max(100).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 100 characters",
      "string.empty":
        "please write your response to a maximum of 100 characters",
      "string.max": "your response must be 100 characters or less",
    }),
    B1f_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B1f_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2a: Joi.string().trim().max(100).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 100 characters",
      "string.empty":
        "please write your response to a maximum of 100 characters",
      "string.max": "your response must be 100 characters or less",
    }),
    B2a_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2a_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2b: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    B2b_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2b_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2c: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    B2c_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2c_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2d: Joi.string().trim().max(100).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 100 characters",
      "string.empty":
        "please write your response to a maximum of 100 characters",
      "string.max": "your response must be 100 characters or less",
    }),
    B2d_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2d_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2e: Joi.string().trim().max(100).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 100 characters",
      "string.empty":
        "please write your response to a maximum of 100 characters",
      "string.max": "your response must be 100 characters or less",
    }),
    B2e_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2e_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2f: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    B2f_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2f_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2g: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    B2g_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2g_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2h: Joi.string().trim().max(100).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 100 characters",
      "string.empty":
        "please write your response to a maximum of 100 characters",
      "string.max": "your response must be 100 characters or less",
    }),
    B2h_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2h_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B3: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B3_comment: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B3_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B4: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B4_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B4_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B5: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B5_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B6: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B6_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B7: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B7_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B7_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B8: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B8_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B8_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B9: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B9_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B9_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B10: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B10_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B10_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B11: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B11_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B11_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B12: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B12_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B12_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C1: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C1_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C1_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C2: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C2_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C3: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C3_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C4: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C4_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C4_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C5: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C5_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C6: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C6_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C7: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C7_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C7_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C8: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C8_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C8_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C9: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C9_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C9_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C10: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C10_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C10_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C11: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C11_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C11_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C12: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C12_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C12_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D1: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D1_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D1_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D2: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D2_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D3: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D3_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D4: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D4_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D4_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D5: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3", "4")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D5_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    // TODO: TECHNICALLY D6 IS NOT USED BUT IT'S VALUE IS PASSE HERE
    // TODO: TECHNICALLY D6 IS NOT USED BUT IT'S VALUE IS PASSED HERE - D6 has comments and score but no response - D6a - e have no score or comment but have a response
    D6: Joi.string().trim().max(100).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 100 characters",
      "string.empty":
        "please write your response to a maximum of 100 characters",
      "string.max": "your response must be 100 characters or less",
    }),
    D6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D6_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D6a: Joi.string().trim().valid(true, false).messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D6a_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D6a_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D6b: Joi.string().trim().valid(true, false).messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D6b_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D6b_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D6c: Joi.string().trim().valid(true, false).messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D6c_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D6c_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D6d: Joi.string().trim().valid(true, false).messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D6d_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D6d_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D6e: Joi.string().trim().valid(true, false).messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D6e_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D6e_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D7: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D7_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D7_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D8: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D8_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D8_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D9: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D9_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D9_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D10: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D10_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D10_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E1: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E1_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E1_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E2: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E2_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E3: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E3_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E4: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E4_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E4_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E5: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E5_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E6: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E6_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E7: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E7_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E7_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E8: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E8_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E8_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E9: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E9_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E9_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E10: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E10_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E10_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E11: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E11_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E11_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E12: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E12_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E12_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E13: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E13_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E13_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E14: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E14_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E14_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E15: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E15_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E15_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E16: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    E16_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    E16_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F1: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F1_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F1_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F2: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F2_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F3: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F3_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F4: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F4_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F4_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F5: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F5_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F6: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F6_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F7: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F7_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F7_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F8: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F8_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F8_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F9: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F9_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F9_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F10: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F10_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F10_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F11: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F11_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F11_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F12: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F12_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F12_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F13: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F13_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F13_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F14: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F14_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F14_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F15: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F15_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F15_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F16: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F16_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F16_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    surveyQ1: Joi.string()
      .trim()
      // .max(1)
      // .valid(null, "", "0", "1", "2", "3", "4")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    surveyQ2: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    reportSummaryBarchart: Joi.string()
      .trim()
      .max(2000)
      .allow("")
      .allow(null)
      .messages({
        "string.base":
          "please write the summary to a maximum of 2000 characters",
        "string.empty":
          "please write the summary to a maximum of 2000 characters",
        "string.max": "your response must be 2000 characters or less",
      }),
    reportSummaryPersonalDevelopment: Joi.string()
      .trim()
      .max(2000)
      .allow("")
      .allow(null)
      .messages({
        "string.base":
          "please write the summary to a maximum of 2000 characters",
        "string.empty":
          "please write the summary to a maximum of 2000 characters",
        "string.max": "your response must be 2000 characters or less",
      }),
    reportSummaryProfessionalDevelopment: Joi.string()
      .trim()
      .max(2000)
      .allow("")
      .allow(null)
      .messages({
        "string.base":
          "please write the summary to a maximum of 2000 characters",
        "string.empty":
          "please write the summary to a maximum of 2000 characters",
        "string.max": "your response must be 2000 characters or less",
      }),
    reportSummaryOverall: Joi.string()
      .trim()
      .max(2000)
      .allow("")
      .allow(null)
      .messages({
        "string.base":
          "please write the summary to a maximum of 2000 characters",
        "string.empty":
          "please write the summary to a maximum of 2000 characters",
        "string.max": "your response must be 2000 characters or less",
      }),
    reportRecommendations: Joi.string().trim().max(2000).messages({
      "string.base": "please write the summary to a maximum of 2000 characters",
      "string.empty":
        "please write the summary to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
  });

  const result = schema.validate(denestedData);

  if (result.error) {
    throw new Error(
      `error with ${explain}: ${result.error.details[0].message}`
    );
  }

  return `${explain} validated successfully`;
}

export default validateFormDataAutomated;
