// ========== Imports ========== //

import "./FormAnalysisTable.css";

import React, { useState, useEffect } from "react";
import Loggito from "../../../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { Spinner } from "../../../../Common";

// ========== Component ========== //

// This logger created outside of component so that it is also available to the helper printEvents
const logger = new Loggito("FormsTable");

// Receives the eventId and return the table

// function FormAnalysisTableEF({ environment, handleUpdateScattergraphScores }) {
function FormAnalysisTableEF({ calculatedScores }) {
  // ========== Hook consts ========== //

  const [tableDataEF, setTableDataEF] = useState("");
  const [calculatedScoresArray, setCalculatedScoresArray] = useState([]);
  const [tableDataPersonalProfessional, setTableDataPersonalProfessional] =
    useState("");

  useEffect(() => {
    if (calculatedScores && Object.keys(calculatedScores).length > 0) {
      setCalculatedScoresArray([
        {
          section: "Section E - Supportive-Demanding (Personal development)",
          supportiveTotal: calculatedScores.sectionE.supportive,
          unsupportiveTotal: calculatedScores.sectionE.unsupportive,
          demandingTotal: calculatedScores.sectionE.demanding,
          undemandingTotal: calculatedScores.sectionE.undemanding,
        },
        {
          section:
            "Section F - Supportive-Demanding (professional development)",
          supportiveTotal: calculatedScores.sectionF.supportive,
          unsupportiveTotal: calculatedScores.sectionF.unsupportive,
          demandingTotal: calculatedScores.sectionF.demanding,
          undemandingTotal: calculatedScores.sectionF.undemanding,
        },
      ]);
      setTableDataPersonalProfessional(
        rowTablePersonalProfessional({
          supportivePersonalTotal: calculatedScores.sectionE.supportiveTotal,
          supportiveProfessionalTotal:
            calculatedScores.sectionF.supportiveTotal,
          demandingPersonalTotal: calculatedScores.sectionE.demandingTotal,
          demandingProfessionalTotal: calculatedScores.sectionF.demandingTotal,
        })
      );
    }
  }, [calculatedScores]);

  useEffect(() => {
    if (calculatedScoresArray.length > 0) {
      setTableDataEF(calculatedScoresArray.map(rowTableEF));
    }
  }, [calculatedScoresArray]);
  // ========== other consts ========== //

  // ========== useEffects ========== //

  function rowTableEF(formSection, key) {
    // ========== JSX ========== //
    return (
      <tbody>
        <tr key={formSection.section}>
          <td className="p--left">{formSection.section}</td>
          <td>{formSection.supportiveTotal}</td>
          <td>/12</td>
          <td>{formSection.unsupportiveTotal}</td>
          <td>/12</td>
          <td>{formSection.demandingTotal}</td>
          <td>/12</td>
          <td>{formSection.undemandingTotal}</td>
          <td>/12</td>
        </tr>
      </tbody>
    );
  }

  function rowTablePersonalProfessional(formTotals) {
    // ========== JSX ========== //
    return (
      <tbody>
        <tr key={1}>
          <td>Supportive</td>
          <td>{formTotals.supportivePersonalTotal}</td>
          <td>/24</td>
          <td>Supportive</td>
          <td>{formTotals.supportiveProfessionalTotal}</td>
          <td>/24</td>
        </tr>
        <tr key={2}>
          <td>Demanding</td>
          <td>{formTotals.demandingPersonalTotal}</td>
          <td>/24</td>
          <td>Demanding</td>
          <td>{formTotals.demandingProfessionalTotal}</td>
          <td>/24</td>
        </tr>
      </tbody>
    );
  }

  // ========== JSX ========== //

  if (tableDataEF !== "" || tableDataPersonalProfessional !== "") {
    return (
      <>
        {tableDataPersonalProfessional !== "" ? (
          <div className="analysis-table">
            <h3 className="h3">Section E and F Scoring:</h3>
            <div className="table-container">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th colspan="1"></th>
                    <th colspan="2">Supportive</th>
                    <th colspan="2">Unupportive</th>
                    <th colspan="2">Demanding</th>
                    <th colspan="2">Unemanding</th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Section</th>
                    <th>Score</th>
                    <th></th>
                    <th>Score</th>
                    <th></th>
                    <th>Score</th>
                    <th></th>
                    <th>Score</th>
                    <th></th>
                  </tr>
                </thead>
                {tableDataEF ? tableDataEF : null}
              </table>
            </div>
          </div>
        ) : null}
        {tableDataPersonalProfessional !== "" ? (
          <div className="analysis-table">
            <h3 className="h3">Supportive and Demanding Total Scores:</h3>
            <div className="table-container">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th colspan="3">E – (Personal development)</th>
                    <th colspan="3">F - (professional development)</th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th>Scale</th>
                    <th>Section Score</th>
                    <th>Possible score</th>
                    <th>Scale</th>
                    <th>Section Score</th>
                    <th>Possible score</th>
                  </tr>
                </thead>
                {tableDataPersonalProfessional
                  ? tableDataPersonalProfessional
                  : null}
              </table>
            </div>
          </div>
        ) : null}
      </>
    );
  } else {
    return <Spinner />;
  }
}

export default FormAnalysisTableEF;
