import React from "react";
import {
  Document,
  Font,
  Image,
  Link,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import Scattergraph4Quadrants from "./not used/Scattergraph4Quadrants_";

import castLogo from "../../../../../Assets/castLogo1.png";

// !! For the fontWeight attribute to work, the font must be registered, for example here with Open Sans,
//  The fonts can be uploaded to a folder, it might be interesting to be able to use this with google fonts

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300.ttf",
      fontWeight: 300,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300italic.ttf",
      fontWeight: 300,
      fontStyle: "italic",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-italic.ttf",
      fontStyle: "italic",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600italic.ttf",
      fontWeight: 600,
      fontStyle: "italic",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
      fontWeight: 700,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700italic.ttf",
      fontWeight: 700,
      fontStyle: "italic",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800italic.ttf",
      fontWeight: 800,
      fontStyle: "italic",
    },
  ],
});

/* Fonts supported out of the box:
Courier
Courier-Bold
Courier-Oblique
Courier-BoldOblique
Helvetica
Helvetica-Bold
Helvetica-Oblique
Helvetica-BoldOblique
Times-Roman
Times-Bold
Times-Italic
Times-BoldItalic */

/*
Font Weight: 
thin	Equals to value 100
ultralight	Equals to value 200
light	Equals to value 300
normal	Equals to value 400 Default
medium	Equals to value 500
semibold	Equals to value 600
bold	Equals to value 700
ultrabold	Equals to value 800
heavy	Equals to value 900 */

/* Valid units:
pt (default. Based on the standard 72 dpi PDF document)
in inches
mm millimeters
cm centimeters
% percentage
vw viewport/page width
vh viewport/page height */

const styles = StyleSheet.create({
  /*  --color-primary: #151a61;
    --color-primary-hover: #151a61;
    --color-secondary: #38a8ce;
    --color-secondary-hover: #38a8ce;
    --color-accent: #a3d063;
    --color-accent-hover: #a3d063; */
  body: {
    paddingTop: "35px",
    paddingBottom: "65px",
    paddingHorizontal: "35px",
    color: "#151a61",
    // TODO: declaring the fontFamily here was stopping the component from rendering when there is no internet connection - solution? store fonts in app
    // i tried uploading the fonts to Assets but it the component would no render
    fontFamily: "Open Sans",
    fontWeight: 400,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  candidateDetails: {
    width: "70%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "10px auto 0 0",
  },
  text: {
    margin: "2px 0",
    fontSize: 12,
    textAlign: "justify",
    fontWeight: 400,
    // color: "#3840a8",
  },
  gapS: {
    marginTop: "10px",
  },
  gapM: {
    marginTop: "20px",
  },
  gapL: {
    marginTop: "30px",
  },
  bullet: {
    margin: 0,
    marginLeft: "20px",
    fontSize: 12,
    textAlign: "justify",
    fontWeight: 400,
    // color: "#3840a8",
  },
  name: {
    margin: "8px 0",
    fontSize: 22,
    textAlign: "center",
  },
  description: {
    // margin: "8px",
    fontSize: 18,
    textAlign: "center",
  },

  h1: {
    margin: "8px 0 0",
    fontSize: 16,
    letterSpacing: 0,
    textDecoration: "underline",
    fontWeight: "bold",
    textAlign: "left",
  },
  h2: {
    margin: "8px 0 0",
    fontSize: 14,
    letterSpacing: 0,
    textDecoration: "underline",
    textAlign: "left",
    fontWeight: "bold",
  },
  h3: {
    margin: "8px 0 0",
    fontSize: 14,
    textAlign: "justify",
    fontWeight: "bold",
  },
  h4: {
    margin: "8px 0 0",
    fontSize: 12,
    textAlign: "justify",
    fontWeight: "bold",
  },
  h5: {
    margin: "8px 0",
    fontSize: 10,
    textAlign: "justify",
  },
  h6: {
    margin: "8px 0",
    fontSize: 8,
    textAlign: "justify",
  },
  image: {
    marginHorizontal: "20px",
    maxWidth: 250,
    margin: "0 auto",
    marginTop: "20px",
    marginBottom: "30px",
  },
  castLogo: {
    marginHorizontal: 20,
    maxWidth: 100,
    margin: "0 0 0 auto",
  },
  scattergraph: {
    marginHorizontal: 20,
    maxWidth: 350,
    margin: "0 auto 0 0",
    marginTop: 10,
    marginBottom: 0,
  },
  barchart: {
    marginHorizontal: 20,
    maxWidth: 450,
    margin: "0 auto 0 0",
    marginTop: 10,
    marginBottom: 0,
  },
  summarytables: {
    marginHorizontal: 20,
    maxWidth: 900,
    margin: "0 auto 0 0",
    marginTop: 0,
    marginBottom: 0,
  },
  /* header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  }, */
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    // left: 0,
    right: 35,
    textAlign: "center",
    color: "grey",
  },
  link: {
    margin: "8px 0",
    fontSize: 12,
    textAlign: "justify",
    color: "#a3d063",
    // fontWeight: "bold",
  },
  contact: {
    marginTop: "30px",
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 35,
    color: "grey",
  },
});

const ReportTemplateReactPdfAutomated = ({
  existingValues,
  scattergraphUrl,
  barchartUrl,
  overallScoringUrl,
  sectionEAndFScoringUrl,
}) => {
  /*   const parser = new DOMParser();
  const htmlParsed = parser.parseFromString(
    associateData.bodyHtml,
    "text/html"
  );
  console.log(htmlParsed);

  const elementArray = [];

  for (let i = 0; i < htmlParsed.body.children.length; i++) {
    elementArray[i] = htmlParsed.body.children[i];
  } */

  const addLineBreaks = (originalText = "") => {
    console.log("original text:" + originalText);
    let processedText = originalText;
    console.log("processed text:" + processedText);
    let textArray = [];

    let count = 0;
    for (let i = 0; i < originalText.length; i++) {
      if (originalText[i] === "\n") count = count + 1;
    }

    for (let i = 0; i < count + 1; i++) {
      // in the final loop processedText.indexOf("\n") === -1, so it must be dealt with differently
      if (i === count) {
        textArray.push(processedText);
      } else {
        textArray.push(processedText.slice(0, processedText.indexOf("\n")));
        processedText = processedText.slice(processedText.indexOf("\n") + 1);
      }
    }

    return textArray;
  };

  const Br = () => "\n";
  // The use this as a Br like any other except with a capital B <Br />

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Image style={styles.castLogo} src={castLogo} />
        <Text style={styles.h1}>CHARACTER ASSESSMENT & SELECTION TOOL</Text>
        <Text style={styles.h2}>SUMMARY REPORT</Text>
        <View style={styles.candidateDetails}>
          <View>
            <Text style={[styles.text, { fontWeight: 700 }]}>
              Name of client:
            </Text>
            <Text style={[styles.text, { fontWeight: 700 }]}>
              Company/Organisation:
            </Text>
            <Text style={[styles.text, { fontWeight: 700 }]}>
              Name of Assessor:
            </Text>
            <Text style={[styles.text, { fontWeight: 700 }]}>
              Date of assessment:
            </Text>
          </View>
          <View>
            <Text style={[styles.text, { fontWeight: 600 }]}>
              {existingValues.detailsFullName}
            </Text>
            <Text style={[styles.text, { fontWeight: 600 }]}>
              {existingValues.detailsOrganisation}
            </Text>
            {/* TODO: the name of Assessor is not entered in the candidate form, this should be added either by default or here in the report editor */}
            <Text style={[styles.text, { fontWeight: 600 }]}>
              {existingValues.detailsAssessor
                ? existingValues.detailsAssessor
                : "Patrick Tomlinson"}
            </Text>
            <Text style={[styles.text, { fontWeight: 600 }]}>
              {existingValues.detailsDateAssessed &&
                existingValues.detailsDateAssessed.slice(0, 10)}
            </Text>
          </View>
        </View>
        <View>
          {overallScoringUrl && (
            <Image style={styles.summarytables} src={overallScoringUrl} />
          )}
          {sectionEAndFScoringUrl && (
            <Image style={styles.summarytables} src={sectionEAndFScoringUrl} />
          )}
        </View>
        <Text style={styles.h3} break>
          Purpose, Ownership (Responsibility), Perseverance and Growth Mindset
        </Text>
        {barchartUrl && <Image style={styles.barchart} src={barchartUrl} />}
        <Text style={styles.h3}>Supportive-Demanding Scales</Text>
        {scattergraphUrl && (
          <Image
            style={styles.scattergraph}
            src={scattergraphUrl ? scattergraphUrl : ""}
          />
        )}

        <View style={styles.contact}>
          <Text style={[styles.text, { fontWeight: 700 }]}>
            Patrick Tomlinson Contact Details
          </Text>
          {/* TODO: change to Link component */}
          <Text style={styles.text}>Email: ptomassociates@gmail.com</Text>
        </View>
        <View style={styles.footer} fixed>
          {/* TODO: get the copywright symbol to show here */}
          <Text style={styles.text}>
            {"\u00A9"}© Copyright Patrick Tomlinson, November 2020
          </Text>
        </View>
        <Text
          fixed
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};

export default ReportTemplateReactPdfAutomated;
