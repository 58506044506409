// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSurvey,
  validateSurvey,
  validateSurveyAutoSave,
} from "../../../../validators";

import { QuestionMcqListHorizontal, QuestionTextBox } from "./FormQuestions";

import ConfirmSubmission from "./ConfirmSubmission";

// ========== Page ========== //

function SubmittedScreenSurvey({
  environment,
  onApproveSection,
  existingValues,
  setExistingValues,
  triggerHandleValidateSectionAssessor,
}) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});
  const [renderErrors, setRenderErrors] = useState(0);

  const [confirmSubmissionPopup, setConfirmSubmissionPopup] = useState(false);

  const [submitted, setSubmitted] = useState(null);

  const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    triggerHandleValidateSectionAssessor > 0 && handleValidateSectionAssessor();
  }, [triggerHandleValidateSectionAssessor]);

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    return () => {
      // This function will be called when the component is unmounted
      // saveFormData(null, null);
      handleValidateSectionAutoSave(null, null);
    };
  }, []);

  useEffect(() => {
    if (confirmSubmissionPopup === true) handleValidateSectionCandidate();
    if (confirmSubmissionPopup === true) setSubmitted(true);
  }, [confirmSubmissionPopup]);

  const params = useParams();
  let route = params.route;

  const location = useLocation();

  const {
    register: registerSurvey,
    handleSubmit: handleSubmitSurvey,
    reset,
    formState: { errors },
    getValues: getValuesSurvey,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSurvey),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  // TODO: this needs to be adjusted for nested loops
  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      const formIdsArray = ["surveyQ1", "surveyQ2"];
      for (const [key, value] of Object.entries(existingValues)) {
        if (key.slice(0, 6) === "survey")
          for (const [key, value2] of Object.entries(value)) {
            if (value2 && formIdsArray.includes(key)) setValue(key, value2, {});
          }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  // START SUBMIT AFTER TYPING - THIS SUBMITS THE USER'S RESPONSE AFTER THEY STOP TYPING

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 13 ||
      keyCode === 32 ||
      keyCode === 56 ||
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 96 && keyCode <= 111)
    ) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    // const delayFn = setTimeout(() => saveFormData(null, null), 3000);
    const delayFn = setTimeout(
      () => handleValidateSectionAutoSave(null, null),
      3000
    );
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keyup", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  // START SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const handleUserKeyPressAfterSubmit = useCallback(
    (event) => {
      const { key, keyCode } = event;

      if (
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 13 ||
        keyCode === 32 ||
        keyCode === 56 ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 96 && keyCode <= 111)
      ) {
        if (submitted) {
          if (environment === "assessor") {
            const errors = validateSurvey(getValuesSurvey());
            setErrorsAssessor(errors ? errors : {});
          } else if (environment === "candidate") {
            const errors = validateSurvey(getValuesSurvey());
            setErrorsCandidate(errors ? errors : {});
          }
        }
      }
    },
    [submitted]
  );

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPressAfterSubmit);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener(
        "keyup",
        handleUserKeyPressAfterSubmit
      );
    };
  }, [handleUserKeyPressAfterSubmit]);

  // END SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const saveFormData = async (approved, dismissed) => {
    // TODO: complete logic here for dismissing the form
    let values = {};
    if (dismissed === null && approved === "approved") {
      values = {
        ...getValuesSurvey(),
        surveyState: "submitted",
        detailsFormSectionApproved: "survey",
      };
    } else if (dismissed === "dismissed" && approved === "approved") {
      values = {
        surveyQ1: "",
        surveyQ2: "",
        surveyState: "dismissed",
        detailsFormSectionApproved: "survey",
      };
    } else
      values = {
        ...getValuesSurvey(),
      };

    console.log(values);

    let endpoint;

    if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    ) {
      endpoint = "candidateassessor";
    } else if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        values,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                if (sessionStorage.token) delete sessionStorage.token;
                if (sessionStorage.candidate_token)
                  delete sessionStorage.candidate_token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          process.env.REACT_APP_LOGGITO_LEVEL === "0" &&
            toast.success("Your response to the survey have been saved");
          // TODO: here the survey field is an object
          const surveyObject = {
            survey: { surveyQ1: values.surveyQ1, surveyQ2: values.surveyQ2 },
          };
          if (values.surveyState)
            surveyObject.survey.surveyState = values.surveyState;

          setExistingValues((prevValues) => ({
            ...prevValues,
            ...surveyObject,
          }));
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    setSubmitted(true);
    const formValues = getValuesSurvey();
    const errors = validateSurvey(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      // TODO: check this, should only update after submission is confirmed, no ever time the form submits
      saveFormData("approved", null);
      toast.success("Your response to the survey have been saved");
      if (environment === "candidate") onApproveSection("survey");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesSurvey();
    const errors = validateSurvey(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      // TODO: check this, should only update after submission is confirmed, not every time the form submits
      saveFormData("approved", null);
      toast.success("Your response to the survey have been saved");
      onApproveSection("survey");
      return;
    }
  };

  // in this functionsection the approved and dismissed values are passed, in all other sections the saveformData is called with null
  const handleValidateSectionAutoSave = (approved, dismissed) => {
    const formValues = getValuesSurvey();
    const validationErrors = validateSurveyAutoSave(formValues);
    if (validationErrors) {
      Object.keys(errors).forEach((key) => delete errors[key]);
      Object.keys(validationErrors).forEach(
        (key) => (validationErrors[key] = { message: validationErrors[key] })
      );
      Object.assign(errors, validationErrors);
      setRenderErrors(renderErrors + 1);
      return;
    } else {
      Object.keys(errors).forEach((key) => delete errors[key]);
      setRenderErrors(renderErrors + 1);
      saveFormData(approved, dismissed);
      return;
    }
  };

  const handleSubmitOnClick = () => {
    // saveFormData(null, null);
    handleValidateSectionAutoSave(null, null);
    if (submitted) {
      if (environment === "assessor") {
        const errors = validateSurvey(getValuesSurvey());
        setErrorsAssessor(errors ? errors : {});
      } else if (environment === "candidate") {
        const errors = validateSurvey(getValuesSurvey());
        setErrorsCandidate(errors ? errors : {});
      }
    }
  };

  const handleDismissSurvey = () => {
    // saveFormData("approved", "dismissed");
    handleValidateSectionAutoSave("approved", "dismissed");
    onApproveSection("survey");
  };

  const confirmSubmissionCandidate = (state) => {
    setConfirmSubmissionPopup(state);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section 1");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmitSurvey((data) => handleSubmitOnClick());
      }}
      className="cast-form cast-form--survey"
      noValidate={true}
    >
      <ConfirmSubmission
        confirmSubmissionPopup={confirmSubmissionPopup}
        onConfirmSubmissionCandidate={confirmSubmissionCandidate}
      />

      {(Object.keys(errorsAssessor).length !== 0 &&
        errorsAssessor.constructor === Object) ||
      (Object.keys(errorsCandidate).length !== 0 &&
        errorsCandidate.constructor === Object) ? (
        <div className="error-block">
          <IoWarningOutline className="icon" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="p--m">
            There are questions that require your attention.
          </p>
        </div>
      ) : null}
      <div className="cast-form__title-block">
        <h2 className="h2">Your opinions matter to us</h2>
        <p className="p--m">
          Please take a moment to respond to the questions below, your opinions
          matter to us and any comments provided will help us improve our
          service and provide a better product to our valued clients. Thank you!
        </p>
      </div>
      <QuestionMcqListHorizontal
        onSubmit={handleSubmitSurvey((data) => handleSubmitOnClick())}
        question={"surveyQ1"}
        questionText={
          "How would you rate your experience using the cast application? (1 = poor, 5 = excellent)."
        }
        register={registerSurvey}
        qErrors={errors.surveyQ1}
        labels={["1", "2", "3", "4", "5"]}
        section={"1"}
        qErrorsAssessor={errorsAssessor.surveyQ1}
        qErrorsCandidate={errorsCandidate.surveyQ1}
      />
      <QuestionTextBox
        question={"surveyQ2"}
        questionText={
          "Please leave any comments below, we would love to hear more about your experience:"
        }
        register={registerSurvey}
        qErrors={errors.surveyQ2}
        section={"1"}
        qErrorsAssessor={errorsAssessor.surveyQ2}
        qErrorsCandidate={errorsCandidate.surveyQ2}
        watch={watch("surveyQ2")}
      />
      <div className="survey-button-container">
        {formDataBeingSent ? (
          <button type="button" className="cta cta--accent--clicked">
            Submit
          </button>
        ) : null}
        {environment === "assessor" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleValidateSectionAssessor}
          >
            Submit
          </button>
        ) : null}
        {environment === "candidate" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={() => confirmSubmissionCandidate("active")}
          >
            Submit
          </button>
        ) : null}
        <button
          type="button"
          className="cta cta--secondary"
          onClick={handleDismissSurvey}
        >
          Dismiss
        </button>
      </div>
    </form>
  );
}

export default withContext(SubmittedScreenSurvey);
