// ========== imports ========== //

import { IoWarningOutline } from "react-icons/io5";

// ========== Page ========== //
function QuestionMcq14Strongly({
  question,
  questionText,
  register,
  qErrors,
  qErrorsAssessor,
  qErrorsCandidate,
  onSubmit,
  subquestion,
  mergedquestion,
  reversed = false,
}) {
  // ========== Hook consts ========== //

  return (
    /* ASSESSOR BLOCK - OUTSIDE Q CONTAINER */
    <div
      className={`cast-form__question-container ${
        subquestion ? "cast-form__question-container--subquestion" : null
      } ${
        mergedquestion ? "cast-form__question-container--mergedquestion" : null
      }`}
      id={`${question}_link`}
    >
      <div className="cast-form__question cast-form__question--radio cast-form__question--radio--strongly">
        <div className="cast-form__question__horizontal-layout">
          <p className="p--m cast-form__question__qtext">
            {<b>{question}</b>}: {questionText}
          </p>
          {reversed === true ? (
            <div className="radio-options" id={question}>
              <p className="p--sm p--centered">
                strongly
                <br />
                disagree
              </p>

              <div>
                <label htmlFor={question + "_0"} className="p--sm">
                  0
                </label>
                <input
                  className="input--radio"
                  type="radio"
                  id={question + "_0"}
                  name={question}
                  value={3}
                  {...register(question, {
                    onChange: (e) => {
                      onSubmit();
                    },
                  })}
                />
              </div>
              <div>
                <label htmlFor={question + "_1"} className="p--sm">
                  1
                </label>
                <input
                  className="input--radio"
                  type="radio"
                  id={question + "_1"}
                  name={question}
                  value={2}
                  {...register(question, {
                    onChange: (e) => {
                      onSubmit();
                    },
                  })}
                />
              </div>
              <div>
                <label htmlFor={question + "_2"} className="p--sm">
                  2
                </label>
                <input
                  className="input--radio"
                  type="radio"
                  id={question + "_2"}
                  name={question}
                  value={1}
                  {...register(question, {
                    onChange: (e) => {
                      onSubmit();
                    },
                  })}
                />
              </div>
              <div>
                <label htmlFor={question + "_3"} className="p--sm">
                  3
                </label>
                <input
                  className="input--radio"
                  type="radio"
                  id={question + "_3"}
                  name={question}
                  value={0}
                  {...register(question, {
                    onChange: (e) => {
                      onSubmit();
                    },
                  })}
                />
              </div>
              <p className="p--sm p--centered">
                strongly
                <br />
                agree
              </p>
            </div>
          ) : (
            <div className="radio-options" id={question}>
              <p className="p--sm p--centered">
                strongly
                <br />
                disagree
              </p>

              <div>
                <label htmlFor={question + "_0"} className="p--sm">
                  0
                </label>
                <input
                  className="input--radio"
                  type="radio"
                  id={question + "_0"}
                  name={question}
                  value={0}
                  {...register(question, {
                    onChange: (e) => {
                      onSubmit();
                    },
                  })}
                />
              </div>
              <div>
                <label htmlFor={question + "_1"} className="p--sm">
                  1
                </label>
                <input
                  className="input--radio"
                  type="radio"
                  id={question + "_1"}
                  name={question}
                  value={1}
                  {...register(question, {
                    onChange: (e) => {
                      onSubmit();
                    },
                  })}
                />
              </div>
              <div>
                <label htmlFor={question + "_2"} className="p--sm">
                  2
                </label>
                <input
                  className="input--radio"
                  type="radio"
                  id={question + "_2"}
                  name={question}
                  value={2}
                  {...register(question, {
                    onChange: (e) => {
                      onSubmit();
                    },
                  })}
                />
              </div>
              <div>
                <label htmlFor={question + "_3"} className="p--sm">
                  3
                </label>
                <input
                  className="input--radio"
                  type="radio"
                  id={question + "_3"}
                  name={question}
                  value={3}
                  {...register(question, {
                    onChange: (e) => {
                      onSubmit();
                    },
                  })}
                />
              </div>
              <p className="p--sm p--centered">
                strongly
                <br />
                agree
              </p>
            </div>
          )}
        </div>
        {/* ERROR BLOCK - WITHIN Q CONTAINER */}
        <div className="cast-form__error-message">
          {qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrors?.message}</p>
            </div>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsCandidate && !qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsCandidate}</p>
            </div>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsAssessor && !qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsAssessor}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuestionMcq14Strongly;
