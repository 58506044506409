// ========== imports ========== //
import { useState } from "react";

import { HiChevronRight } from "react-icons/hi";
import { IoWarningOutline } from "react-icons/io5";

// ========== Page ========== //
function QuestionAssessmentContainer({
  question,
  watch,
  scoreType,
  scoreExplanation,
  register,
  category,
  onSubmit,
  qErrorsAssessor,
  scoreMax = 3,
  scoreShown = true,
  scoreGuide,
}) {
  // ========== Hook consts ========== //
  const [isActiveComment, setIsActiveComment] = useState(false);
  const renderInputs = () => {
    const inputs = [];
    for (let i = 0; i <= scoreMax; i++) {
      inputs.push(
        <div key={i}>
          <label htmlFor={`${question}_${i}`} className="p--sm">
            {i}
          </label>
          <input
            className="input--radio"
            type="radio"
            id={`${question}_${i}`}
            name={question}
            value={i}
            {...register(`${question}_score`, {
              onChange: (e) => {
                onSubmit();
              },
            })}
          />
        </div>
      );
    }
    return inputs;
  };
  console.log("question:" + question);
  return (
    /* ASSESSOR BLOCK - OUTSIDE Q CONTAINER */

    <div
      className={`cast-form__question-assessment-container ${
        category === "supportive"
          ? "cast-form__question-assessment-container--supportive"
          : ""
      } ${
        category === "unsupportive"
          ? "cast-form__question-assessment-container--unsupportive"
          : ""
      } ${
        category === "demanding"
          ? "cast-form__question-assessment-container--demanding"
          : ""
      } ${
        category === "undemanding"
          ? "cast-form__question-assessment-container--undemanding"
          : ""
      } ${
        category === undefined
          ? "cast-form__question-assessment-container--standard"
          : ""
      }`}
      id={`${question}_link`}
    >
      {scoreType !== "Assessor scored" ? (
        <div className="cast-form__question-assessment">
          <p className="p--m">{scoreExplanation ? scoreType : ""}</p>
          <p className="p--m">
            <b>{question}</b>: {scoreType}{" "}
            {scoreType === "Automatically scored" &&
            scoreShown === true &&
            watch(question) ? (
              <span>
                - <span className="question-score">{watch(question)}</span>
              </span>
            ) : null}
          </p>
          {/* <div
            className={`collapsible ${
              isActiveComment ? "collapsible--expanded" : null
            }`}
          >
            <span className="p--m">Assessor Comments:&nbsp;&nbsp;&nbsp;</span>
            <div
              className="react-icon__container"
              onClick={() => setIsActiveComment(!isActiveComment)}
            >
              <HiChevronRight
                className={`p--xl react-icon__icon--small react-icon__icon--chevronright ${
                  isActiveComment && "react-icon__icon--active"
                }`}
              />
            </div>
            <div className="collapsible__content">
              <textarea
                id={question + "_comment"}
                {...register(question + "_comment", {})}
                s
                className="p--m"
              />
            </div>
          </div> */}
          {scoreGuide && (
            <div className="score-guide">
              <span className="p--m">
                Assessor Scoring Guide:&nbsp;&nbsp;&nbsp;
              </span>
              <span className="p--sm">
                {typeof scoreGuide === "string" ? <>{scoreGuide}</> : null}
                {typeof scoreGuide === "object"
                  ? scoreGuide.map((text, i) => {
                      return (
                        <li
                          className="cast-form__question__qtext__listitem"
                          key={
                            Date.now().toString() +
                            Math.floor(Math.random() * 1000000).toString()
                          }
                        >
                          {text}
                        </li>
                      );
                    })
                  : null}
              </span>
            </div>
          )}
          <div
            className={`cast-form__comments collapsible ${
              isActiveComment ? "collapsible--expanded" : null
            }`}
          >
            <span className="p--m">Assessor Comments:&nbsp;&nbsp;&nbsp;</span>
            <div
              className="react-icon__container"
              onClick={() => setIsActiveComment(!isActiveComment)}
            >
              <HiChevronRight
                className={`p--xl react-icon__icon--small react-icon__icon--chevronright ${
                  isActiveComment && "react-icon__icon--active"
                }`}
              />
            </div>
            <div className="collapsible__content">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  gap: "0.5rem",
                }}
              >
                <textarea
                  id={question + "_comment"}
                  {...register(question + "_comment", {})}
                  className="p--m"
                />
                {watch(question + "_comment") ||
                watch(question + "_comment") === "" ? (
                  watch(question + "_comment").length > 2000 ? (
                    <span style={{ marginLeft: "8px" }} className="p--xs">
                      <span style={{ color: "var(--color-accent)" }}>
                        {watch(question + "_comment").length}
                      </span>
                      /2000
                    </span>
                  ) : (
                    <span style={{ marginLeft: "8px" }} className="p--xs">
                      {watch(question + "_comment").length}/2000
                    </span>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {scoreType === "Assessor scored" ? (
        <div
          className=" cast-form__question--assessor cast-form__question--radio"
          id={`${question}_score_link`}
        >
          {/* <div className="cast-form__question cast-form__question--assessor cast-form__question--radio"> */}
          <div className="radio-options--assessor">
            <div className="cast-form__question-assessment">
              <p className="p--m">{scoreExplanation ? scoreType : ""}</p>
              <p className="p--m">
                <b>{question}</b>: {scoreType}{" "}
              </p>
            </div>
            {renderInputs()}
            {/*  <div>
              <label htmlFor={question + "_0"} className="p--sm">
                0
              </label>
              <input
                className="input--radio"
                type="radio"
                id={question + "_0"}
                name={question}
                value={"0"}
                {...register(question + "_score", {
                  onChange: (e) => {
                    onSubmit();
                  },
                })}
              />
            </div>
            <div>
              <label htmlFor={question + "_1"} className="p--sm">
                1
              </label>
              <input
                className="input--radio"
                type="radio"
                id={question + "_1"}
                name={question}
                value={"1"}
                {...register(question + "_score", {
                  onChange: (e) => {
                    onSubmit();
                  },
                })}
              />
            </div>
            <div>
              <label htmlFor={question + "_2"} className="p--sm">
                2
              </label>
              <input
                className="input--radio"
                type="radio"
                id={question + "_2"}
                name={question}
                value={"2"}
                {...register(question + "_score", {
                  onChange: (e) => {
                    onSubmit();
                  },
                })}
              />
            </div>
            <div>
              <label htmlFor={question + "_3"} className="p--sm">
                3
              </label>
              <input
                className="input--radio"
                type="radio"
                id={question + "_3"}
                name={question}
                value={"3"}
                {...register(question + "_score", {
                  onChange: (e) => {
                    onSubmit();
                  },
                })}
              />
            </div> */}
          </div>
          {scoreGuide && (
            <div className="score-guide">
              <span className="p--m">
                Assessor Scoring Guide:&nbsp;&nbsp;&nbsp;
              </span>
              <span className="p--sm">
                {typeof scoreGuide === "string" ? <>{scoreGuide}</> : null}
                {typeof scoreGuide === "object"
                  ? scoreGuide.map((text, i) => {
                      return (
                        <li
                          className="cast-form__question__qtext__listitem"
                          key={
                            Date.now().toString() +
                            Math.floor(Math.random() * 1000000).toString()
                          }
                        >
                          {text}
                        </li>
                      );
                    })
                  : null}
              </span>
            </div>
          )}

          <div className="cast-form__error-message">
            {qErrorsAssessor.score && (
              <div className="cast-form__error-message-container">
                <IoWarningOutline className="icon" />
                &nbsp;&nbsp;&nbsp;
                <p className="input__error-message">{qErrorsAssessor.score}</p>
              </div>
            )}
          </div>
          <div
            className={`cast-form__comments collapsible ${
              isActiveComment ? "collapsible--expanded" : null
            }`}
          >
            <span className="p--m">Assessor Comments:&nbsp;&nbsp;&nbsp;</span>
            <div
              className="react-icon__container"
              onClick={() => setIsActiveComment(!isActiveComment)}
            >
              <HiChevronRight
                className={`p--xl react-icon__icon--small react-icon__icon--chevronright ${
                  isActiveComment && "react-icon__icon--active"
                }`}
              />
            </div>
            <div className="collapsible__content">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  gap: "0.5rem",
                }}
              >
                <textarea
                  id={question + "_comment"}
                  {...register(question + "_comment", {})}
                  className="p--m"
                />
                {watch(question + "_comment") ||
                watch(question + "_comment") === "" ? (
                  watch(question + "_comment").length > 2000 ? (
                    <span style={{ marginLeft: "8px" }} className="p--xs">
                      <span style={{ color: "var(--color-accent)" }}>
                        {watch(question + "_comment").length}
                      </span>
                      /2000
                    </span>
                  ) : (
                    <span style={{ marginLeft: "8px" }} className="p--xs">
                      {watch(question + "_comment").length}/2000
                    </span>
                  )
                ) : null}
              </div>
            </div>
          </div>
          <div className="cast-form__error-message">
            {qErrorsAssessor.comment && (
              <div className="cast-form__error-message-container">
                <IoWarningOutline className="icon" />
                &nbsp;&nbsp;&nbsp;
                <p className="input__error-message">
                  {qErrorsAssessor.comment}
                </p>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default QuestionAssessmentContainer;
