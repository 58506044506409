const { FormatError } = require("errors");

const Joi = require("joi");

function validateSectionCCandidate(formValues) {
  // THE SCHEMA HERE HAS THE SAME STRUCTURE AS THE FORM SCHEMAS

  const schema = Joi.object({
    C1: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    C1_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C1_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C2: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    C2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C2_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C3: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    C3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C3_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C4: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    C4_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C4_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C5: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    C5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C5_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C6: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    C6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C6_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C7: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    C7_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C7_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C8: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    C8_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C8_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C9: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    C9_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C9_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C10: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    C10_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C10_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C11: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    C11_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C11_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    C12: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    C12_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    C12_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
  });

  const options = { abortEarly: false };

  const { error } = schema.validate(formValues, options);

  if (!error) return null;

  const errors = {};
  for (let item of error.details) {
    errors[item.path[0]] = item.message;
  }
  return errors;
}

export default validateSectionCCandidate;
