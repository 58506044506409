const { FormatError } = require("errors");

const Joi = require("joi");

function validateSectionBAutoSaveAutomated(formValues) {
  // THE SCHEMA HERE HAS THE SAME STRUCTURE AS THE FORM SCHEMAS

  const schema = Joi.object({
    // TODO: reomve the allow for the validate schema
    // TODO: check this number.base & number.empty ...
    B1a: Joi.number().integer().allow(null, "").max(60).messages({
      "number.base": "please select a valid response",
      "number.empty": "please select a valid response",
    }),
    B1a_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B1a_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B1b: Joi.number().integer().allow(null, "").max(20).messages({
      "number.base": "please select a valid response",
      "number.empty": "please select a valid response",
    }),
    B1b_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B1b_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B1c: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    B1c_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B1c_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B1d: Joi.string().trim().max(100).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 100 characters",
      "string.empty":
        "please write your response to a maximum of 100 characters",
      "string.max": "your response must be 100 characters or less",
    }),
    B1d_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B1d_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B1e: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    B1e_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B1e_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B1f: Joi.string().trim().max(100).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 100 characters",
      "string.empty":
        "please write your response to a maximum of 100 characters",
      "string.max": "your response must be 100 characters or less",
    }),
    B1f_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B1f_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2a: Joi.string().trim().max(100).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 100 characters",
      "string.empty":
        "please write your response to a maximum of 100 characters",
      "string.max": "your response must be 100 characters or less",
    }),
    B2a_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2a_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2b: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    B2b_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2b_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2c: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    B2c_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2c_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2d: Joi.string().trim().max(100).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 100 characters",
      "string.empty":
        "please write your response to a maximum of 100 characters",
      "string.max": "your response must be 100 characters or less",
    }),
    B2d_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2d_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2e: Joi.string().trim().max(100).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 100 characters",
      "string.empty":
        "please write your response to a maximum of 100 characters",
      "string.max": "your response must be 100 characters or less",
    }),
    B2e_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2e_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2f: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    B2f_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2f_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2g: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    B2g_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2g_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B2h: Joi.string().trim().max(100).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 100 characters",
      "string.empty":
        "please write your response to a maximum of 100 characters",
      "string.max": "your response must be 100 characters or less",
    }),
    B2h_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B2h_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B3: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B3_comment: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B3_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B4: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B4_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B4_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B5: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B5_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B6: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B6_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B7: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B7_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B7_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B8: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B8_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B8_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B9: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B9_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B9_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B10: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B10_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B10_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B11: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B11_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B11_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B12: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    B12_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    B12_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
  });

  const options = { abortEarly: false };

  const { error } = schema.validate(formValues, options);

  if (!error) return null;

  const errors = {};
  for (let item of error.details) {
    errors[item.path[0]] = item.message;
  }
  return errors;
}

export default validateSectionBAutoSaveAutomated;
