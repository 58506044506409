import Joi from "joi";

const schemaFormSectionD = Joi.object({
  D1: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  D1_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D1_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  D2: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  D2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D2_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  D3: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  D3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D3_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  D4: Joi.string().trim().max(1).valid(null, "", "0", "1", "2", "3").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  D4_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D4_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  D5: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3", "4")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  D5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D5_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  D6: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D6_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  D7a_1: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  D7a_1_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D7a_1_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  D7a_2: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  D7a_2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D7a_2_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  D7a_3: Joi.string().trim().max(1).valid(null, "", "Yes", "No").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  D7a_3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D7a_3_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  D7b: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D7b_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D7b_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  D8: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 100 characters",
    "string.empty": "please write your response to a maximum of 100 characters",
    "string.max": "your response must be 100 characters or less",
  }),
  D8_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D8_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  D9: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 100 characters",
    "string.empty": "please write your response to a maximum of 100 characters",
    "string.max": "your response must be 100 characters or less",
  }),
  D9_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D9_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  D10: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 100 characters",
    "string.empty": "please write your response to a maximum of 100 characters",
    "string.max": "your response must be 100 characters or less",
  }),
  D10_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  D10_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
});

export default schemaFormSectionD;
