// ========== Imports ========== //

import "./ConfirmSubmission.css";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Loggito from "../../../../utils/Loggito";
import { ServerError } from "errors";

// ========== Component ========== //

//Receives the eventId and try to delete that one in our database
function ConfirmSubmission({
  confirmSubmissionPopup,
  onConfirmSubmissionCandidate,
}) {
  // ========== Hook consts ========== //

  const [deleteDivStyle, setDeleteDivStyle] = useState({
    display: "none",
    position: "fixed",
    top: "50%",
    left: "50%",
    marginTop: "-50px",
    marginLeft: "-50px",
    width: "100px",
    height: "100px",
  });
  const [pageBlockStyle, setPageBlockStyle] = useState({
    display: "none",
  });

  // ========== other consts ========== //

  const logger = new Loggito("DeleteForm");

  window.Buffer = window.Buffer || require("buffer").Buffer;

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  useEffect(() => {
    changeDivStatus();
  }, [confirmSubmissionPopup]);

  // ========== Functions ========== //

  function refreshPage() {
    window.location.reload(false);
  }

  // ========== Function to block the screen and show the accept/decline popup ========== //

  function changeDivStatus() {
    if (confirmSubmissionPopup === "active") {
      setPageBlockStyle({
        display: "block",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        background: "black",
        opacity: "50%",
      });
      setDeleteDivStyle({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        color: "var(--color-primary-text)",
        flexDirection: "column",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "25px",
        width: "85%",
        maxWidth: "500px",
        height: "auto",
        borderRadius: "10px",
        gap: "20px",
      });
    } else {
      setDeleteDivStyle({ display: "none" });
      setPageBlockStyle({ display: "none" });
    }
  }

  // ========== Functions ========== //

  const confirmSubmission = () => {
    changeDivStatus();
    onConfirmSubmissionCandidate(true);
  };

  const negateSubmission = () => {
    changeDivStatus();
    onConfirmSubmissionCandidate(false);
  };

  // ========== JSX ========== //

  return (
    <div className="confirm-submission">
      <div style={pageBlockStyle}></div>
      <div style={deleteDivStyle} className="p--m">
        <h3 className="h3 p--centered">Have you reviewed your answers?</h3>
        <p className="p--centered">
          After submitting you will not be able to return to view, edit or
          review your responses for this section.
        </p>
        <p className="p--centered">
          Are you sure you want to submit your responses?
        </p>
        <div className="button-container">
          <button className="cta cta--accent" onClick={confirmSubmission}>
            Yes
          </button>
          <button className="cta cta--primary" onClick={negateSubmission}>
            No
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmSubmission;
