// ========== imports ========== //
import "../Forms/CASTForm.css";
import "./ReportEditor.css";

import React, { useRef, useState, useEffect } from "react";
import {
  FormAnalysisTable,
  FormAnalysisTableEF,
  ReportCommentsForm,
} from "./ReportEditorComponents";

import ReactPDF, { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

import ReportTemplateReactPdf from "./ReportEditorComponents/ReportTemplateReactPdf";
import ReportTemplateReactPdfAutomated from "./ReportEditorComponents/ReportTemplateReactPdfAutomated";
import ReportTemplateReactPdfHtml from "./ReportEditorComponents/ReportTemplateReactPdfHtml";
import ReportTemplateHTML from "./ReportEditorComponents/not used/ReportTemplateHTML";
import Scattergraph4Quadrants from "./ReportEditorComponents/Scattergraph4Quadrants";
import BarChartReport from "./ReportEditorComponents/BarChartReport";
import BarChart from "./ReportEditorComponents/not used/BarChart2";
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";

import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

import { useReactToPrint } from "react-to-print";
import Html2Pdf from "js-html2pdf";

import html2canvas from "html2canvas";

import { useParams, useNavigate } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { getFormResponse, getFormScores } from "../../../../logic";

import { toast } from "react-toastify";

import { ServerError } from "errors";

// ========== Page ========== //

function ReportEditor() {
  // ========== Hook consts ========== //

  const [existingValues, setExistingValues] = useState({});
  const [calculatedScores, setCalculatedScores] = useState({});

  const [isExpandedAutomated, setIsExpandedAutomated] = useState(false);
  const [isExpandedWritten, setIsExpandedWritten] = useState(false);

  const toggleExpandAutomated = () => {
    setIsExpandedAutomated(!isExpandedAutomated);
  };

  const toggleExpandWritten = () => {
    setIsExpandedWritten(!isExpandedWritten);
  };

  // const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    getFormResponseData();
    getFormScoresData();
  }, []);

  // TODO: To connect to the Flask server on page load to automatically generate report - in reality tis will be done on button press
  // Start call to Flask server

  /*   useEffect(() => {
    getReportPDFPython();
  }, []);

  const getReportPDFPython = async () => {
    try {
      const response = await fetch(`http://127.0.0.1:5000/get-report-pdf`);
      console.log(response);
      const data = await response.json();
      console.log(data);
      toast.success("Report PDF retrieved successfully from Flask");
    } catch (error) {
      toast.warn(error.message);
      // toast.warn("an error occurred when fetching the report pdf");
    }
  };
 */
  // End call to Flask server

  const componentRef = useRef(null);

  const overallScoringRef = useRef();
  const sectionEAndFScoringRef = useRef();
  const [overallScoringUrl, setOverallScoringUrl] = useState({});
  const [sectionEAndFScoringUrl, setSectionEAndFScoringUrl] = useState({});
  const scattergraphRef = useRef();
  const barchartRef = useRef();
  const [scattergraphUrl, setScattergraphUrl] = useState({});
  const [barchartUrl, setBarchartUrl] = useState({});
  const [barchartScores, setBarchartScores] = useState({});
  const [scattergraphScores, setScattergraphScores] = useState({});

  useEffect(() => {
    if (calculatedScores && Object.keys(calculatedScores).length > 0) {
      setScattergraphScores({
        supportivePersonalTotal: calculatedScores.sectionE.supportiveTotal,
        supportiveProfessionalTotal: calculatedScores.sectionF.supportiveTotal,
        demandingPersonalTotal: calculatedScores.sectionE.demandingTotal,
        demandingProfessionalTotal: calculatedScores.sectionF.demandingTotal,
      });
      // toFixed(2) returns a string so the number should be parsed to a float, although it seems the bar chart work with both numbers and string numbers
      setBarchartScores({
        purpose: parseFloat(
          (
            (calculatedScores.sectionPercentageScores.sectionA * 100) /
            20
          ).toFixed(2)
        ),
        perseverance: parseFloat(
          (
            (calculatedScores.sectionPercentageScores.sectionB * 100) /
            20
          ).toFixed(2)
        ),
        growthMindset: parseFloat(
          (
            (calculatedScores.sectionPercentageScores.sectionC * 100) /
            20
          ).toFixed(2)
        ),
        ownership: parseFloat(
          (
            (calculatedScores.sectionPercentageScores.sectionD * 100) /
            20
          ).toFixed(2)
        ),
      });
    }
  }, [calculatedScores]);

  useEffect(() => {
    setTimeout(() => {
      handleDownloadImage();
    }, "3000");
  }, []);

  // ! this saves the graph but without the points - a setTimeout allows the component time to render, biut not a foolproof soplution (min approx 200ms)
  const handleDownloadImage = async () => {
    try {
      const scattergraphElement = scattergraphRef.current;
      // ! passing the ref directlt like this prints two images
      // const canvas = await html2canvas(scattergraphRef.current);
      const canvas = await html2canvas(scattergraphElement, { scale: 3 });
      const scattergraphDataUrl = canvas.toDataURL("image/jpg");

      setScattergraphUrl(scattergraphDataUrl);

      const barchartElement = barchartRef.current;
      const canvas2 = await html2canvas(barchartElement, { scale: 3 });
      const barchartDataUrl = canvas2.toDataURL("image/jpg");

      setBarchartUrl(barchartDataUrl);

      const overallScoringElement = overallScoringRef.current;
      const canvas3 = await html2canvas(overallScoringElement, { scale: 3 });
      const overallScoringDataUrl = canvas3.toDataURL("image/jpg");

      setOverallScoringUrl(overallScoringDataUrl);

      const sectionEAndFScoringElement = sectionEAndFScoringRef.current;
      const canvas4 = await html2canvas(sectionEAndFScoringElement, {
        scale: 3,
      });
      const sectionEAndFScoringDataUrl = canvas4.toDataURL("image/jpg");

      setSectionEAndFScoringUrl(sectionEAndFScoringDataUrl);
    } catch (error) {
      // add error handling - this is producing an error here - investigate why
      TODO: console.error(error);
    }
  };

  // const params = useParams();

  const params = useParams();
  const navigate = useNavigate();
  let route = params.route;
  const environment = params.environment;
  console.log(environment);

  const getFormResponseData = () => {
    console.log(route);
    try {
      getFormResponse(
        sessionStorage,
        route,
        "assessor",
        (error, formDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              navigate("/admin/");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              navigate("/admin/");
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                delete sessionStorage.token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          setExistingValues(formDataRetrieved);
        }
      );
    } catch (error) {
      navigate("/admin/");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  const getFormScoresData = () => {
    console.log(route);
    try {
      getFormScores(
        sessionStorage,
        route,
        (error, calculatedScoresRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              navigate("/admin/");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              navigate("/admin/");
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                delete sessionStorage.token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          setCalculatedScores(calculatedScoresRetrieved);
        }
      );
    } catch (error) {
      navigate("/admin/");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  // ========== other consts ========== //

  const logger = new Loggito("CAST Form Dev");

  const handlePrint = useReactToPrint({
    documentTitle: existingValues.detailsFullName,
    contentRef: componentRef,
  });

  // TODO: this work to automatically download the pdf, but the formatting is not the same and seems a bit buggy compared to the native react-to-print download window

  /*   const handlePrint = useReactToPrint({
    documentTitle: existingValues.detailsFullName,
    contentRef: componentRef,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementsByClassName("pdf-document")[0];
        const options = {
          margin: 0,
          filename: "the-joys-of-buying-over-building.pdf",
        };
        const exporter = new Html2Pdf(html, options);
        await exporter.getPdf(options);
      }
    },
  }); */

  /*   const handlePrintDebug = () => {
    debugger;
    handlePrint();
  }; */

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <div className="report-editor">
      <div className="section cast-form-container">
        <h2 className="h2">Manage Report</h2>
        <PDFDownloadLink
          document={
            <ReportTemplateReactPdfAutomated
              scattergraphUrl={scattergraphUrl}
              barchartUrl={barchartUrl}
              overallScoringUrl={overallScoringUrl}
              sectionEAndFScoringUrl={sectionEAndFScoringUrl}
              existingValues={existingValues}
            />
          }
          // TODO: update this file name with the candidate's name or form ID
          // filename={existingValues.route + "_automated.pdf"}
          filename="automated_report.pdf"
        >
          {({ loading }) =>
            loading ? (
              <button className="cta cta--primary">Loading PDF Report</button>
            ) : (
              // TODO: try to get this to open in a new window
              <a
                className="cta cta--secondary"
                href={ReportTemplateReactPdfAutomated}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Automated Report
              </a>
            )
          }
        </PDFDownloadLink>

        <div className="pdf-preview-container">
          {isExpandedAutomated ? (
            <button className="cta cta--accent" onClick={toggleExpandAutomated}>
              Hide Automated PDF Preview
            </button>
          ) : (
            <button
              className="cta cta--primary"
              onClick={toggleExpandAutomated}
            >
              Show Automated PDF Preview
            </button>
          )}
          {isExpandedAutomated && (
            <PDFViewer className="pdf-viewer">
              <ReportTemplateReactPdfAutomated
                existingValues={existingValues}
                scattergraphUrl={scattergraphUrl}
                overallScoringUrl={overallScoringUrl}
                sectionEAndFScoringUrl={sectionEAndFScoringUrl}
                barchartUrl={barchartUrl}
              />
            </PDFViewer>
          )}
        </div>
        <div ref={overallScoringRef}>
          <FormAnalysisTable calculatedScores={calculatedScores} />
        </div>
        <div ref={sectionEAndFScoringRef}>
          <FormAnalysisTableEF calculatedScores={calculatedScores} />
        </div>
      </div>
      {/* This link generates and downloads a pdf automatically, the rename filename part is not working */}
      {/* <ReportTemplateHTML
          ref={componentRef}
          scattergraphUrl={scattergraphUrl}
          barchartUrl={barchartUrl}
          existingValues={existingValues}
        /> */}

      {/* This link opens uses the component which allows for full HTML markup. The library also works with React components - see npm documentation react-pdf-html */}
      {/* <PDFDownloadLink
          document={
            <ReportTemplateReactPdfHtml
              scattergraphUrl={scattergraphUrl}
              barchartUrl={barchartUrl}
              existingValues={existingValues}
            />
          }
          filename={"PDF created from HTML template.pdf"}
        >
          {({ loading }) =>
            loading ? (
              <button>Loading PDF Report</button>
            ) : (
              <a
                className="cta cta--secondary"
                href={ReportTemplateReactPdfHtml}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download PDF Report from HTML
              </a>
            )
          }
        </PDFDownloadLink> */}

      {/* TODO:  */}
      {/*   <div
          className="scattergraph-container"
          ref={scattergraphRef}
          id="scattergraph"
        >
          <Scattergraph4Quadrants />
        </div>
        <button
          className="cta cta--secondary"
          onClick={() =>
            exportComponentAsJPEG(scattergraphRef, { fileName: "scattergraph" })
          }
        >
          Export As JPEG
        </button>
        <div className="barchart-container" ref={barchartRef} id="barchart">
          <BarChartReport />
        </div>
        <button
          className="cta cta--secondary"
          onClick={() =>
            exportComponentAsJPEG(barchartRef, { fileName: "barchart" })
          }
        >
          Export As JPEG
        </button> */}
      <ReportCommentsForm
        environment={environment}
        existingValues={existingValues}
        scattergraphRef={scattergraphRef}
        barchartRef={barchartRef}
        barchartScores={barchartScores}
        scattergraphScores={scattergraphScores}
      />
      <PDFDownloadLink
        document={
          <ReportTemplateReactPdf
            scattergraphUrl={scattergraphUrl}
            barchartUrl={barchartUrl}
            existingValues={existingValues}
          />
        }
        // TODO: update this file name with the candidate's name or form ID
        filename={existingValues.route + "_written.pdf"}
      >
        {({ loading }) =>
          loading ? (
            <button className="cta cta--primary">Loading PDF Report</button>
          ) : (
            // TODO: try to get this to open in a new window
            <a
              className="cta cta--secondary"
              href={ReportTemplateReactPdf}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Written PDF Report
            </a>
          )
        }
      </PDFDownloadLink>

      <div className="pdf-preview-container">
        {isExpandedWritten ? (
          <button className="cta cta--accent" onClick={toggleExpandWritten}>
            Hide Written PDF Preview
          </button>
        ) : (
          <button className="cta cta--primary" onClick={toggleExpandWritten}>
            Show Written PDF Preview
          </button>
        )}
        {isExpandedWritten && (
          <PDFViewer className="pdf-viewer">
            <ReportTemplateReactPdf
              existingValues={existingValues}
              scattergraphUrl={scattergraphUrl}
              barchartUrl={barchartUrl}
            />
          </PDFViewer>
        )}
      </div>
    </div>
  );
}

export default withContext(ReportEditor);
