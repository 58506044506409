import Joi from "joi";

const schemaReportCommentsForm = Joi.object({
  detailsFullName: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write your full name",
    "string.empty": "please write your full name",
    "string.max": "your response must be 100 characters or less",
  }),
  detailsOrganisation: Joi.string()
    .trim()
    .max(100)
    .allow("")
    .allow(null)
    .messages({
      "string.base":
        "please write the name of the organisation that you work for",
      "string.empty":
        "please write the name of the organisation that you work for",
      "string.max": "your response must be 100 characters or less",
    }),
  detailsAssessor: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write the full name of the assessor",
    "string.empty": "please write the full name of the assessor",
    "string.max": "your response must be 100 characters or less",
  }),
  detailsDateAssessed: Joi.string()
    .trim()
    .max(100)
    .allow("")
    .allow(null)
    .messages({
      "string.base": "please select a date",
      "string.empty": "please select a date",
    }),
  reportSummaryBarchart: Joi.string()
    .trim()
    .max(2000)
    .allow("")
    .allow(null)
    .messages({
      "string.base": "please write the summary to a maximum of 2000 characters",
      "string.empty":
        "please write the summary to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
  reportSummaryPersonalDevelopment: Joi.string()
    .trim()
    .max(2000)
    .allow("")
    .allow(null)
    .messages({
      "string.base": "please write the summary to a maximum of 2000 characters",
      "string.empty":
        "please write the summary to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
  reportSummaryProfessionalDevelopment: Joi.string()
    .trim()
    .max(2000)
    .allow("")
    .allow(null)
    .messages({
      "string.base": "please write the summary to a maximum of 2000 characters",
      "string.empty":
        "please write the summary to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
  reportSummaryOverall: Joi.string()
    .trim()
    .max(2000)
    .allow("")
    .allow(null)
    .messages({
      "string.base": "please write the summary to a maximum of 2000 characters",
      "string.empty":
        "please write the summary to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
  reportRecommendations: Joi.string()
    .trim()
    .max(2000)
    .allow("")
    .allow(null)
    .messages({
      "string.base": "please write the summary to a maximum of 2000 characters",
      "string.empty":
        "please write the summary to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
});

export default schemaReportCommentsForm;
