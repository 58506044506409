import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter as Router } from "react-router-dom";

// GOOGLE ANALYTICS ROUTING
// import ReactDOM from "react-dom";
// import ReactGA from "react-ga";
import ReactGA from "react-ga4";

// TEST
ReactGA.initialize(process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID);

// GOOGLE ANALYTICS ROUTING
/*   useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []); */

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Router>
    <App />
  </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
