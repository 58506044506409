// ========== imports ========== //

import { useEffect, useState } from "react";

import { IoWarningOutline } from "react-icons/io5";

// ========== Page ========== //
function QuestionCheckboxList({
  question,
  questionText,
  register,
  qErrors,
  qErrorsAssessor,
  qErrorsCandidate,
  labels,
  onSubmit,
  environment,
  subquestion,
  mergedquestion,
}) {
  // ========== Hook consts ========== //

  // FISHE-YATES ALGORITHM TO COMPLETLY RANDOMLY SHUFFLE ARRAY
  /*   console.log(`labels before: ${labels}`);
  debugger; */
  const [labelsCandidate, setLabelsCandidate] = useState([]);

  useEffect(() => {
    if (environment === "candidate") {
      const labelsCandidateTemp = createLabelsScoreArray(labels);
      shuffleArray(labelsCandidateTemp);
      setLabelsCandidate(labelsCandidateTemp);
    }
  }, []);

  const createLabelsScoreArray = (array) => {
    const tempArray = [];
    labels.forEach((label, i) => {
      tempArray.push([label, i]);
    });
    return tempArray;
  };

  // const labelsCandidate = createLabelsScoreArray(labels);

  const shuffleArray = (labelsCandidate) => {
    for (let i = labelsCandidate.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = labelsCandidate[i];
      labelsCandidate[i] = labelsCandidate[j];
      labelsCandidate[j] = temp;
    }
  };

  return (
    <div
      className={`cast-form__question-container ${
        subquestion ? "cast-form__question-container--subquestion" : null
      } ${
        mergedquestion ? "cast-form__question-container--mergedquestion" : null
      }`}
      id={`${question}_link`}
    >
      <div className="cast-form__question cast-form__question--radio--column">
        <p className="p--m cast-form__question__qtext">
          {<b>{question}</b>}: {questionText}
        </p>
        <div className="radio-options" id={question}>
          {labels.map((label, i) => {
            return (
              <li
                className="cast-form__question__qtext__listitem"
                key={
                  Date.now().toString() +
                  Math.floor(Math.random() * 1000000).toString()
                }
              >
                <input
                  className="input--radio"
                  type="checkbox"
                  id={label[0]}
                  name={label[0]}
                  // value={}
                  // onChange={debug}
                  {...register(label[0], {
                    onChange: (e) => {
                      onSubmit();
                    },
                  })}
                />
                <label htmlFor={label[0]} className="p--sm">
                  {label[1]}
                </label>
              </li>
            );
          })}
        </div>

        <div className="cast-form__error-message">
          {qErrors.D6 && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrors.D6?.message}</p>
            </div>
          )}
          {qErrors.D6a && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrors.D6a?.message}</p>
            </div>
          )}
          {qErrors.D6b && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrors.D6b?.message}</p>
            </div>
          )}
          {qErrors.D6c && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrors.D6c?.message}</p>
            </div>
          )}
          {qErrors.D6d && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrors.D6d?.message}</p>
            </div>
          )}
          {qErrors.D6e && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrors.D6e?.message}</p>
            </div>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsCandidate.D6 && !qErrors.D6 && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsCandidate.D6}</p>
            </div>
          )}
          {qErrorsCandidate.D6a && !qErrors.D6a && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsCandidate.D6a}</p>
            </div>
          )}
          {qErrorsCandidate.D6b && !qErrors.D6b && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsCandidate.D6b}</p>
            </div>
          )}
          {qErrorsCandidate.D6c && !qErrors.D6c && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsCandidate.D6c}</p>
            </div>
          )}
          {qErrorsCandidate.D6d && !qErrors.D6d && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsCandidate.D6d}</p>
            </div>
          )}
          {qErrorsCandidate.D6e && !qErrors.D6e && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsCandidate.D6e}</p>
            </div>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsAssessor.D6 && !qErrors.D6 && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsAssessor.D6}</p>
            </div>
          )}
          {qErrorsAssessor.D6a && !qErrors.D6a && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsAssessor.D6a}</p>
            </div>
          )}
          {qErrorsAssessor.D6b && !qErrors.D6b && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsAssessor.D6b}</p>
            </div>
          )}
          {qErrorsAssessor.D6c && !qErrors.D6c && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsAssessor.D6c}</p>
            </div>
          )}
          {qErrorsAssessor.D6d && !qErrors.D6d && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsAssessor.D6d}</p>
            </div>
          )}
          {qErrorsAssessor.D6e && !qErrors.D6e && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsAssessor.D6e}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuestionCheckboxList;
