// ========== imports ========== //
import { useState } from "react";

import { HiChevronRight } from "react-icons/hi";
import { IoWarningOutline } from "react-icons/io5";

// ========== Page ========== //
function QuestionDetailsAdmin({
  question,
  watch,
  scoreExplanation,
  category,
  scoreMax = 3,
  scoreShown = true,
  scoreGuide,
  calculatedScore,
}) {
  // ========== Hook consts ========== //

  return (
    /* ASSESSOR BLOCK - OUTSIDE Q CONTAINER */

    <div
      className={`cast-form__question-assessment-container ${
        category === "supportive"
          ? "cast-form__question-assessment-container--supportive"
          : ""
      } ${
        category === "unsupportive"
          ? "cast-form__question-assessment-container--unsupportive"
          : ""
      } ${
        category === "demanding"
          ? "cast-form__question-assessment-container--demanding"
          : ""
      } ${
        category === "undemanding"
          ? "cast-form__question-assessment-container--undemanding"
          : ""
      } ${
        category === undefined
          ? "cast-form__question-assessment-container--standard"
          : ""
      }`}
      id={`${question}_link`}
    >
      <div className="cast-form__question-assessment">
        <p className="p--m">
          {watch(question) ? (
            <span>
              {question} -{" "}
              <span className="question-score">{watch(question)}</span>
            </span>
          ) : null}
          {calculatedScore || calculatedScore === 0 ? (
            <span>
              {watch(question) ? (
                <span>&nbsp;&nbsp;&nbsp;</span>
              ) : (
                question + " - "
              )}
              Calculated score -{" "}
              <span className="question-score">{calculatedScore}</span>
            </span>
          ) : null}
        </p>
        {scoreGuide && (
          <div className="score-guide">
            <span className="p--m">
              Assessor Scoring Guide:&nbsp;&nbsp;&nbsp;
            </span>
            <span className="p--sm">
              {typeof scoreGuide === "string" ? <>{scoreGuide}</> : null}
              {typeof scoreGuide === "object"
                ? scoreGuide.map((text, i) => {
                    return (
                      <li
                        className="cast-form__question__qtext__listitem"
                        key={
                          Date.now().toString() +
                          Math.floor(Math.random() * 1000000).toString()
                        }
                      >
                        {text}
                      </li>
                    );
                  })
                : null}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default QuestionDetailsAdmin;
