import Joi from "joi";

const schemaFormAccessForm = Joi.object({
  route: Joi.string()
    .trim()
    .min(11)
    .max(11)
    // TODO: add regex pattern for access code
    // .pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
    .required()
    .messages({
      "string.pattern.base": "form ID is not the correct format",
      "string.base": "please enter a valid form ID",
      "string.empty": "please enter a valid form ID",
      "string.max": "please enter a valid form ID",
      "string.min": "please enter a valid form ID",
    }),
  accessCode: Joi.string()
    .trim()
    .min(6)
    .max(6)
    // TODO: add regex pattern for access code
    // .pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/)
    .required()
    .messages({
      "string.pattern.base": "access code is not the correct format",
      "string.base": "please enter a valid access code",
      "string.empty": "please enter a valid access code",
      "string.max": "please enter a valid access code",
      "string.min": "please enter a valid access code",
    }),
});

export default schemaFormAccessForm;
