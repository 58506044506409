import React from "react";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";

import Html from "react-pdf-html";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    color: "#151a61",
    fontFamily: "Helvetica",
  },
});

const ReportTemplateReactPdfHtml = ({
  existingValues,
  scattergraphUrl,
  barchartUrl,
}) => {
  const html = `<html class="pdf-document">
  <body class="pdf-content">
    <style>
    .pdf-document {
      background-color: #ffffff;
      font-family: Roboto;
      /* padding: 30px; */
    }
    
    .pdf-document .pdf-content {
      height: 90%;
      width: 90%;
      margin: 30px auto;
    }

    .pdf-document p {
     margin: 0;
    }

    .pdf-document p--m {
      font-size: 1rem;
    }
    
    .pdf-document p--bold {
      font-weight: 700;
    }
    
    .pdf-document .barchart-container {
      max-width: 40%;
      margin: 0 auto;
    }
    
    .pdf-document .barchart-container > img {
      max-width: 100%;
    }
    
    .pdf-document .scattergraph-container {
      max-width: 60%;
      margin: 0 auto;
    }
    
    .pdf-document .scattergraph-container > img {
      width: 100%;
    }
    
    </style>
    <div className="pdf-document">
    <div className="pdf-content">
      <p className="h2">CHARACTER ASSESSMENT & SELECTION TOOL</p>
      <p className="h2">SUMMARY REPORT</p>
      <p className="p--m p--bold">
        Name of client: ${existingValues.detailsFullName}
      </p>
      <p className="p--m p--bold">
        Company/Organisation: ${existingValues.detailsOrganisation}
      </p>
      <p className="p--m p--bold">
        Name of Assessor: ${existingValues.detailsAssessor}
      </p>
      <p className="p--m p--bold">
        Date of assessment: ${existingValues.detailsDateAssessed}
      </p>
      <p className="p--m">
        This assessment can be used to consider the suitability of the
        candidate for different professional roles. It focuses on the
        candidate’s character and personal development in terms of,
      </p>
      <li className="p--m">Sense of purpose</li>
      <li className="p--m">Perseverance</li>
      <li className="p--m">Growth v Fixed Mindset</li>
      <li className="p--m">Ownership and Responsibility</li>
      <li className="p--m">Resilience</li>
      <li className="p--m">
        Supportive-Demanding scales - approach to the development of self
        and others
      </li>
      <p className="p--m">
        <ul>
          <li>Sense of purpose</li>
          <li>Perseverance</li>
        </ul>
      </p>
      <p className="p--m">
        The assessment focuses on characteristics that are associated with
        successful performance and positive development. It can also be used
        to inform the candidate’s development plan. This assessment should
        be considered alongside an assessment of the candidate’s
        professional skillset and capability.
      </p>
      <p className="h3" break>
        ASSESSMENT RESULTS
      </p>
      <div className="barchart-container">
        <img src=${barchartUrl ? barchartUrl : null} />
      </div>
      <p className="h4">Summary:</p>
      <p className="p--m">summary text goes here...</p>
      <p className="h3" break>
        SUPPORTIVE DEMANDING SCALES:
      </p>
      <p className="p--m">
        The quadrant that is most associated with positive development is
        the supportive- demanding quadrant (S-D). The letter P represents
        the ideal position within the quadrant. The X and Y on the quadrant
        indicate where the candidate is now. The dashed lines are the
        developmental pathways to optimize potential development (X).
      </p>
      <p className="p--m p--bold">
        = ‘Parenting’ Style, Personal Development
      </p>
      <p className="p--m p--bold">
        = Professional Development, People Management
      </p>
      <div className="scattergraph-container">
        <img src=${scattergraphUrl ? scattergraphUrl : null} alt="" />
      </div>
      <p className="p--m p--bold">
        = ‘Parenting’ Style, Personal Development
      </p>
      <p className="p--m">Summary explanstion...</p>
      <p className="p--m p--bold">
        = Professional Development, People Management
      </p>
      <p className="p--m">Summary explanation...</p>
      <p className="p--m p--bold">Overall Summary</p>
      <p className="p--m">Summary explanation...</p>
      <p className="h4">RECOMMENDATIONS:</p>
      <p className="p--m">Summary explanation...</p>
    </div>
  </div>
  </body>
</html>
`;

  /*   const parser = new DOMParser();
  const htmlParsed = parser.parseFromString(
    associateData.bodyHtml,
    "text/html"
  );
  console.log(htmlParsed);

  const elementArray = [];

  for (let i = 0; i < htmlParsed.body.children.length; i++) {
    elementArray[i] = htmlParsed.body.children[i];
  } */

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Html>{html}</Html>
      </Page>
    </Document>
  );
};

export default ReportTemplateReactPdfHtml;
