// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";
import ScrollToTop from "../../../../utils/ScrollToTop";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionB,
  validateSectionBAssessor,
  validateSectionBCandidate,
  validateSectionBAutoSaveInterviewer,
} from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionMcqYesNoMaybe,
  QuestionShortText,
  QuestionTextBox,
  QuestionMcqYesNo,
} from "./FormQuestions";

// ========== Page ========== //

function SectionB({
  environment,
  onApproveSection,
  existingValues,
  setExistingValues,
  onUpdateErrorsAssessor,
  formButtonText,
  triggerHandleValidateSectionAssessor,
}) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});
  const [renderErrors, setRenderErrors] = useState(0);

  const [submitted, setSubmitted] = useState(null);

  const form = useRef();
  const location = useLocation();

  useEffect(() => {
    triggerHandleValidateSectionAssessor > 0 && handleValidateSectionAssessor();
  }, [triggerHandleValidateSectionAssessor]);

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    return () => {
      // This function will be called when the component is unmounted
      // saveFormData(null);
      handleValidateSectionAutoSave();
    };
  }, []);

  // These useEffects are to update the state in the AssessorNav component

  useEffect(() => {
    if (environment !== "candidate")
      onUpdateErrorsAssessor(errorsAssessor, "sectionB");
  }, [errorsAssessor]);

  const {
    register: registerB,
    handleSubmit: handleSubmitB,
    reset,
    formState: { errors },
    getValues: getValuesB,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionB),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        if (key[0] === "B") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 13 ||
      keyCode === 32 ||
      keyCode === 56 ||
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 96 && keyCode <= 111)
    ) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    // const delayFn = setTimeout(() => saveFormData(null), 3000);
    const delayFn = setTimeout(() => handleValidateSectionAutoSave(), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keyup", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  // START SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const handleUserKeyPressAfterSubmit = useCallback(
    (event) => {
      const { key, keyCode } = event;

      if (
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 13 ||
        keyCode === 32 ||
        keyCode === 56 ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 96 && keyCode <= 111)
      ) {
        if (submitted) {
          if (environment === "assessor") {
            const errors = validateSectionBAssessor(getValuesB());
            setErrorsAssessor(errors ? errors : {});
          } else if (environment === "candidate") {
            const errors = validateSectionBCandidate(getValuesB());
            setErrorsCandidate(errors ? errors : {});
          }
        }
      }
    },
    [submitted]
  );

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPressAfterSubmit);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener(
        "keyup",
        handleUserKeyPressAfterSubmit
      );
    };
  }, [handleUserKeyPressAfterSubmit]);

  // END SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    const questionNumbersArray = [
      "B1",
      "B2",
      "B3",
      "B4a",
      "B4b",
      "B5",
      "B6",
      "B7",
      "B8",
      "B9",
      "B10",
      "B11",
      "B12",
    ];

    const values = getValuesB();

    let groupedValuesObject = {};
    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    console.log(values);

    if (approved === "approved")
      groupedValuesObject.detailsFormSectionApproved = "sectionB";

    let endpoint;

    if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    )
      endpoint = "candidateassessor";
    else if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
      groupedValuesObject.detailsStatus = "In progress";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                if (sessionStorage.token) delete sessionStorage.token;
                if (sessionStorage.candidate_token)
                  delete sessionStorage.candidate_token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          process.env.REACT_APP_LOGGITO_LEVEL === "0" &&
            toast.success("Your answers to section B have been saved");
          setExistingValues((prevValues) => ({
            ...prevValues,
            ...groupedValuesObject,
          }));
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    setSubmitted(true);
    const formValues = getValuesB();
    const errors = validateSectionBAssessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      toast.success("Your answers to section B have been saved");
      if (environment === "candidate") onApproveSection("sectionB");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesB();
    const errors = validateSectionBCandidate(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      toast.success("Your answers to seciton B have been saved");
      onApproveSection("sectionB");
      return;
    }
  };

  const handleValidateSectionAutoSave = () => {
    const formValues = getValuesB();
    const validationErrors = validateSectionBAutoSaveInterviewer(formValues);
    if (validationErrors) {
      Object.keys(errors).forEach((key) => delete errors[key]);
      Object.keys(validationErrors).forEach(
        (key) => (validationErrors[key] = { message: validationErrors[key] })
      );
      Object.assign(errors, validationErrors);
      setRenderErrors(renderErrors + 1);
      return;
    } else {
      Object.keys(errors).forEach((key) => delete errors[key]);
      setRenderErrors(renderErrors + 1);
      saveFormData(null);
      return;
    }
  };

  const handleSubmitOnClick = () => {
    // saveFormData();
    handleValidateSectionAutoSave();
    if (submitted) {
      if (environment === "assessor") {
        const errors = validateSectionBAssessor(getValuesB());
        setErrorsAssessor(errors ? errors : {});
      } else if (environment === "candidate") {
        const errors = validateSectionBCandidate(getValuesB());
        setErrorsCandidate(errors ? errors : {});
      }
    }
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section B");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <ScrollToTop>
      <form
        ref={form}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmitB((data) => handleSubmitOnClick());
        }}
        className="cast-form"
        noValidate={true}
      >
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        <div className="cast-form__title-block">
          <h2 className="h2">Section B</h2>
          <p className="p--m">
            PERSEVERANCE
            <br />
            <br />
            Through the next couple of questions, I want to find out a bit about
            your life and the challenges you have faced.
          </p>
        </div>
        <QuestionTextBox
          question={"B1"}
          questionText={[
            "How many years have you been in full-time employment?",
            "How many different organizations have you worked for full-time? Shortest length of stay? Longest length of stay?",
          ]}
          register={registerB}
          qErrors={errors.B1}
          qErrorsAssessor={errorsAssessor.B1}
          qErrorsCandidate={errorsCandidate.B1}
          watch={watch("B1")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B1"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B1_score,
              comment: errorsAssessor.B1_comment,
            }}
            scoreMax={6}
            scoreGuide={[
              "Check the number of jobs (change of job in same organisation counts as one job) and the average length of stay (in months) on the CV. For young people with few jobs, use the average. For a person staying at the same job for their entire career, ask follow-up questions to look at the reasons.",
              "Do not include part-time jobs or jobs taken temporarily to support study for example. If working for an agency count that as one organization rather than how many organizations you worked in with the agency. If self-employed count that as one job rather than the number of organizations worked with.",
              "Calculate the average length of stay.",
              "Average less than 1 year = 0, less than 2 years = 1, 2-3 years = 2, 3-5 years = 3, over 5 years = 4. For a significant advancement in a job add 1 point, i.e. team leader, for attaining a position such as a manager add another 1 point.",
            ]}
          />
        ) : null}
        <QuestionShortText
          question={"B2"}
          questionText={[
            "At any time in your life have you ever had any hobbies or activities that you have pursued for more than 2 years (outside of the regular school /work hours)? This can be any kind of pursuit, e.g. sports, music, clubs, volunteer activities, or hobbies.",
            "If so what and for how long?",
            "Did you progress your ability in these pursuits? Please explain if you took on a leadership or a co-ordinating role in either of these 2 interests?",
          ]}
          register={registerB}
          qErrors={errors.B2}
          qErrorsAssessor={errorsAssessor.B2}
          qErrorsCandidate={errorsCandidate.B2}
          watch={watch("B2")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B2"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B2_score,
              comment: errorsAssessor.B2_comment,
            }}
            scoreMax={6}
            scoreGuide="Each activity for more than 2 years one point. Any of these in which advancement/promotion was made = 1 more point. The third point for high advancement – such as captain."
          />
        ) : null}
        <QuestionShortText
          question={"B3"}
          questionText={
            "How would you describe yourself concerning routines at home and work?"
          }
          register={registerB}
          qErrors={errors.B3}
          qErrorsAssessor={errorsAssessor.B3}
          qErrorsCandidate={errorsCandidate.B3}
          watch={watch("B3")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B3"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B3_score,
              comment: errorsAssessor.B3_comment,
            }}
            scoreGuide={
              "Has consistent routines at home and work as far as possible."
            }
          />
        ) : null}
        <QuestionMcqYesNoMaybe
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B4a"}
          questionText={
            "Do you think that qualities such as determination, perseverance, and purpose can be learned/developed through conscious effort? If the answer is yes - how?"
          }
          register={registerB}
          qErrors={errors.B4a}
          qErrorsAssessor={errorsAssessor.B4a}
          qErrorsCandidate={errorsCandidate.B4a}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B4a"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B4a_score,
              comment: errorsAssessor.B4a_comment,
            }}
            scoreGuide={
              "Yes, and can show how, by deliberate practice, commitment, and by using the support of a role model/ mentor."
            }
          />
        ) : null}
        <QuestionMcqYesNo
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B4b"}
          questionText={"Is an explanation given?"}
          register={registerB}
          qErrors={errors.B4b}
          qErrorsAssessor={errorsAssessor.B4b}
          qErrorsCandidate={errorsCandidate.B4b}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B4b"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B4b_score,
              comment: errorsAssessor.B4b_comment,
            }}
          />
        ) : null}
        <QuestionShortText
          question={"B5"}
          questionText={
            "Give an example in your life where you have demonstrated resilience and determination."
          }
          register={registerB}
          qErrors={errors.B5}
          qErrorsAssessor={errorsAssessor.B5}
          qErrorsCandidate={errorsCandidate.B5}
          watch={watch("B5")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B5"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B5_score,
              comment: errorsAssessor.B5_comment,
            }}
            scoreGuide={"Can give a clear example."}
          />
        ) : null}
        <QuestionTextBox
          question={"B6"}
          questionText={[
            "Choose 4 or 5 words to describe your character (how people who know you well might describe you).",
            "b)	Please give an example that demonstrates your characteristics.",
          ]}
          register={registerB}
          qErrors={errors.B6}
          qErrorsAssessor={errorsAssessor.B6}
          qErrorsCandidate={errorsCandidate.B6}
          watch={watch("B6")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B6"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B6_score,
              comment: errorsAssessor.B6_comment,
            }}
            scoreGuide={
              "E.g. optimistic, respectful, adaptable, determined, persevering, flexible, purposeful, kind, conscientious, go-getter, hardworking. Can give an example that demonstrates the characteristics."
            }
          />
        ) : null}
        <QuestionTextBox
          question={"B7"}
          questionText={"What do you think of competition, in a general sense?"}
          register={registerB}
          qErrors={errors.B7}
          qErrorsAssessor={errorsAssessor.B7}
          qErrorsCandidate={errorsCandidate.B7}
          watch={watch("B7")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B7"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B7_score,
              comment: errorsAssessor.B7_comment,
            }}
            scoreGuide={
              "Competition can be positive when it is not just about winning, but about improving and developing - by being challenged and pushed."
            }
          />
        ) : null}
        <QuestionTextBox
          question={"B8"}
          questionText={
            "What do you think are the key ingredients to becoming successful at something?"
          }
          register={registerB}
          qErrors={errors.B8}
          qErrorsAssessor={errorsAssessor.B8}
          qErrorsCandidate={errorsCandidate.B8}
          watch={watch("B8")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B8"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B8_score,
              comment: errorsAssessor.B8_comment,
            }}
            scoreGuide={
              "Knowing what you want, practice repetition, hard work, discipline, resilience, good habits, not giving up, and using support."
            }
          />
        ) : null}
        <QuestionShortText
          question={"B9"}
          questionText={[
            "",
            'a) How would you define a "genius"?',
            "b) How do you think a person becomes a genius?",
          ]}
          register={registerB}
          qErrors={errors.B9}
          qErrorsAssessor={errorsAssessor.B9}
          qErrorsCandidate={errorsCandidate.B9}
          watch={watch("B9")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B9"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B9_score,
              comment: errorsAssessor.B9_comment,
            }}
            scoreGuide={
              "1 - A genius is someone who has accomplished an exceptional level of achievement. 2-3 Understands that a genius is a mixture of innate talent and hard work. (it takes around 10,000 deliberate practice to become a master at something)"
            }
          />
        ) : null}
        <QuestionTextBox
          question={"B10"}
          questionText={
            "Can you think of a big disappointment in your life? If so, how did you respond to it? (You don't have to tell me what the disappointment was, I am most interested in how you responded to it)"
          }
          register={registerB}
          qErrors={errors.B10}
          qErrorsAssessor={errorsAssessor.B10}
          qErrorsCandidate={errorsCandidate.B10}
          watch={watch("B10")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B10"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B10_score,
              comment: errorsAssessor.B10_comment,
            }}
            scoreGuide={
              "Has a clear example, can show how disappointment was handled, and a positive way forward was found. Believes disappointments can be learning opportunity, and a spur for development."
            }
          />
        ) : null}
        <QuestionTextBox
          question={"B11"}
          questionText={
            "This question is about hope. What does hope mean to you?"
          }
          register={registerB}
          qErrors={errors.B11}
          qErrorsAssessor={errorsAssessor.B11}
          qErrorsCandidate={errorsCandidate.B11}
          watch={watch("B11")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B11"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B11_score,
              comment: errorsAssessor.B11_comment,
            }}
            scoreGuide={
              "Has a strong sense of hope that is connected to one's potential to create a positive future. To have the courage to carry on."
            }
          />
        ) : null}
        <QuestionTextBox
          question={"B12"}
          questionText={
            "Can you imagine what would it be like to feel hopeless? (then ask) If you began to feel like that what would you do?	"
          }
          register={registerB}
          qErrors={errors.B12}
          qErrorsAssessor={errorsAssessor.B12}
          qErrorsCandidate={errorsCandidate.B12}
          watch={watch("B12")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B12"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B12_score,
              comment: errorsAssessor.B12_comment,
            }}
            scoreGuide={
              "Understands how awful it would be, but has a sense of resolve, strategy for persevering, and can ask for help."
            }
          />
        ) : null}
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        {formDataBeingSent ? (
          <button type="button" className="cta cta--accent--clicked">
            {formButtonText}
          </button>
        ) : null}
        {environment === "assessor" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleValidateSectionAssessor}
          >
            {formButtonText}
          </button>
        ) : null}
        {environment === "candidate" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleValidateSectionCandidate}
          >
            {formButtonText}
          </button>
        ) : null}
      </form>
    </ScrollToTop>
  );
}

export default withContext(SectionB);
