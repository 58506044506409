// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";
import ScrollToTop from "../../../../utils/ScrollToTop";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionA,
  validateSectionAAssessor,
  validateSectionACandidate,
  validateSectionAAutoSaveInterviewer,
} from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionMcqList,
  QuestionShortText,
  QuestionTextBox,
  QuestionMcqYesNo,
} from "./FormQuestions";

// ========== Page ========== //

function SectionA({
  environment,
  onApproveSection,
  existingValues,
  setExistingValues,
  onUpdateErrorsAssessor,
  formButtonText,
  triggerHandleValidateSectionAssessor,
}) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});
  const [renderErrors, setRenderErrors] = useState(0);

  const [submitted, setSubmitted] = useState(null);

  const form = useRef();
  const location = useLocation();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    triggerHandleValidateSectionAssessor > 0 && handleValidateSectionAssessor();
  }, [triggerHandleValidateSectionAssessor]);

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    return () => {
      // This function will be called when the component is unmounted
      // saveFormData(null);
      handleValidateSectionAutoSave();
    };
  }, []);

  useEffect(() => {
    console.log("state:");
    console.log(errorsAssessor);
  }, [errorsAssessor]);

  // These useEffects are to update the state in the AssessorNav component

  useEffect(() => {
    if (environment !== "candidate")
      onUpdateErrorsAssessor(errorsAssessor, "sectionA");
  }, [errorsAssessor]);

  const {
    register: registerA,
    handleSubmit: handleSubmitA,
    reset,
    formState: { errors },
    getValues: getValuesA,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionA),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        if (key[0] === "A") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
          // setValue(key, value.response, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  // START SUBMIT AFTER TYPING - THIS SUBMITS THE USER'S RESPONSE AFTER THEY STOP TYPING

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 13 ||
      keyCode === 32 ||
      keyCode === 56 ||
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 96 && keyCode <= 111)
    ) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    // const delayFn = setTimeout(() => saveFormData(null), 3000);
    const delayFn = setTimeout(() => handleValidateSectionAutoSave(), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keyup", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  // START SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const handleUserKeyPressAfterSubmit = useCallback(
    (event) => {
      const { key, keyCode } = event;

      if (
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 13 ||
        keyCode === 32 ||
        keyCode === 56 ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 96 && keyCode <= 111)
      ) {
        if (submitted) {
          if (environment === "assessor") {
            const errors = validateSectionAAssessor(getValuesA());
            setErrorsAssessor(errors ? errors : {});
          } else if (environment === "candidate") {
            const errors = validateSectionACandidate(getValuesA());
            setErrorsCandidate(errors ? errors : {});
          }
        }
      }
    },
    [submitted]
  );

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPressAfterSubmit);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener(
        "keyup",
        handleUserKeyPressAfterSubmit
      );
    };
  }, [handleUserKeyPressAfterSubmit]);

  // END SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    const questionNumbersArray = ["A1a", "A1b", "A2", "A3", "A4", "A5", "A6"];

    const values = getValuesA();

    let groupedValuesObject = {};

    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    if (approved === "approved")
      groupedValuesObject.detailsFormSectionApproved = "sectionA";

    console.log(groupedValuesObject);

    let endpoint;

    if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    )
      endpoint = "candidateassessor";
    else if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
      groupedValuesObject.detailsStatus = "In progress";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                if (sessionStorage.token) delete sessionStorage.token;
                if (sessionStorage.candidate_token)
                  delete sessionStorage.candidate_token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          process.env.REACT_APP_LOGGITO_LEVEL === "0" &&
            toast.success("Your answers to section A have been saved");
          setExistingValues((prevValues) => ({
            ...prevValues,
            ...groupedValuesObject,
          }));
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    setSubmitted(true);
    const formValues = getValuesA();
    const errors = validateSectionAAssessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      toast.success("Your answers to section A have been saved");
      if (environment === "candidate") onApproveSection("sectionA");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesA();
    const errors = validateSectionACandidate(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      toast.success("Your answers to section A have been saved");
      onApproveSection("sectionA");
      return;
    }
  };

  const handleValidateSectionAutoSave = () => {
    const formValues = getValuesA();
    const validationErrors = validateSectionAAutoSaveInterviewer(formValues);
    if (validationErrors) {
      Object.keys(errors).forEach((key) => delete errors[key]);
      Object.keys(validationErrors).forEach(
        (key) => (validationErrors[key] = { message: validationErrors[key] })
      );
      Object.assign(errors, validationErrors);
      setRenderErrors(renderErrors + 1);
      return;
    } else {
      Object.keys(errors).forEach((key) => delete errors[key]);
      setRenderErrors(renderErrors + 1);
      saveFormData(null);
      return;
    }
  };

  const handleSubmitOnClick = () => {
    handleValidateSectionAutoSave();
    if (submitted) {
      if (environment === "assessor") {
        const errors = validateSectionAAssessor(getValuesA());
        setErrorsAssessor(errors ? errors : {});
      } else if (environment === "candidate") {
        const errors = validateSectionACandidate(getValuesA());
        setErrorsCandidate(errors ? errors : {});
      }
    }
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section A");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <ScrollToTop>
      <form
        ref={form}
        // THIS WORKS BUT THE FORM IS SUBMITTED ON PAGE LOAD
        // onSubmit={handleSubmitA((data) => saveFormData())}
        // THIS STOPS THE SAVEFORMDATA() FUNCTION BEING CALLED
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmitA((data) => handleSubmitOnClick());
        }}
        className="cast-form"
        noValidate={true}
      >
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        <div className="cast-form__title-block">
          <h2 className="h2">Section A</h2>
          <p className="p--m">
            PURPOSE (sense of meaningful purpose that includes contributing to
            others)
            <br />
            <br />
            First, I am going to ask you about your work history.
            {/*    <br />
          &nbsp; */}
          </p>
        </div>
        <QuestionMcqList
          onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
          question={"A1a"}
          questionText={
            "Which one of these statements most fits with you and explain your reasons?"
          }
          register={registerA}
          qErrors={errors.A1a}
          qErrorsAssessor={errorsAssessor.A1a}
          qErrorsCandidate={errorsCandidate.A1a}
          labels={[
            "I view my work as just a necessity of life – it pays the bills.",
            "My work is one of the most important things in my life.",
            "I view my job mainly as a way of progressing to other jobs.",
          ]}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
            question={"A1a"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerA}
            qErrorsAssessor={{
              score: errorsAssessor.A1a_score,
              comment: errorsAssessor.A1a_comment,
            }}
            scoreShown={false}
            scoreGuide={
              "b. with clear reasons, sense of purpose, determination, and contribution to others (a is a job, b is a calling, c is a career). (a = 1-2, c = 3-4, b = 5-6)"
            }
          />
        ) : null}
        <QuestionMcqYesNo
          onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
          question={"A1b"}
          questionText={"Is an explanation given?"}
          register={registerA}
          qErrors={errors.A1b}
          qErrorsAssessor={errorsAssessor.A1b}
          qErrorsCandidate={errorsCandidate.A1b}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
            question={"A1b"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerA}
            qErrorsAssessor={{
              score: errorsAssessor.A1b_score,
              comment: errorsAssessor.A1b_comment,
            }}
          />
        ) : null}
        <QuestionTextBox
          question={"A2"}
          questionText={"What motivates you in your work?"}
          register={registerA}
          qErrors={errors.A2}
          qErrorsAssessor={errorsAssessor.A2}
          qErrorsCandidate={errorsCandidate.A2}
          watch={watch("A2")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
            question={"A2"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerA}
            qErrorsAssessor={{
              score: errorsAssessor.A2_score,
              comment: errorsAssessor.A2_comment,
            }}
            scoreGuide={
              "Helping others, developing self, interest, purpose, contributing to an important cause outside of oneself, teamwork, achieving positive outcomes."
            }
          />
        ) : null}
        <QuestionTextBox
          question={"A3"}
          questionText={
            "TThink of a role model or mentor in your life (friend, family, colleague, etc.). Explain 3-6 qualities you admire in them."
          }
          register={registerA}
          qErrors={errors.A3}
          qErrorsAssessor={errorsAssessor.A3}
          qErrorsCandidate={errorsCandidate.A3}
          watch={watch("A3")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
            question={"A3"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerA}
            qErrorsAssessor={{
              score: errorsAssessor.A3_score,
              comment: errorsAssessor.A3_comment,
            }}
            scoreGuide={
              "Has a role model who demonstrates, perseverance, determination, purpose, contribution to others, positive outlook."
            }
          />
        ) : null}
        <QuestionTextBox
          question={"A4"}
          questionText={
            "What do you want to be doing in 10 years? Why? (and then) How are you going to achieve your goals?"
          }
          register={registerA}
          qErrors={errors.A4}
          qErrorsAssessor={errorsAssessor.A4}
          qErrorsCandidate={errorsCandidate.A4}
          watch={watch("A4")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
            question={"A4"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerA}
            qErrorsAssessor={{
              score: errorsAssessor.A4_score,
              comment: errorsAssessor.A4_comment,
            }}
            scoreGuide={
              "Has a clear aim (1), which includes personal development and contribution to others (2) and has a realistic plan (3)."
            }
          />
        ) : null}
        <QuestionShortText
          question={"A5"}
          questionText={
            "Write down 4-6 words to describe the core values that you live by."
          }
          register={registerA}
          qErrors={errors.A5}
          qErrorsAssessor={errorsAssessor.A5}
          qErrorsCandidate={errorsCandidate.A5}
          watch={watch("A5")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
            question={"A5"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerA}
            qErrorsAssessor={{
              score: errorsAssessor.A5_score,
              comment: errorsAssessor.A5_comment,
            }}
            scoreGuide={
              "Respect, perseverance, determination, hope, trust, belief, collaboration, kindness, accountability. None of these or similar = 0, 1 = 1, 2-3 =2, 4 or more = 3."
            }
          />
        ) : null}
        <QuestionTextBox
          question={"A6"}
          questionText={
            "What would you like to be your life legacy? What plans do you have to achieve this?"
          }
          register={registerA}
          qErrors={errors.A6}
          qErrorsAssessor={errorsAssessor.A6}
          qErrorsCandidate={errorsCandidate.A6}
          watch={watch("A6")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
            question={"A6"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerA}
            qErrorsAssessor={{
              score: errorsAssessor.A6_score,
              comment: errorsAssessor.A6_comment,
            }}
            scoreGuide={
              "Has a clear and powerful life legacy that includes contributing to others (1), also has clear and realistic plans (2), has begun to implement the plan (3)."
            }
          />
        ) : null}
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        {formDataBeingSent ? (
          <button type="button" className="cta cta--accent--clicked">
            {formButtonText}
          </button>
        ) : null}
        {environment === "assessor" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleValidateSectionAssessor}
          >
            {formButtonText}
          </button>
        ) : null}
        {environment === "candidate" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleValidateSectionCandidate}
          >
            {formButtonText}
          </button>
        ) : null}
      </form>
    </ScrollToTop>
  );
}

export default withContext(SectionA);
