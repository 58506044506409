const { FormatError } = require("errors");

const Joi = require("joi");

function validateSectionDAutomated(formValues) {
  // THE SCHEMA HERE HAS THE SAME STRUCTURE AS THE FORM SCHEMAS

  const schema = Joi.object({
    D1: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D1_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D1_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D2: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D2_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D3: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D3_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D4: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D4_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D4_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D5: Joi.string().trim().max(1).valid("0", "1", "2", "3", "4").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D5_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D6a: Joi.string().trim().valid(true, false).messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D6b: Joi.string().trim().valid(true, false).messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D6c: Joi.string().trim().valid(true, false).messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D6d: Joi.string().trim().valid(true, false).messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D6e: Joi.string().trim().valid(true, false).messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D6_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D7: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D7_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D7_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D8: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D8_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D8_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D9: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D9_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D9_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    D10: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    D10_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    D10_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
  });

  const options = { abortEarly: false };

  const { error } = schema.validate(formValues, options);

  if (!error) return null;

  const errors = {};
  for (let item of error.details) {
    errors[item.path[0]] = item.message;
  }
  return errors;
}

export default validateSectionDAutomated;
