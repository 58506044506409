// ========== imports ========== //
import "./Blog.css";
import React, { useState, useEffect, useMemo } from "react";

import useAnalyticsPageViewSenderGA4 from "../../utils/useAnalyticsPageViewSenderGA4";

import { toast } from "react-toastify";
import Loggito from "../../utils/Loggito";
import withContext from "../../utils/withContext";

import { ServerError } from "errors";
import { Link } from "react-router-dom";
import { Spinner } from "../Common";
import {
  SearchGeneral,
  MyCarouselEditorsPicks,
  BlogHero,
} from "./BlogComponents";
import { getNewsClient, searchNewsClient } from "../../logic";

import { Pagination } from "../Common";

// ========== Page ========== //

/**
 * Renders the Blog component.
 * The Blog component displays news updates and resources, and allows users to search for articles.
 * @returns {JSX.Element} The rendered Blog component.
 */
function Blog() {
  // ========== Hook consts ========== //
  const [newsData, setNewsData] = useState("");

  const [category, setCategory] = useState("All categories");
  const [query, setQuery] = useState();

  const [articlesCount, setArticlesCount] = useState(10);

  useEffect(() => {
    loadNews();
  }, []);

  useEffect(() => {
    loadNews();
  }, [query]);

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("News");
  }, []);

  /**
   * Loads news data based on the provided query and category.
   * If no query is provided, it retrieves all news data.
   * If a query is provided, it searches for news data based on the query and category.
   * @returns {void}
   */
  const loadNews = () => {
    try {
      if (query === undefined)
        return getNewsClient((error, newsArray) => {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          setNewsData(newsArray);
          setCurrentPage(1);
        });
      else
        return searchNewsClient(query, category, (error, newsArray) => {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }
          setNewsData(newsArray);
          setCurrentPage(1);
        });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  const handleSearch = (query, category) => {
    setCategory(category);
    setQuery(query);
  };
  // ========== other consts ========== //

  const logger = new Loggito("Articles");

  const [currentPage, setCurrentPage] = useState();

  const pageSize = 8;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return newsData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, newsData]);
  // ========== jsx ========== //

  if (newsData) {
    return (
      <div className="">
        <div className="content blog">
          <BlogHero />
          <div className="intro-description">
            <h2 className="h2 p--centered intro">News and Resources</h2>
            <p className="p--m p--left--mobile">
              Here you'll find CAST news updates and resources, and other
              relevant content.
            </p>
            <p className="p--m p--left--mobile">
              Please feel free to leave a comment or subscribe to keep up to
              date with new releases.
            </p>
          </div>
          <MyCarouselEditorsPicks />
          {currentTableData.length >= 1 || query ? (
            <>
              <div className="block">
                <SearchGeneral onQuery={handleSearch} />
                {currentTableData.length === 0 ? (
                  <p className="p--m p--bold p--centered no-search-results">
                    Sorry, your search returned no relevant results
                  </p>
                ) : null}
              </div>
              <div className="block">
                <ul className="articles list grid grid--1x4--wide">
                  {currentTableData.map((article) => {
                    return (
                      <li className="article" key={article.title}>
                        <Link
                          to={`/news/${article.route}`}
                          className="article__link"
                        >
                          <img
                            className="article__image "
                            src={article.mainImage.fileUrl}
                            alt=""
                          />
                        </Link>
                        <Link
                          to={`/news/${article.route}`}
                          className="article__link"
                        >
                          <p className="p--m p--centered p--article">
                            {article.title}
                          </p>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={newsData.length}
                  pageSize={pageSize}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  } else
    return (
      <div className="content">
        <Spinner />
      </div>
    );
}

export default withContext(Blog);
