// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";
import ScrollToTop from "../../../../utils/ScrollToTop";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionC,
  validateSectionCAssessor,
  validateSectionCCandidate,
  validateSectionCAutoSave,
} from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionDetailsAdmin,
  QuestionMcq14Strongly,
} from "./FormQuestions";

import ConfirmSubmission from "./ConfirmSubmission";

// ========== Page ========== //

function SectionC({
  environment,
  onApproveSection,
  existingValues,
  setExistingValues,
  onUpdateErrorsAssessor,
  formButtonText,
  triggerHandleValidateSectionAssessor,
}) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});
  const [renderErrors, setRenderErrors] = useState(0);

  const [confirmSubmissionPopup, setConfirmSubmissionPopup] = useState(false);

  const [submitted, setSubmitted] = useState(null);

  const form = useRef();
  const location = useLocation();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    triggerHandleValidateSectionAssessor > 0 && handleValidateSectionAssessor();
  }, [triggerHandleValidateSectionAssessor]);

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    return () => {
      // This function will be called when the component is unmounted
      // saveFormData(null);
      handleValidateSectionAutoSave();
    };
  }, []);

  useEffect(() => {
    if (confirmSubmissionPopup === true) handleValidateSectionCandidate();
    if (confirmSubmissionPopup === true) setSubmitted(true);
  }, [confirmSubmissionPopup]);

  // These useEffects are to update the state in the AssessorNav component

  useEffect(() => {
    if (environment !== "candidate")
      onUpdateErrorsAssessor(errorsAssessor, "sectionC");
  }, [errorsAssessor]);

  const {
    register: registerC,
    handleSubmit: handleSubmitC,
    reset,
    formState: { errors },
    getValues: getValuesC,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionC),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        if (key[0] === "C") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 13 ||
      keyCode === 32 ||
      keyCode === 56 ||
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 96 && keyCode <= 111)
    ) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    // const delayFn = setTimeout(() => saveFormData(null), 3000);
    const delayFn = setTimeout(() => handleValidateSectionAutoSave(), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keyup", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  // START SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const handleUserKeyPressAfterSubmit = useCallback(
    (event) => {
      const { key, keyCode } = event;

      if (
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 13 ||
        keyCode === 32 ||
        keyCode === 56 ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 96 && keyCode <= 111)
      ) {
        if (submitted) {
          if (environment === "assessor") {
            const errors = validateSectionCAssessor(getValuesC());
            setErrorsAssessor(errors ? errors : {});
          } else if (environment === "candidate") {
            const errors = validateSectionCCandidate(getValuesC());
            setErrorsCandidate(errors ? errors : {});
          }
        }
      }
    },
    [submitted]
  );

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPressAfterSubmit);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener(
        "keyup",
        handleUserKeyPressAfterSubmit
      );
    };
  }, [handleUserKeyPressAfterSubmit]);

  // END SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    const questionNumbersArray = [
      "C1",
      "C2",
      "C3",
      "C4",
      "C5",
      "C6",
      "C7",
      "C8",
      "C9",
      "C10",
      "C11",
      "C12",
    ];

    const values = getValuesC();

    let groupedValuesObject = {};

    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    console.log(values);

    if (approved === "approved")
      groupedValuesObject.detailsFormSectionApproved = "sectionC";

    let endpoint;

    if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    )
      endpoint = "candidateassessor";
    else if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
      groupedValuesObject.detailsStatus = "In progress";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                if (sessionStorage.token) delete sessionStorage.token;
                if (sessionStorage.candidate_token)
                  delete sessionStorage.candidate_token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          process.env.REACT_APP_LOGGITO_LEVEL === "0" &&
            toast.success("Your answers to section C have been saved");
          setExistingValues((prevValues) => ({
            ...prevValues,
            ...groupedValuesObject,
          }));
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    setSubmitted(true);
    const formValues = getValuesC();
    const errors = validateSectionCAssessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      debugger;
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      toast.success("Your answers to section C have been saved");
      if (environment === "candidate") onApproveSection("sectionC");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesC();
    const errors = validateSectionCCandidate(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      toast.success("Your answers to section C have been saved");
      onApproveSection("sectionC");
      return;
    }
  };

  const handleValidateSectionAutoSave = () => {
    const formValues = getValuesC();
    const validationErrors = validateSectionCAutoSave(formValues);
    if (validationErrors) {
      Object.keys(errors).forEach((key) => delete errors[key]);
      Object.keys(validationErrors).forEach(
        (key) => (validationErrors[key] = { message: validationErrors[key] })
      );
      Object.assign(errors, validationErrors);
      setRenderErrors(renderErrors + 1);
      return;
    } else {
      Object.keys(errors).forEach((key) => delete errors[key]);
      setRenderErrors(renderErrors + 1);
      saveFormData(null);
      return;
    }
  };

  const handleSubmitOnClick = () => {
    // saveFormData();
    handleValidateSectionAutoSave();
    if (submitted) {
      if (environment === "assessor") {
        const errors = validateSectionCAssessor(getValuesC());
        setErrorsAssessor(errors ? errors : {});
      } else if (environment === "candidate") {
        const errors = validateSectionCCandidate(getValuesC());
        setErrorsCandidate(errors ? errors : {});
      }
    }
  };

  const confirmSubmissionCandidate = (state) => {
    setConfirmSubmissionPopup(state);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section C");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <ScrollToTop>
      <form
        ref={form}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmitC((data) => handleSubmitOnClick());
        }}
        className="cast-form"
        noValidate={true}
      >
        <ConfirmSubmission
          confirmSubmissionPopup={confirmSubmissionPopup}
          onConfirmSubmissionCandidate={confirmSubmissionCandidate}
        />
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        {environment === "assessor" ? (
          <div className="cast-form__title-block">
            <h2 className="h2">Section C</h2>
            <p className="p--m">
              GROWTH v FIXED MINDSETS (Similar to having an Open or Closed mind)
              <br />
              <br />
              Through the next couple of questions, I want to find out how you
              think about and how you see certain things. I am going to say
              several statements, and I would like you to tell me how much you
              agree with each statement.
              <br />
              <br />
              Score these statements from 0-3 (0 being strongly disagree, 1
              disagree, 2 agree and 3 strongly agree) on how much you agree with
              it and explain your score. On some questions, I may ask you to
              explain your score.
            </p>
          </div>
        ) : null}
        {environment === "candidate" ? (
          <div className="cast-form__title-block">
            <h2 className="h2">Section C</h2>
            <p className="p--m">
              Score these statements from 0-3 based on how much you agree or
              disagree with them. <br />
              <br />
              0 strongly disagree <br />1 disagree <br />2 agree <br />3
              strongly agree
            </p>
          </div>
        ) : null}

        <QuestionMcq14Strongly
          onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
          question={"C1"}
          questionText={
            "Consider this metaphor - A leopard will never change its spots."
          }
          register={registerC}
          qErrors={errors.C1}
          qErrorsAssessor={errorsAssessor.C1}
          qErrorsCandidate={errorsCandidate.C1}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
            question={"C1"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerC}
            qErrorsAssessor={{
              score: errorsAssessor.C1_score,
              comment: errorsAssessor.C1_comment,
            }}
            scoreGuide={
              "Strongly believes in the possibility that people can change."
            }
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"C1"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
          question={"C2"}
          questionText={
            "\"Whether you think you can, or think you can't—you are right\", or in other words what you think, can determine whether you can or can't do something."
          }
          register={registerC}
          qErrors={errors.C2}
          qErrorsAssessor={errorsAssessor.C2}
          qErrorsCandidate={errorsCandidate.C2}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
            question={"C2"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerC}
            qErrorsAssessor={{
              score: errorsAssessor.C2_score,
              comment: errorsAssessor.C2_comment,
            }}
            scoreGuide={
              "Our thoughts and beliefs have big influences on what we achieve or fail to achieve."
            }
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"C2"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
          question={"C3"}
          questionText={
            "You can always substantially change how capable you are."
          }
          register={registerC}
          qErrors={errors.C3}
          qErrorsAssessor={errorsAssessor.C3}
          qErrorsCandidate={errorsCandidate.C3}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
            question={"C3"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerC}
            qErrorsAssessor={{
              score: errorsAssessor.C3_score,
              comment: errorsAssessor.C3_comment,
            }}
            scoreGuide={"Capability can change and develop."}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"C3"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
          question={"C4"}
          questionText={"People who change their minds are unreliable. "}
          register={registerC}
          qErrors={errors.C4}
          qErrorsAssessor={errorsAssessor.C4}
          qErrorsCandidate={errorsCandidate.C4}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
            question={"C4"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerC}
            qErrorsAssessor={{
              score: errorsAssessor.C4_score,
              comment: errorsAssessor.C4_comment,
            }}
            scoreGuide={
              "Being flexible in thinking is a strength. However, continuously changing their mind for no sound reason is unreliable."
            }
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"C4"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
          question={"C5"}
          questionText={
            environment === "assessor"
              ? 'This one is about your life - "You have overcome difficulty to conquer an important challenge."'
              : "I have overcome a difficult situation and conquered an important challenge."
          }
          register={registerC}
          qErrors={errors.C5}
          qErrorsAssessor={errorsAssessor.C5}
          qErrorsCandidate={errorsCandidate.C5}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
            question={"C5"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerC}
            qErrorsAssessor={{
              score: errorsAssessor.C5_score,
              comment: errorsAssessor.C5_comment,
            }}
            scoreGuide={"Can explain with a good example."}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"C5"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
          question={"C6"}
          questionText={
            environment === "assessor"
              ? 'This one is also about you "I always finish whatever I begin."'
              : "I always finish whatever I begin. "
          }
          register={registerC}
          qErrors={errors.C6}
          qErrorsAssessor={errorsAssessor.C6}
          qErrorsCandidate={errorsCandidate.C6}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
            question={"C6"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerC}
            qErrorsAssessor={{
              score: errorsAssessor.C6_score,
              comment: errorsAssessor.C6_comment,
            }}
            scoreGuide={
              "Finishes things except for occasional small things that don’t matter or where it makes sense to stop."
            }
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"C6"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
          question={"C7"}
          questionText={
            '"Success is never final; failure is never fatal: It is the courage to continue that counts".'
          }
          register={registerC}
          qErrors={errors.C7}
          qErrorsAssessor={errorsAssessor.C7}
          qErrorsCandidate={errorsCandidate.C7}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
            question={"C7"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerC}
            qErrorsAssessor={{
              score: errorsAssessor.C7_score,
              comment: errorsAssessor.C7_comment,
            }}
            scoreGuide={"Can explainsupport with a good example."}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"C7"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
          question={"C8"}
          questionText={"It is better to praise talent than effort."}
          register={registerC}
          qErrors={errors.C8}
          qErrorsAssessor={errorsAssessor.C8}
          qErrorsCandidate={errorsCandidate.C8}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
            question={"C8"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerC}
            qErrorsAssessor={{
              score: errorsAssessor.C8_score,
              comment: errorsAssessor.C8_comment,
            }}
            scoreGuide={
              "Effort is more important for long-term development. Talent is usually achieved through persistent effort. Praising someone for talent may undermine the hard work they have put in."
            }
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"C8"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
          question={"C9"}
          questionText={
            "A positive role model or mentor can always help someone change."
          }
          register={registerC}
          qErrors={errors.C9}
          qErrorsAssessor={errorsAssessor.C9}
          qErrorsCandidate={errorsCandidate.C9}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
            question={"C9"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerC}
            qErrorsAssessor={{
              score: errorsAssessor.C9_score,
              comment: errorsAssessor.C9_comment,
            }}
            scoreGuide={
              "Understands role modelling, how we influence each other, and that anyone can change under the right influences."
            }
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"C9"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
          question={"C10"}
          questionText={
            "Cognitive and emotional intelligence are something very basic about you that you can’t change very much."
          }
          register={registerC}
          qErrors={errors.C10}
          qErrorsAssessor={errorsAssessor.C10}
          qErrorsCandidate={errorsCandidate.C10}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
            question={"C10"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerC}
            qErrorsAssessor={{
              score: errorsAssessor.C10_score,
              comment: errorsAssessor.C10_comment,
            }}
            scoreGuide={"Intelligence is not fixed"}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"C10"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
          question={"C11"}
          questionText={
            "If someone told you can’t do something, it probably means that you can’t."
          }
          register={registerC}
          qErrors={errors.C11}
          qErrorsAssessor={errorsAssessor.C11}
          qErrorsCandidate={errorsCandidate.C11}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
            question={"C11"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerC}
            qErrorsAssessor={{
              score: errorsAssessor.C11_score,
              comment: errorsAssessor.C11_comment,
            }}
            scoreGuide={
              "Believes they are the master of their life and that with effort most things are possible."
            }
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"C11"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
          question={"C12"}
          questionText={"Failure is always an opportunity."}
          register={registerC}
          qErrors={errors.C12}
          qErrorsAssessor={errorsAssessor.C12}
          qErrorsCandidate={errorsCandidate.C12}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitC((data) => handleSubmitOnClick())}
            question={"C12"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerC}
            qErrorsAssessor={{
              score: errorsAssessor.C12_score,
              comment: errorsAssessor.C12_comment,
            }}
            scoreGuide={
              "Learning happens from trying something out, failing, and trying again."
            }
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"C12"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        {formDataBeingSent ? (
          <button type="button" className="cta cta--accent--clicked">
            {formButtonText}
          </button>
        ) : null}
        {environment === "assessor" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleValidateSectionAssessor}
          >
            {formButtonText}
          </button>
        ) : null}
        {environment === "candidate" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={() => confirmSubmissionCandidate("active")}
          >
            {formButtonText}
          </button>
        ) : null}
      </form>
    </ScrollToTop>
  );
}

export default withContext(SectionC);
