import Joi from "joi";

const schemaFormSectionB = Joi.object({
  B1: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B1_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B1_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3", "4", "5", "6")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B2: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 100 characters",
    "string.empty": "please write your response to a maximum of 100 characters",
    "string.max": "your response must be 100 characters or less",
  }),
  B2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B2_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3", "4", "5", "6")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B3: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 100 characters",
    "string.empty": "please write your response to a maximum of 100 characters",
    "string.max": "your response must be 100 characters or less",
  }),
  B3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B3_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B4a: Joi.string()
    .trim()
    .max(5)
    .valid(null, "", "Yes", "No", "Maybe")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B4a_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B4a_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B4b: Joi.string().trim().max(3).valid(null, "", "Yes", "No").messages({
    "string.base": "please select a valid response",
    "string.empty": "please select a valid response",
  }),
  B4b_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B4b_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B5: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 100 characters",
    "string.empty": "please write your response to a maximum of 100 characters",
    "string.max": "your response must be 100 characters or less",
  }),
  B5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B5_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B6: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B6_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B7: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B7_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B7_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B8: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B8_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B8_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B9: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B9_comment: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 100 characters",
    "string.empty": "please write your response to a maximum of 100 characters",
    "string.max": "your response must be 100 characters or less",
  }),
  B9_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B10: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B10_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B10_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B11: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B11_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B11_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
  B12: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B12_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
    "string.base": "please write your response to a maximum of 2000 characters",
    "string.empty":
      "please write your response to a maximum of 2000 characters",
    "string.max": "your response must be 2000 characters or less",
  }),
  B12_score: Joi.string()
    .trim()
    .max(1)
    .valid(null, "", "0", "1", "2", "3")
    .messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
});

export default schemaFormSectionB;
