// ========== Imports ========== //

import "./NewsTable.css";

import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { Spinner } from "../../Common";
import { CreateNews, DeleteNews } from "..";
import { getNews } from "../../../logic";

import { Pagination } from "../../Common";

// ========== Component ========== //

// This logger created outside of component so that it is also available to the helper printEvents
const logger = new Loggito("NewsTable");

// Receives the eventId and return the table

function NewsTable() {
  // ========== Hook consts ========== //

  const [newsData, setNewsData] = useState("");

  const [currentPage, setCurrentPage] = useState();

  const PageSize = 12;

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return newsData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  // ========== other consts ========== //

  function rowTable(news, index) {
    // ========== JSX ========== //

    return (
      <tr key={news.route}>
        <td>
          <img src={news.mainImage.fileUrl} alt={news.title} />
        </td>
        <td>{news.title}</td>
        <td>{news.date.slice(0, 10)}</td>
        <td>{news.status}</td>
        <td>
          <Link to={`/admin/news/comments/${news.route}`} className="link">
            Manage Comments
          </Link>
          <br />
          <br />
          Pending: {news.commentsPending}
        </td>
        <td className="buttons-td">
          {
            <>
              <Link to={`/admin/news/view/${news.route}`} className="link">
                View
              </Link>{" "}
              /
              <Link to={`/admin/news/edit/${news.route}`} className="link">
                 Edit
              </Link>{" "}
              /
              <DeleteNews news={news} />
            </>
          }
        </td>
      </tr>
    );
  }
  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getNews(sessionStorage.token, (error, newsArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            logger.warn(error.message);
            if (
              error.message ===
              "error 401: session timed out, please log in again"
            ) {
              toast.info("session timed out, please log in again");
              delete sessionStorage.token;
              window.location.reload(false);
            } else toast.warn(error.message);
          }
          return;
        }
        setNewsData(newsArray.map(rowTable));
        setCurrentPage(1);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);

  // ========== JSX ========== //

  if (newsData !== "") {
    return (
      <div className="news-table">
        <h1>Manage News</h1>
        <CreateNews />
        <p>
          <strong>*</strong>A minimum of <strong>3 'Editor's Picks'</strong> are
          required for the component to show on the Home Page
        </p>
        <div className="table-container">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Main Image</th>
                <th>Title</th>
                <th>Date added</th>
                <th>Status</th>
                <th>Comments</th>
                <th>Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{currentTableData ? currentTableData : null}</tbody>
          </table>
        </div>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={newsData.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    );
  } else {
    return <Spinner />;
  }
}

export default NewsTable;
