// ========== Imports ========== //

import "./AdminMainPage.css";
import { useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import Loggito from "../utils/Loggito";
import withContext from "../utils/withContext";
import { FormAccessPage } from ".";
import AutomatedForm from "../Components/AdminComponents/CASTResults/Forms/AutomatedForm";

// ========== Page ========== //

function CandidateMainPage({ handleLogoutClick }) {
  // ========== Hook consts ========== //

  const [name, setName] = useState(null);

  const [code, setCode] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  // ========== other consts ========== //

  const logger = new Loggito("CandidateMainPage");

  // ========== useEffects ========== //

  // ========== Function to navigate between pages ========== //

  // I think the . here is incorrect
  const handleReturn = () => {
    navigate("./");
  };

  const handleNavigationToForm = () => {
    navigate(`/candidate/access/candidate`);
    logger.debug("navigate to form");
  };

  const handleNavigationToRegister = () => {
    navigate("register");

    logger.debug("navigate to register");
  };

  const handleNavigateTo = (location) => {
    navigate(`/`);
    navigate(location);
  };

  // ========== Functions ========== //

  const handleUpdateUserDetails = () => {
    handleLogoutClick();
  };

  const handleUpdateAccessCode = (code) => {
    setCode(code);
  };

  // ========== jsx ========== //

  return (
    <div className="">
      <main className="">
        <Routes>
          <Route
            path="/access"
            element={
              <FormAccessPage
                handleNavigationToRegister={handleNavigationToRegister}
                handleNavigationToForm={handleNavigationToForm}
                onUpdateAccessCode={handleUpdateAccessCode}
              />
            }
          />
          <Route
            path="/access/:environment"
            element={
              !sessionStorage.candidate_token ? (
                <Navigate to={`/candidate/access`} />
              ) : (
                <AutomatedForm accessCode={code} />
              )
            }
          />
        </Routes>
      </main>
      <footer className=""></footer>
    </div>
  );
}

export default withContext(CandidateMainPage);
