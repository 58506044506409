// ========== imports ========== //

import { useEffect, useState } from "react";

import { IoWarningOutline } from "react-icons/io5";

// ========== Page ========== //
function QuestionMcqList({
  question,
  questionText,
  register,
  qErrors,
  qErrorsAssessor,
  qErrorsCandidate,
  labels,
  onSubmit,
  environment,
  subquestion,
  mergedquestion,
}) {
  // ========== Hook consts ========== //

  // FISHE-YATES ALGORITHM TO COMPLETLY RANDOMLY SHUFFLE ARRAY

  const [labelsCandidate, setLabelsCandidate] = useState([]);

  useEffect(() => {
    if (environment === "candidate") {
      const labelsCandidateTemp = createLabelsScoreArray(labels);
      shuffleArray(labelsCandidateTemp);
      setLabelsCandidate(labelsCandidateTemp);
    }
  }, []);

  const createLabelsScoreArray = (array) => {
    const tempArray = [];
    labels.forEach((label, i) => {
      tempArray.push([label, i]);
    });
    return tempArray;
  };

  const shuffleArray = (labelsCandidate) => {
    for (let i = labelsCandidate.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = labelsCandidate[i];
      labelsCandidate[i] = labelsCandidate[j];
      labelsCandidate[j] = temp;
    }
  };

  return (
    <div
      className={`cast-form__question-container ${
        subquestion ? "cast-form__question-container--subquestion" : null
      } ${
        mergedquestion ? "cast-form__question-container--mergedquestion" : null
      }`}
      id={`${question}_link`}
    >
      <div className="cast-form__question cast-form__question--radio--column">
        <p className="p--m cast-form__question__qtext">
          {<b>{question}</b>}: {questionText}
        </p>
        {environment === "candidate" ? (
          <div className="radio-options" id={question}>
            {labelsCandidate.map((labelArray, i) => {
              return (
                <li
                  className="cast-form__question__qtext__listitem"
                  key={
                    Date.now().toString() +
                    Math.floor(Math.random() * 1000000).toString()
                  }
                >
                  <input
                    className="input--radio"
                    type="radio"
                    id={question + "_" + labelArray[1].toString()}
                    name={question}
                    value={labelArray[1]}
                    {...register(question, {
                      onChange: (e) => {
                        onSubmit();
                      },
                    })}
                  />
                  <label
                    htmlFor={question + "_" + labelArray[1].toString()}
                    className="p--sm"
                  >
                    {labelArray[0]}
                  </label>
                </li>
              );
            })}
          </div>
        ) : (
          <div className="radio-options" id={question}>
            {labels.map((label, i) => {
              return (
                <li
                  className="cast-form__question__qtext__listitem"
                  key={
                    Date.now().toString() +
                    Math.floor(Math.random() * 1000000).toString()
                  }
                >
                  <input
                    className="input--radio"
                    type="radio"
                    id={question + "_" + i.toString()}
                    name={question}
                    value={i}
                    {...register(question, {
                      onChange: (e) => {
                        onSubmit();
                      },
                    })}
                  />
                  <label
                    htmlFor={question + "_" + i.toString()}
                    className="p--sm"
                  >
                    {label}
                  </label>
                </li>
              );
            })}
          </div>
        )}
        <div className="cast-form__error-message">
          {qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrors?.message}</p>
            </div>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsCandidate && !qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsCandidate}</p>
            </div>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsAssessor && !qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsAssessor}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuestionMcqList;
