// ========== Imports ========== //

import "./CreateForm.css";

import { useState, useEffect } from "react";
import { createOrganisation, getOrganisations } from "../../../../logic";
import Loggito from "../../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import withContext from "../../../../utils/withContext";

// ========== Component ========== //

function ManageOrganisations({ context: { refreshTokenCount }, formType }) {
  // ========== Hook consts ========== //

  const [organisationsData, setOrganisationsData] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [state, setState] = useState(0);

  // ========== other consts ========== //

  const logger = new Loggito("ManageOrganisations");

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== Function to add/show input fields ========== //

  const changeState = () => {
    if (state === 0) {
      setState(1);
    } else if (state === 1) {
      setState(0);
    }
  };

  const handleChange = (input, value) => {
    setOrganisation(value);
    return;
  };

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getOrganisations(sessionStorage.token, (error, organisationsArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            logger.warn(error.message);
            if (
              error.message ===
              "error 401: session timed out, please log in again"
            ) {
              toast.info("session timed out, please log in again");
              delete sessionStorage.token;
              window.location.reload(false);
            } else toast.warn(error.message);
          }
          return;
        }
        setOrganisationsData(organisationsArray);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);

  // ========== Functions ========== //

  const handleCreateEvent = () => {
    try {
      // refreshTokenHandler();
      createOrganisation(sessionStorage.token, organisation, function (error) {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            logger.warn(error.message);
            // TODO: check this refresh_token implementation
            if (
              error.message ===
              "error 401: session timed out, please log in again"
            ) {
              toast.info("session timed out, please log in again");
              delete sessionStorage.token;
              refreshTokenCount("stop");
              window.location.reload(false);
            } else toast.warn(error.message);
          }
          return;
        }
        toast.success("organisation created successfully");
        window.location.reload(false);
      });
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  const introduceAndClose = () => {
    handleCreateEvent(organisation);
    changeState();
  };

  // ========== jsx ========== //

  return (
    <div className="create-form">
      {state === 0 ? (
        <button className="cta cta--secondary" onClick={changeState}>
          Create organisation
        </button>
      ) : null}
      {state === 1 ? (
        <div className="create-form-overlay">
          <div className="p--m p--bold create-form-overlay__content">
            <h3 className="h3">Create Organisation Form</h3>
            <div className="input-container">
              <label htmlFor="organisation">Organisation:</label>

              <input
                name="organisation"
                type="text"
                onChange={(e) => handleChange("organisation", e.target.value)}
                className=""
                placeholder="This can be modified later..."
              />
            </div>
            <div className="buttons-container">
              <button className="cta cta--primary" onClick={introduceAndClose}>
                Save
              </button>
              <button className="cta cta--outline" onClick={changeState}>
                Cancel
              </button>
            </div>
            {organisationsData.length > 0 ? (
              <div className="groups-list-container">
                <h4 className="h4">Existing organisations:</h4>
                <ul>
                  {organisationsData
                    ? organisationsData.map((org, index) => <li>{org.name}</li>)
                    : null}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default withContext(ManageOrganisations);
