// ========== imports ========== //
import "./AddNews.css";

import Loggito from "../../../utils/Loggito";

import { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
// to install $npm install @hookform/resolvers joi

import { ServerError } from "errors";
import { getNewsItem, updateNews } from "../../../logic";
import { schemaAddNewsForm } from "../../../validators";

import { Spinner } from "../../Common";
import { RichTextEditorTiny, S3Upload } from "..";
import kebabFileName from "../Helpers/kebabFileName";

import parse from "html-react-parser";
// ========== Page ========== //

function AddNews() {
  // ========== Hook consts ========== //

  const [newsData, setNewsData] = useState();
  const [body, setBody] = useState();
  const [mainImage, setMainImage] = useState();
  const [file1, setFile1] = useState();
  const [file2, setFile2] = useState();
  const [file3, setFile3] = useState();

  const params = useParams();
  const navigate = useNavigate();

  let route = params.route;

  useEffect(() => {
    logger.info("componentDidMount");
    getNewsIemData("setData");
  }, []);

  const getNewsIemData = (action) => {
    try {
      getNewsItem(sessionStorage.token, route, (error, newsDataRetrieved) => {
        if (error) {
          if (error instanceof ServerError) {
            navigate("/admin/newstable");
            toast.error(error.message);
            logger.error(error.message);
          } else {
            navigate("/admin/newstable");

            logger.warn(error.message);
            if (
              error.message ===
              "error 401: session timed out, please log in again"
            ) {
              toast.info("session timed out, please log in again");
              delete sessionStorage.token;
              window.location.reload(false);
            } else toast.warn(error.message);
          }
          return;
        }
        if (action === "setData") setNewsData(newsDataRetrieved);
        else if (action === "getEditorsPick")
          return newsDataRetrieved.editorsPick;
      });
    } catch (error) {
      navigate("/admin/newstable");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };
  // ========== other consts ========== //

  const logger = new Loggito("AddNews");
  // ========== useEffects ========== /

  const handleUpdateNews = (action, data) => {
    const values = getValues();

    const newsDataToUpdate = {};
    if (action === "details") {
      newsDataToUpdate.title = values.title;
      newsDataToUpdate.newRoute = kebabFileName(values.title);
      newsDataToUpdate.status = values.status;
      newsDataToUpdate.editorsPick = values.editorsPick;
      newsDataToUpdate.tags = values.tags;
      newsDataToUpdate.video = values.video;
      values.date
        ? (newsDataToUpdate.date = new Date(values.date))
        : (newsDataToUpdate.date = null);
    }
    if (action === "content") {
      newsDataToUpdate.newsHtml = data;
    }
    if (action === "mainImage") {
      newsDataToUpdate.mainImage = data;
    }
    if (action === "file1") {
      newsDataToUpdate.file1 = data;
    }
    if (action === "file2") {
      newsDataToUpdate.file2 = data;
    }
    if (action === "file3") {
      newsDataToUpdate.file3 = data;
    }

    try {
      updateNews(
        sessionStorage.token,
        action,
        route,
        newsDataToUpdate,

        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              // TODO: check if this needs error 500: or something similar
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                delete sessionStorage.token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }

          if (action === "details") {
            const newRoute = `/admin/news/edit/${kebabFileName(data.title)}`;
            navigate(newRoute);
          }
          toast.success("News article updated successfully");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  const handleTextEditorSave = (html) => {
    setBody(html);
    handleUpdateNews("content", html);
  };

  const handleMainImageUpload = (file) => {
    setMainImage(file);
    handleUpdateNews("mainImage", file);
  };

  const handleFile1Upload = (file) => {
    setFile1(file);
    handleUpdateNews("file1", file);
  };

  const handleFile2Upload = (file) => {
    setFile2(file);
    handleUpdateNews("file2", file);
  };

  const handleFile3Upload = (file) => {
    setFile3(file);
    handleUpdateNews("file3", file);
  };

  const handleFormSubmit = (data) => {
    handleUpdateNews("details", data);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaAddNewsForm),
    defaultValues: {
      /* editorsPick: useMemo(() => {
        if (newsData.editorsPick) return newsData.editorsPick;
      }, [newsData]), */
      // editorsPick: newsData ? newsData.editorsPick : true,
      // TODO: default value works here but not at field level - but can't get newsData before this const is set
      /* editorsPick: () => {
        const value = getNewsIemData("getEditorsPick");
        return value;
      }, */
    },
  });

  // ========== jsx ========== //
  if (newsData) {
    return (
      <div className="">
        <div className="content--admin">
          <h2 className="h2">Add News</h2>
          <h3 className="h3">Please complete the details below:</h3>
          <form
            onSubmit={handleSubmit((data) => handleFormSubmit(data))}
            className="add-news-form"
            noValidate={true}
          >
            <div>{/* TODO: DATE */}</div>
            <div>
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                placeholder=""
                name="title"
                defaultValue={newsData.title}
                {...register("title", {})}
              />
              {errors.title && (
                <p className="input__error-message">{errors.title?.message}</p>
              )}
            </div>
            <div>
              <label className="p--m label--select" htmlFor="status">
                Select status:
              </label>
              <select
                className="p--m input--select"
                {...register("status")}
                name="status"
                defaultValue={newsData.status}
              >
                <option value="draft">Draft</option>
                <option value="published">Published</option>
                <option value="inactive">Inactive</option>
              </select>
              {errors.status && (
                <p className="input__error-message">{errors.status?.message}</p>
              )}
            </div>
            <div>
              <label className="label--select" htmlFor="date">
                Select a date:
              </label>
              <input
                type="date"
                placeholder=""
                {...register("date")}
                defaultValue={newsData.date.slice(0, 10)}
              />

              {errors.date && (
                <p className="input__error-message">{errors.date?.message}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="editorsPick"
                className="label--select add-news-form__checkbox-label"
                type="checkbox"
              >
                Select as Editor's pick:
              </label>

              <select
                {...register("editorsPick", {})}
                defaultValue={newsData.editorsPick}
                className="input--select p--m"
              >
                <option value={true}>Selected</option>
                <option value={false}>Not selected</option>
              </select>
              {errors.editorsPick && (
                <p className="input__error-message">
                  {errors.editorsPick?.message}
                </p>
              )}
              <p className="p--m"></p>
            </div>

            <div className="p--break">
              <label htmlFor="tags">Tags (comma separated; optional):</label>
              <input
                type="string"
                placeholder=""
                name="tags"
                defaultValue={newsData.tags}
                {...register("tags", {})}
              />
              {errors.tags && (
                <p className="input__error-message">{errors.tags?.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="video">Video content - YouTube URL link:</label>
              <input
                type="text"
                placeholder=""
                name="video"
                defaultValue={newsData.video}
                {...register("video", {})}
              />
              {errors.video && (
                <p className="input__error-message">{errors.video?.message}</p>
              )}
            </div>
            <button type="submit" className="cta cta--accent cta--save">
              Save
            </button>
          </form>
          <h3 className="h3">Article Body:</h3>
          <RichTextEditorTiny
            onSaveContent={handleTextEditorSave}
            startingValue={newsData.newsHtml}
            environment="news"
          />

          <div className="">
            <S3Upload
              title="Upload Main Image (optional)"
              onFileUpload={handleMainImageUpload}
              type="image"
              currentFileName={newsData.mainImage.fileName}
            />
            <div></div>
          </div>
          <div className="upload-files--group">
            <S3Upload
              title="Upload File 1 (optional)"
              onFileUpload={handleFile1Upload}
              type="file"
              currentFileName={newsData.file1.fileName}
            />
            <S3Upload
              title="Upload File 2 (optional)"
              onFileUpload={handleFile2Upload}
              type="file"
              currentFileName={newsData.file2.fileName}
            />
            <S3Upload
              title="Upload File 3 (optional)"
              onFileUpload={handleFile3Upload}
              type="file"
              currentFileName={newsData.file3.fileName}
            />
          </div>
        </div>
      </div>
    );
  } else return <Spinner />;
}

export default AddNews;
