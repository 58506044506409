import Joi from "joi";

const schemaFormSection1 = Joi.object({
  detailsFullName: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write your full name",
    "string.empty": "please write your full name",
    "string.max": "your response must be 100 characters or less",
  }),
  detailsCandidateEmail: Joi.string()
    .trim()
    .pattern(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    )
    .max(254)
    .allow("")
    .allow(null)
    .messages({
      "string.pattern.base": `candidate email is not in the correct format`,
      "string.base": `candidate email is not the correct type`,
      "string.empty": `candidate email cannot be empty`,
      "string.max": "candidate email must be 254 characters or less",
    }),
  detailsOrganisation: Joi.string()
    .trim()
    .max(100)
    .allow("")
    .allow(null)
    .messages({
      "string.base":
        "please write the name of the organisation that you work for",
      "string.empty":
        "please write the name of the organisation that you work for",
      "string.max": "your response must be 100 characters or less",
    }),
  detailsRole: Joi.string()
    .trim()
    .max(100)
    .valid(
      "",
      null,
      "entry1",
      "entry2",
      "senior",
      "manager",
      "seniormanager",
      "managingdirector",
      "ceo"
    )
    .messages({
      "string.base": "please select your current role at work",
      "string.empty": "please select your current role at work",
      "string.max": "your response must be 100 characters or less",
    }),
  detailsGender: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please select a gender",
    "string.empty": "please select a gender",
    "string.max": "your response must be 100 characters or less",
  }),
  detailsAssessor: Joi.string().trim().max(100).allow("").allow(null).messages({
    "string.base": "please write the full name of the assessor",
    "string.empty": "please write the full name of the assessor",
    "string.max": "your response must be 100 characters or less",
  }),
  detailsDateAssessed: Joi.string()
    .trim()
    .max(100)
    .allow("")
    .allow(null)
    .messages({
      "string.base": "please select a date",
      "string.empty": "please select a date",
      "string.max": "your response must be 100 characters or less",
    }),
});

export default schemaFormSection1;
