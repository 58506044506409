// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import ScrollToTop from "../../../../utils/ScrollToTop";
import withContext from "../../../../utils/withContext";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionF,
  validateSectionFAssessor,
  validateSectionFCandidate,
  validateSectionFAutoSave,
} from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionDetailsAdmin,
  QuestionMcq03,
} from "./FormQuestions";

import ConfirmSubmission from "./ConfirmSubmission";

// ========== Page ========== //

function SectionF({
  environment,
  onApproveSection,
  existingValues,
  setExistingValues,
  onUpdateErrorsAssessor,
  formButtonText,
  triggerHandleValidateSectionAssessor,
}) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});
  const [renderErrors, setRenderErrors] = useState(0);

  const [confirmSubmissionPopup, setConfirmSubmissionPopup] = useState(false);

  const [submitted, setSubmitted] = useState(null);

  const form = useRef();
  const location = useLocation();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    triggerHandleValidateSectionAssessor > 0 && handleValidateSectionAssessor();
  }, [triggerHandleValidateSectionAssessor]);

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    return () => {
      // This function will be called when the component is unmounted
      // saveFormData(null);
      handleValidateSectionAutoSave();
    };
  }, []);

  useEffect(() => {
    if (confirmSubmissionPopup === true) handleValidateSectionCandidate();
    if (confirmSubmissionPopup === true) setSubmitted(true);
  }, [confirmSubmissionPopup]);

  // These useEffects are to update the state in the AssessorNav component

  useEffect(() => {
    if (environment !== "candidate")
      onUpdateErrorsAssessor(errorsAssessor, "sectionF");
  }, [errorsAssessor]);

  const {
    register: registerF,
    handleSubmit: handleSubmitF,
    reset,
    formState: { errors },
    getValues: getValuesF,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionF),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        if (key[0] === "F") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 13 ||
      keyCode === 32 ||
      keyCode === 56 ||
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 96 && keyCode <= 111)
    ) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    // const delayFn = setTimeout(() => saveFormData(null), 3000);
    const delayFn = setTimeout(() => handleValidateSectionAutoSave(), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keyup", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  // START SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const handleUserKeyPressAfterSubmit = useCallback(
    (event) => {
      const { key, keyCode } = event;

      if (
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 13 ||
        keyCode === 32 ||
        keyCode === 56 ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 96 && keyCode <= 111)
      ) {
        if (submitted) {
          if (environment === "assessor") {
            const errors = validateSectionFAssessor(getValuesF());
            setErrorsAssessor(errors ? errors : {});
          } else if (environment === "candidate") {
            const errors = validateSectionFCandidate(getValuesF());
            setErrorsCandidate(errors ? errors : {});
          }
        }
      }
    },
    [submitted]
  );

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPressAfterSubmit);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener(
        "keyup",
        handleUserKeyPressAfterSubmit
      );
    };
  }, [handleUserKeyPressAfterSubmit]);

  // END SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    const questionNumbersArray = [
      "F1",
      "F2",
      "F3",
      "F4",
      "F5",
      "F6",
      "F7",
      "F8",
      "F9",
      "F10",
      "F11",
      "F12",
      "F13",
      "F14",
      "F15",
      "F16",
    ];

    const values = getValuesF();

    let groupedValuesObject = {};

    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    console.log(values);

    let endpoint;

    if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    )
      endpoint = "candidateassessor";
    else if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
      groupedValuesObject.detailsStatus = "In progress";
    } else endpoint = "candidate";

    // This needs to go after the previous block to overwrite the detailsStatus
    if (approved === "approved") {
      groupedValuesObject.detailsFormSectionApproved = "sectionF";
      groupedValuesObject.detailsDateAssessed = new Date(Date.now());
      // TODO: for the interviewer form this status change should be based on a final review of all the form and not just section F as this section can be completed out of sequence
      groupedValuesObject.detailsStatus = "Pending report";
    }

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                if (sessionStorage.token) delete sessionStorage.token;
                if (sessionStorage.candidate_token)
                  delete sessionStorage.candidate_token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          process.env.REACT_APP_LOGGITO_LEVEL === "0" &&
            toast.success("Your answers to section F have been saved");
          setExistingValues((prevValues) => ({
            ...prevValues,
            ...groupedValuesObject,
          }));
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    setSubmitted(true);
    const formValues = getValuesF();
    const errors = validateSectionFAssessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      toast.success("Your answers to section F have been saved");
      if (environment === "candidate") onApproveSection("sectionF");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesF();
    const errors = validateSectionFCandidate(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      toast.success("Your answers to section F have been saved");
      onApproveSection("sectionF");
      return;
    }
  };

  const handleValidateSectionAutoSave = () => {
    const formValues = getValuesF();
    const validationErrors = validateSectionFAutoSave(formValues);
    if (validationErrors) {
      Object.keys(errors).forEach((key) => delete errors[key]);
      Object.keys(validationErrors).forEach(
        (key) => (validationErrors[key] = { message: validationErrors[key] })
      );
      Object.assign(errors, validationErrors);
      setRenderErrors(renderErrors + 1);
      return;
    } else {
      Object.keys(errors).forEach((key) => delete errors[key]);
      setRenderErrors(renderErrors + 1);
      saveFormData(null);
      return;
    }
  };

  const handleSubmitOnClick = () => {
    // saveFormData();
    handleValidateSectionAutoSave();
    if (submitted) {
      if (environment === "assessor") {
        const errors = validateSectionFAssessor(getValuesF());
        setErrorsAssessor(errors ? errors : {});
      } else if (environment === "candidate") {
        const errors = validateSectionFCandidate(getValuesF());
        setErrorsCandidate(errors ? errors : {});
      }
    }
  };

  const confirmSubmissionCandidate = (state) => {
    setConfirmSubmissionPopup(state);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section F");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <ScrollToTop>
      <form
        ref={form}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmitF((data) => handleSubmitOnClick());
        }}
        className="cast-form"
        noValidate={true}
      >
        <ConfirmSubmission
          confirmSubmissionPopup={confirmSubmissionPopup}
          onConfirmSubmissionCandidate={confirmSubmissionCandidate}
        />
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        {environment === "assessor" ? (
          <div className="cast-form__title-block">
            <h2 className="h2">Section F</h2>
            <p className="p--m">
              SUPPORTIVE - DEMANDING SCALES (Professional Development/People
              Management)
              <br />
              <br />
              Now I am going to ask you about things that you might say to a
              colleague, or a manager might say to a team member. I am going to
              read you a set of statements, and I want you to think about how
              much each statement is likely to support or hinder development.
              Keep a focus on whether the statement is likely to help a person
              develop and not just whether it might make a person feel good.
              <br />
              <br />
              Try not to get too focused on the style of the language, we are
              thinking of the essence of the statement.
              <br />
              <br />
              Score each statement from 0-3 according to how well they reflect
              qualities that are likely to help or hinder the development of a
              worker. Think in a general sense and not so much about the age of
              a child.
              <br />
              <br />
              0 = may set back development <br />
              1 = unhelpful for development <br />
              2 = helpful for development <br />3 = very helpful for
              development.
            </p>
          </div>
        ) : null}
        {environment === "candidate" ? (
          <div className="cast-form__title-block">
            <h2 className="h2">Section F</h2>
            <p className="p--m">
              This section is about development at work. I am going to ask you
              about things that you might say to a colleague, or a manager might
              say to a team member.
              <br />
              <br />
              You will be presented with a set of statements. Please think about
              how much each statement is likely to support or hinder
              development. Focus on the essence of what is being stated, even if
              the language does not translate exactly to your work culture.
              <br />
              <br />
              Score these statements from 0-3 according to how well they reflect
              qualities that are likely to support or hinder development.
              <br />
              <br />
              0 = may set back development <br />
              1 = unhelpful for development <br />
              2 = helpful for development <br />3 = very helpful for
              development.
            </p>
          </div>
        ) : null}

        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F1"}
          questionText={"Great job you are so talented!"}
          register={registerF}
          qErrors={errors.F1}
          qErrorsAssessor={errorsAssessor.F1}
          qErrorsCandidate={errorsCandidate.F1}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F1"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"undemanding"}
            qErrorsAssessor={{
              score: errorsAssessor.F1_score,
              comment: errorsAssessor.F1_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F1"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F2"}
          questionText={
            "You have improved your analytical skills since starting here."
          }
          register={registerF}
          qErrors={errors.F2}
          qErrorsAssessor={errorsAssessor.F2}
          qErrorsCandidate={errorsCandidate.F2}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F2"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"supportive"}
            qErrorsAssessor={{
              score: errorsAssessor.F2_score,
              comment: errorsAssessor.F2_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F2"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F3"}
          questionText={
            "Well Done! Now, let's see if we can think about one thing that could have been even better?"
          }
          register={registerF}
          qErrors={errors.F3}
          qErrorsAssessor={errorsAssessor.F3}
          qErrorsCandidate={errorsCandidate.F3}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F3"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"demanding"}
            qErrorsAssessor={{
              score: errorsAssessor.F3_score,
              comment: errorsAssessor.F3_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F3"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F4"}
          questionText={
            "I don't think you have enough experience for this task."
          }
          register={registerF}
          qErrors={errors.F4}
          qErrorsAssessor={errorsAssessor.F4}
          qErrorsCandidate={errorsCandidate.F4}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F4"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"unsupportive"}
            qErrorsAssessor={{
              score: errorsAssessor.F4_score,
              comment: errorsAssessor.F4_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F4"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F5"}
          questionText={"You are someone who loves learning – that is great!"}
          register={registerF}
          qErrors={errors.F5}
          qErrorsAssessor={errorsAssessor.F5}
          qErrorsCandidate={errorsCandidate.F5}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F5"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"supportive"}
            qErrorsAssessor={{
              score: errorsAssessor.F5_score,
              comment: errorsAssessor.F5_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F5"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F6"}
          questionText={"I need you to start trying harder."}
          register={registerF}
          qErrors={errors.F6}
          qErrorsAssessor={errorsAssessor.F6}
          qErrorsCandidate={errorsCandidate.F6}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F6"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"unsupportive"}
            qErrorsAssessor={{
              score: errorsAssessor.F6_score,
              comment: errorsAssessor.F6_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F6"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F7"}
          questionText={
            "That was hard. Don't feel bad if you can't do it, yet."
          }
          register={registerF}
          qErrors={errors.F7}
          qErrorsAssessor={errorsAssessor.F7}
          qErrorsCandidate={errorsCandidate.F7}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F7"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"supportive"}
            qErrorsAssessor={{
              score: errorsAssessor.F7_score,
              comment: errorsAssessor.F7_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F7"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F8"}
          questionText={"Well, at least you tried!"}
          register={registerF}
          qErrors={errors.F8}
          qErrorsAssessor={errorsAssessor.F8}
          qErrorsCandidate={errorsCandidate.F8}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F8"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"undemanding"}
            qErrorsAssessor={{
              score: errorsAssessor.F8_score,
              comment: errorsAssessor.F8_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F8"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F9"}
          questionText={"You don't seem to be making very much progress."}
          register={registerF}
          qErrors={errors.F9}
          qErrorsAssessor={errorsAssessor.F9}
          qErrorsCandidate={errorsCandidate.F9}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F9"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"unsupportive"}
            qErrorsAssessor={{
              score: errorsAssessor.F9_score,
              comment: errorsAssessor.F9_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F9"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F10"}
          questionText={
            "That didn’t work. Let’s talk about how you approached it and what might work better."
          }
          register={registerF}
          qErrors={errors.F10}
          qErrorsAssessor={errorsAssessor.F10}
          qErrorsCandidate={errorsCandidate.F10}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F10"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"demanding"}
            qErrorsAssessor={{
              score: errorsAssessor.F10_score,
              comment: errorsAssessor.F10_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F10"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F11"}
          questionText={
            "Maybe this just isn’t your strength. Don’t worry, you have other things to contribute."
          }
          register={registerF}
          qErrors={errors.F11}
          qErrorsAssessor={errorsAssessor.F11}
          qErrorsCandidate={errorsCandidate.F11}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F11"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"undemanding"}
            qErrorsAssessor={{
              score: errorsAssessor.F11_score,
              comment: errorsAssessor.F11_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F11"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F12"}
          questionText={
            "We have high standards. I’m holding you to them because I know we can reach them together."
          }
          register={registerF}
          qErrors={errors.F12}
          qErrorsAssessor={errorsAssessor.F12}
          qErrorsCandidate={errorsCandidate.F12}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F12"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"demanding"}
            qErrorsAssessor={{
              score: errorsAssessor.F12_score,
              comment: errorsAssessor.F12_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F12"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F13"}
          questionText={"This is hard don't feel bad if you can't do it."}
          register={registerF}
          qErrors={errors.F13}
          qErrorsAssessor={errorsAssessor.F13}
          qErrorsCandidate={errorsCandidate.F13}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F13"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"undemanding"}
            qErrorsAssessor={{
              score: errorsAssessor.F13_score,
              comment: errorsAssessor.F13_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F13"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F14"}
          questionText={
            "That didn't work out very well. I don't think it's one of your strengths."
          }
          register={registerF}
          qErrors={errors.F14}
          qErrorsAssessor={errorsAssessor.F14}
          qErrorsCandidate={errorsCandidate.F14}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F14"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"unsupportive"}
            qErrorsAssessor={{
              score: errorsAssessor.F14_score,
              comment: errorsAssessor.F14_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F14"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F15"}
          questionText={"The amount of effort you put in here is good."}
          register={registerF}
          qErrors={errors.F15}
          qErrorsAssessor={errorsAssessor.F15}
          qErrorsCandidate={errorsCandidate.F15}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F15"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"supportive"}
            qErrorsAssessor={{
              score: errorsAssessor.F15_score,
              comment: errorsAssessor.F15_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F15"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
          question={"F16"}
          questionText={
            "I believe you are very capable and ready for more responsibility."
          }
          register={registerF}
          qErrors={errors.F16}
          qErrorsAssessor={errorsAssessor.F16}
          qErrorsCandidate={errorsCandidate.F16}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitF((data) => handleSubmitOnClick())}
            question={"F16"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerF}
            category={"demanding"}
            qErrorsAssessor={{
              score: errorsAssessor.F16_score,
              comment: errorsAssessor.F16_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"F16"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        {formDataBeingSent ? (
          <button type="button" className="cta cta--accent--clicked">
            {formButtonText}
          </button>
        ) : null}
        {environment === "assessor" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleValidateSectionAssessor}
          >
            {formButtonText}
          </button>
        ) : null}
        {environment === "candidate" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={() => confirmSubmissionCandidate("active")}
          >
            {formButtonText}
          </button>
        ) : null}
      </form>
    </ScrollToTop>
  );
}

export default withContext(SectionF);
