// ========== imports ========== //
import { IoWarningOutline } from "react-icons/io5";

// ========== Page ========== //
function QuestionShortText({
  question,
  questionText,
  register,
  qErrors,
  qErrorsAssessor,
  qErrorsCandidate,
  section,
  placeholder,
  subquestion,
  mergedquestion,
  watch,
}) {
  // ========== Hook consts ========== //

  return (
    <div
      className={`cast-form__question-container ${
        subquestion ? "cast-form__question-container--subquestion" : null
      } ${
        mergedquestion ? "cast-form__question-container--mergedquestion" : null
      }`}
      id={`${question}_link`}
    >
      <div className="cast-form__question">
        <label className="p--m cast-form__question__qtext" htmlFor={question}>
          {typeof questionText === "string" &&
          section !== "1" &&
          section !== "B" ? (
            <>
              <b>{question}</b>: {questionText}
            </>
          ) : null}
          {typeof questionText === "string" && section === "1" ? (
            <>
              <b>{questionText}</b>
            </>
          ) : null}
          {typeof questionText === "string" && section === "B" ? (
            <>{questionText}</>
          ) : null}
          {typeof questionText === "object"
            ? questionText.map((text, i) => {
                if (i === 0) {
                  return (
                    <li
                      className="cast-form__question__qtext__listitem"
                      key={
                        Date.now().toString() +
                        Math.floor(Math.random() * 1000000).toString()
                      }
                    >
                      <b>{question}</b>: {text}
                    </li>
                  );
                } else
                  return (
                    <li
                      className="cast-form__question__qtext__listitem"
                      key={
                        Date.now().toString() +
                        Math.floor(Math.random() * 1000000).toString()
                      }
                    >
                      {text}
                    </li>
                  );
              })
            : null}
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <input
            className="p--m"
            type="text"
            placeholder={placeholder ? placeholder : null}
            name={question}
            id={question}
            {...register(question, {})}
          />
          {watch || watch === "" ? (
            watch.length > 100 ? (
              <span style={{ marginLeft: "8px" }} className="p--xs">
                <span style={{ color: "var(--color-accent)" }}>
                  {watch.length}
                </span>
                /100
              </span>
            ) : (
              <span style={{ marginLeft: "8px" }} className="p--xs">
                {watch.length}/100
              </span>
            )
          ) : null}
        </div>
        <div className="cast-form__error-message">
          {qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrors?.message}</p>
            </div>
          )}
        </div>
        {/* qErrors created by the forms initial handleSubmit are given priority - to avoid repeated error messages */}
        <div className="cast-form__error-message">
          {qErrorsCandidate && !qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsCandidate}</p>
            </div>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsAssessor && !qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsAssessor}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuestionShortText;
