// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";
import ScrollToTop from "../../../../utils/ScrollToTop";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionD,
  validateSectionDAssessor,
  validateSectionDCandidate,
  validateSectionDAutoSaveInterviewer,
} from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionMcq14Strongly,
  QuestionMcqListHorizontal,
  QuestionTextBox,
  QuestionShortText,
  QuestionMcqYesNo,
} from "./FormQuestions";

// ========== Page ========== //

function SectionD({
  environment,
  onApproveSection,
  existingValues,
  setExistingValues,
  onUpdateErrorsAssessor,
  formButtonText,
  triggerHandleValidateSectionAssessor,
}) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});
  const [renderErrors, setRenderErrors] = useState(0);

  const [submitted, setSubmitted] = useState(null);

  const form = useRef();
  const location = useLocation();

  useEffect(() => {
    triggerHandleValidateSectionAssessor > 0 && handleValidateSectionAssessor();
  }, [triggerHandleValidateSectionAssessor]);

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    return () => {
      // This function will be called when the component is unmounted
      // saveFormData(null);
      handleValidateSectionAutoSave();
    };
  }, []);

  // These useEffects are to update the state in the AssessorNav component

  useEffect(() => {
    if (environment !== "candidate")
      onUpdateErrorsAssessor(errorsAssessor, "sectionD");
  }, [errorsAssessor]);

  const {
    register: registerD,
    handleSubmit: handleSubmitD,
    reset,
    formState: { errors },
    getValues: getValuesD,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionD),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        if (key[0] === "D") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 13 ||
      keyCode === 32 ||
      keyCode === 56 ||
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 96 && keyCode <= 111)
    ) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    // const delayFn = setTimeout(() => saveFormData(null), 3000);
    const delayFn = setTimeout(() => handleValidateSectionAutoSave(), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keyup", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  // START SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const handleUserKeyPressAfterSubmit = useCallback(
    (event) => {
      const { key, keyCode } = event;

      if (
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 13 ||
        keyCode === 32 ||
        keyCode === 56 ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 96 && keyCode <= 111)
      ) {
        if (submitted) {
          if (environment === "assessor") {
            const errors = validateSectionDAssessor(getValuesD());
            setErrorsAssessor(errors ? errors : {});
          } else if (environment === "candidate") {
            const errors = validateSectionDCandidate(getValuesD());
            setErrorsCandidate(errors ? errors : {});
          }
        }
      }
    },
    [submitted]
  );

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPressAfterSubmit);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener(
        "keyup",
        handleUserKeyPressAfterSubmit
      );
    };
  }, [handleUserKeyPressAfterSubmit]);

  // END SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    const questionNumbersArray = [
      "D1",
      "D2",
      "D3",
      "D4",
      "D5",
      "D6",
      "D7a_1",
      "D7a_2",
      "D7a_3",
      "D7b",
      "D8",
      "D9",
      "D10",
      /*  "D10a",
      "D10b", */
    ];

    const values = getValuesD();

    let groupedValuesObject = {};

    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    console.log(values);

    if (approved === "approved")
      groupedValuesObject.detailsFormSectionApproved = "sectionD";

    let endpoint;

    if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    )
      endpoint = "candidateassessor";
    else if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
      groupedValuesObject.detailsStatus = "In progress";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                if (sessionStorage.token) delete sessionStorage.token;
                if (sessionStorage.candidate_token)
                  delete sessionStorage.candidate_token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          process.env.REACT_APP_LOGGITO_LEVEL === "0" &&
            toast.success("Your answers to section D have been saved");
          setExistingValues((prevValues) => ({
            ...prevValues,
            ...groupedValuesObject,
          }));
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    setSubmitted(true);
    const formValues = getValuesD();
    const errors = validateSectionDAssessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      toast.success("Your answers to section D have been saved");
      if (environment === "candidate") onApproveSection("sectionD");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesD();
    const errors = validateSectionDCandidate(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      toast.success("Your answers to section D have been saved");
      onApproveSection("sectionD");
      return;
    }
  };

  const handleValidateSectionAutoSave = () => {
    const formValues = getValuesD();
    const validationErrors = validateSectionDAutoSaveInterviewer(formValues);
    if (validationErrors) {
      Object.keys(errors).forEach((key) => delete errors[key]);
      Object.keys(validationErrors).forEach(
        (key) => (validationErrors[key] = { message: validationErrors[key] })
      );
      Object.assign(errors, validationErrors);
      setRenderErrors(renderErrors + 1);
      return;
    } else {
      Object.keys(errors).forEach((key) => delete errors[key]);
      setRenderErrors(renderErrors + 1);
      saveFormData(null);
      return;
    }
  };

  const handleSubmitOnClick = () => {
    // saveFormData();
    handleValidateSectionAutoSave();
    if (submitted) {
      if (environment === "assessor") {
        const errors = validateSectionDAssessor(getValuesD());
        setErrorsAssessor(errors ? errors : {});
      } else if (environment === "candidate") {
        const errors = validateSectionDCandidate(getValuesD());
        setErrorsCandidate(errors ? errors : {});
      }
    }
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section D");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <ScrollToTop>
      <form
        ref={form}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmitD((data) => handleSubmitOnClick());
        }}
        className="cast-form"
        noValidate={true}
      >
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}

        <div className="cast-form__title-block">
          <h2 className="h2">Section D</h2>
          <p className="p--m">
            OWNERSHIP (which includes having an optimistic outlook and
            responsibility for one’s own happiness and outcomes in life)
            <br />
            <br />
            All ratings on the statements are from 0-3 according to how strongly
            you agree with it.
            <br />
            <br />
            0 strongly disagree <br />1 disagree, <br />2 agree <br />3 strongly
            agree
            <br /> <br />
            We are looking at trends, on average, etc. Be careful not to focus
            on an exception to the rule that may come to mind.
          </p>
        </div>

        <QuestionMcq14Strongly
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D1"}
          questionText={
            "Taking responsibility tends to be a trait of successful people and blaming tends to be a trait of unsuccessful people."
          }
          register={registerD}
          qErrors={errors.D1}
          qErrorsAssessor={errorsAssessor.D1}
          qErrorsCandidate={errorsCandidate.D1}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D1"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D1_score,
              comment: errorsAssessor.D1_comment,
            }}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D2"}
          questionText={
            "Where you end up in life is usually dictated by the choices you make."
          }
          register={registerD}
          qErrors={errors.D2}
          qErrorsAssessor={errorsAssessor.D2}
          qErrorsCandidate={errorsCandidate.D2}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D2"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D2_score,
              comment: errorsAssessor.D2_comment,
            }}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D3"}
          questionText={
            "Success is not the key to happiness; happiness is the key to success."
          }
          register={registerD}
          qErrors={errors.D3}
          qErrorsAssessor={errorsAssessor.D3}
          qErrorsCandidate={errorsCandidate.D3}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D3"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D3_score,
              comment: errorsAssessor.D3_comment,
            }}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D4"}
          questionText={
            'This one is about you again, "I can fail many times, but not become a failure until I start to blame others."'
          }
          register={registerD}
          qErrors={errors.D4}
          qErrorsAssessor={errorsAssessor.D4}
          qErrorsCandidate={errorsCandidate.D4}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D4"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D4_score,
              comment: errorsAssessor.D4_comment,
            }}
          />
        ) : null}
        <QuestionMcqListHorizontal
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D5"}
          questionText={
            "If we take a line with pessimism at one end and optimism at the other end with realist in the middle, where do you place yourself?"
          }
          register={registerD}
          qErrors={errors.D5}
          qErrorsAssessor={errorsAssessor.D5}
          qErrorsCandidate={errorsCandidate.D5}
          labels={[
            "Pessimist",
            "Pessiminst-Realist",
            "Realist",
            "Realist-Optimist",
            "Optimist",
          ]}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D5"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D5_score,
              comment: errorsAssessor.D5_comment,
            }}
            scoreShown={false}
            scoreGuide={
              "Pessimist = 0, pessimist-realist = 1 Optimism or realist = 2 Realist-optimist= 3"
            }
          />
        ) : null}
        <QuestionTextBox
          question={"D6"}
          questionText={"How are you learning and developing yourself?"}
          register={registerD}
          qErrors={errors.D6}
          qErrorsAssessor={errorsAssessor.D6}
          qErrorsCandidate={errorsCandidate.D6}
          watch={watch("D6")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D6"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D6_score,
              comment: errorsAssessor.D6_comment,
            }}
            scoreGuide={
              "Reading relevant books, taking on challenges, learning by doing, reflective processes, learning from a master, mentor, training, regular deliberate practice."
            }
          />
        ) : null}
        <QuestionMcqYesNo
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D7a_1"}
          questionText={"Do you have a habit you would like to change?"}
          register={registerD}
          qErrors={errors.D7a_1}
          qErrorsAssessor={errorsAssessor.D7a_1}
          qErrorsCandidate={errorsCandidate.D7a_1}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D7a_1"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D7a_1_score,
              comment: errorsAssessor.D7a_1_comment,
            }}
          />
        ) : null}
        <QuestionMcqYesNo
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D7a_2"}
          questionText={
            "(If answered 'yes' to the previous question) Do you have a plan for how to change it?"
          }
          register={registerD}
          qErrors={errors.D7a_2}
          qErrorsAssessor={errorsAssessor.D7a_2}
          qErrorsCandidate={errorsCandidate.D7a_2}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D7a_2"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D7a_2_score,
              comment: errorsAssessor.D7a_2_comment,
            }}
          />
        ) : null}
        <QuestionMcqYesNo
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D7a_3"}
          questionText={
            "(If answered 'yes' to the previous question) Have you already started putting that plan into action?"
          }
          register={registerD}
          qErrors={errors.D7a_3}
          qErrorsAssessor={errorsAssessor.D7a_3}
          qErrorsCandidate={errorsCandidate.D7a_3}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D7a_3"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D7a_3_score,
              comment: errorsAssessor.D7a_3_comment,
            }}
          />
        ) : null}
        <QuestionTextBox
          question={"D7b"}
          questionText={
            "Please say if you are currently learning and developing yourself and if so how?"
          }
          register={registerD}
          qErrors={errors.D7b}
          qErrorsAssessor={errorsAssessor.D7b}
          qErrorsCandidate={errorsCandidate.D7b}
          watch={watch("D7b")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D7b"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D7b_score,
              comment: errorsAssessor.D7b_comment,
            }}
          />
        ) : null}
        <QuestionShortText
          question={"D8"}
          questionText={"What things do you feel gratitude for, and why?"}
          register={registerD}
          qErrors={errors.D8}
          qErrorsAssessor={errorsAssessor.D8}
          qErrorsCandidate={errorsCandidate.D8}
          watch={watch("D8")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D8"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D8_score,
              comment: errorsAssessor.D8_comment,
            }}
            scoreGuide={
              "Has a strong sense of gratitude supported by clear examples. Health, work, opportunity, family, friends, etc."
            }
          />
        ) : null}
        <QuestionShortText
          question={"D9"}
          questionText={"What does it mean to you to have 'courage' at work?"}
          register={registerD}
          qErrors={errors.D9}
          qErrorsAssessor={errorsAssessor.D9}
          qErrorsCandidate={errorsCandidate.D9}
          watch={watch("D9")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D9"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D9_score,
              comment: errorsAssessor.D9_comment,
            }}
            scoreGuide={
              "Speaking your mind or standing up to your boss and others. Taking risks personally and for the benefit of the organization and its task. Maintaining integrity and being non-collusive."
            }
          />
        ) : null}
        <QuestionShortText
          question={"D10"}
          questionText={[
            "a)Imagine this scenario: You can’t get all the work done that is expected of you. Now imagine the reason for this and tell me what comes to mind.",
            "b) What would you do in such a situation?",
          ]}
          register={registerD}
          qErrors={errors.D10}
          qErrorsAssessor={errorsAssessor.D10}
          qErrorsCandidate={errorsCandidate.D10}
          watch={watch("D10")}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D10"}
            watch={watch}
            scoreType={"Assessor scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D10_score,
              comment: errorsAssessor.D10_comment,
            }}
            scoreGuide={
              "Attributes the problem more to matters under one's control and can see how to tackle the problem. The problem is described as temporary rather than permanent, specific rather than pervasive."
            }
          />
        ) : null}
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        {formDataBeingSent ? (
          <button type="button" className="cta cta--accent--clicked">
            {formButtonText}
          </button>
        ) : null}
        {environment === "assessor" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleValidateSectionAssessor}
          >
            {formButtonText}
          </button>
        ) : null}
        {environment === "candidate" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleValidateSectionCandidate}
          >
            {formButtonText}
          </button>
        ) : null}
      </form>
    </ScrollToTop>
  );
}

export default withContext(SectionD);
