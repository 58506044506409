import React from 'react';
import './FormSectionSelector.css'; // Import the CSS file for styling

const FormSectionSelector = ({ onNavigateToFormSection }) => {
  const sections = [
    ['Instructions', null],
    ['Section 1', 'instructions'],
    ['Section A', 'section1'],
    ['Section B', 'sectionA'],
    ['Section C', 'sectionB'],
    ['Section D', 'sectionC'],
    ['Section E', 'sectionD'],
    ['Section F', 'sectionE'],
    ['Survey', 'sectionF'],
  ];

  return (
    <div className="form-section-selector">
      {sections.map((section) => (
        <button
          key={section}
          className="form-section-button"
          onClick={() => onNavigateToFormSection(section[1])}
        >
          {section[0]}
        </button>
      ))}
    </div>
  );
};

export default FormSectionSelector;