// Filename - App.js

import React from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Cell,
  Tooltip,
  Legend,
} from "recharts";
// const {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} = Recharts;

const BarChartReport = ({ barchartScores }) => {
  /*   const data = [
    { name: "Growth Mindset", score: 1 },
    { name: "Perseverance", score: 2 },
    { name: "Ownership", score: 3 },
    { name: "Purpose", score: 4 },
  ]; */
  // Dynamic Data set
  const data = [
    { name: "Growth Mindset", score: barchartScores.growthMindset },
    { name: "Perseverance", score: barchartScores.perseverance },
    { name: "Ownership", score: barchartScores.ownership },
    { name: "Purpose", score: barchartScores.purpose },
  ];

  const colorsOriginal = ["#92D051", "#548335", "#01B0F1", "#1F4E78"];
  const colors = ["#506CA8", "#5E8DAF", "#FFA702", "#1F1D5B"];

  return (
    <BarChart
      width={600}
      height={260}
      data={data}
      // maxBarSize={60}
      barCategoryGap={3}
      layout={"vertical"}
      margin={{ left: 25, right: 15, top: 15 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        type={"number"}
        orientation={"bottom"}
        domain={[0, 5]}
        ticks={[0, 1, 2, 3, 4, 5]}
      />
      <YAxis type={"category"} orientation={"left"} dataKey={"name"} />
      {/* <Bar dataKey={"score"} fill={"#8884d8"} radius={3} /> */}
      <Bar dataKey={"score"} /* fill={"#8884d8"} */ radius={3}>
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colorsOriginal[index % 20]} />
        ))}
      </Bar>
    </BarChart>
  );
};

export default BarChartReport;
