import { ServerError, ClientError } from "errors";
import {
  validateText,
  validateCallback,
  validateToken,
} from "../../validators";

const API_URL = process.env.REACT_APP_API_URL;

function createForm(
  token,
  fullName,
  organisation,
  group,
  formType,
  number,
  callback
) {
  validateToken(token);
  fullName && validateText(fullName, "full name");
  organisation && validateText(organisation, "organisation");
  group && validateText(group, "group");
  validateText(formType, "formType");
  validateCallback(callback);
  // TODO: validate number

  const xhr = new XMLHttpRequest();

  xhr.onload = function () {
    const status = xhr.status;
    if (status >= 500) {
      const message = JSON.parse(xhr.response).error;
      callback(new ServerError(`error ${status}: ${message}`));
    } else if (status >= 400) {
      const message = JSON.parse(xhr.response).error;
      callback(new ClientError(`error ${status}: ${message}`));
    } else if (status === 201) callback(null);
  };

  xhr.open("POST", `${API_URL}/forms`);

  xhr.setRequestHeader("Content-type", "application/json");
  xhr.setRequestHeader("Authorization", `Bearer ${token}`);

  xhr.send(
    `{ "fullName": "${fullName}", "organisation": "${organisation}", "group": "${group}", "formType": "${formType}", "number": "${number}"}`
  );
}

export default createForm;
