// ========== Imports ========== //

import "./CreateNews.css";

import { useState, useEffect } from "react";
import { createNews } from "../../../logic";
import Loggito from "../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import kebabFileName from "../Helpers/kebabFileName";
import withContext from "../../../utils/withContext";

// ========== Component ========== //

function CreateNews({ context: { refreshTokenCount } }) {
  // ========== Hook consts ========== //

  const [title, setTitle] = useState("");
  const [route, setRoute] = useState("");
  const [state, setState] = useState(0);

  // ========== other consts ========== //

  const logger = new Loggito("CreateNews");

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== Function to add/show CreateNews input fields ========== //

  const changeState = () => {
    if (state === 0) {
      setState(1);
    } else if (state === 1) {
      setState(0);
    }
  };

  const handleChange = (title) => {
    setTitle(title);
    setRoute(kebabFileName(title));
  };

  // ========== Functions ========== //

  const handleCreateEvent = () => {
    try {
      // refreshTokenHandler();
      createNews(sessionStorage.token, title, route, function (error) {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            logger.warn(error.message);
            // TODO: check this refresh_token implementation
            if (
              error.message ===
              "error 401: session timed out, please log in again"
            ) {
              toast.info("session timed out, please log in again");
              delete sessionStorage.token;
              refreshTokenCount("stop");
              window.location.reload(false);
            } else toast.warn(error.message);
          }
          return;
        }
        // TODO: navigate to editNews page
        toast.success("news created successfully");
        window.location.reload(false);
      });
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  const introduceAndClose = () => {
    handleCreateEvent(title);
    changeState();
  };

  // ========== jsx ========== //

  return (
    <div className="create-news">
      {state === 0 ? (
        <button className="cta cta--accent" onClick={changeState}>
          Create news
        </button>
      ) : null}
      {state === 1 ? (
        <div className="p--m p--bold">
          <label htmlFor="title">Introduce a title:</label>

          <input
            name="title"
            type="text"
            value={title}
            onChange={(e) => handleChange(e.target.value)}
            className=""
            placeholder="This can be modified later..."
          />
          <button className="cta cta--primary" onClick={introduceAndClose}>
            Create
          </button>
          <button className="cta cta--outline" onClick={changeState}>
            Cancel
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default withContext(CreateNews);
