// ========== imports ========== //

import React, { useEffect } from "react";

import { useParams, useLocation } from "react-router-dom";

import { toast } from "react-toastify";

import { updateCastFormResponse } from "../../../../logic";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { schemaFormPrivacyPolicy } from "../../../../validators";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";
import ScrollToTop from "../../../../utils/ScrollToTop";

import { IoWarningOutline } from "react-icons/io5";

// ========== Page ========== //

function InstructionsCandidate({ onApproveSection, environment }) {
  // ========== Hook consts ========== //
  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  // ========== other consts ========== //

  const logger = new Loggito("Form section 1");

  // ========== useEffects ========== //

  const params = useParams();
  let route = params.route;

  const location = useLocation();

  const {
    register: registerPrivacyPolicy,
    handleSubmit: handleSubmitPrivacyPolicy,
    reset,
    formState: { errors },
    getValues: getValuesA,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormPrivacyPolicy),
    reValidateMode: "onChange",
  });

  const saveFormData = async (approved) => {
    const values = {
      detailsFormSectionApproved: "instructions",
      detailsStatus: "In progress",
    };

    let endpoint;

    if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
      values.detailsStatus = "In progress";
    } else if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    ) {
      endpoint = "candidateassessor";
      // TODO: check if this is necessary here, at the moment i don't think it is
      // values.detailsStatus = "In progress";
    } else endpoint = "candidate";

    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        values,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                if (sessionStorage.token) delete sessionStorage.token;
                if (sessionStorage.candidate_token)
                  delete sessionStorage.candidate_token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          process.env.REACT_APP_LOGGITO_LEVEL === "0" &&
            toast.success("Confirmed");
          onApproveSection("instructions");
          return;
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  // ========== jsx ========== //

  return (
    <ScrollToTop>
      <form
        // THIS WORKS BUT THE FORM IS SUBMITTED ON PAGE LOAD
        // onSubmit={handleSubmitA((data) => saveFormData())}
        // THIS STOPS THE SAVEFORMDATA() FUNCTION BEING CALLED
        onSubmit={(event) => {
          event.preventDefault();
          // TODO: this is a better way to write the handleSubmit while calling another function on completion - replace in other forms.
          handleSubmitPrivacyPolicy(saveFormData)();
        }}
        className="cast-form"
        noValidate={true}
      >
        <div className="cast-form__title-block">
          <h2 className="h2">What to expect in the assessment.</h2>
          <p className="p--m">
            &nbsp;
            <br />
            Before starting you need to complete Section 1 with your details.
            This does not affect your score - the details are for administrative
            purposes only. When that is completed you will be able to begin the
            assessment.
            <br />
            <br />
            The assessment consists of six sections, A to F. Each section must
            be completed before moving onto the next. Once answers have been
            submitted for each section you will not be able to return to alter
            your answers. Once you have revised your answers for each section
            click the 'Submit' button to move to the next section.
            <br />
            <br />
            After completing section F the assessment administrator will be
            automatically notified that the assessment has been submitted.
            <br />
            <br />
            There is no time limit for the assessment, although a record is kept
            of time taken from starting the assessment to completing section F.
            The time taken does not affect your score. Please take time to read
            the questions carefully and give honest responses, focusing on your
            instinctual answer rather than focusing on exceptions.
            <br />
            <br />
            Answers are saved progressively, and if you leave the assessment at
            any time if if you lose connection, you will be able to continue
            from where you stopped by logging in again.
            <br />
            &nbsp;
          </p>
        </div>
        <div className="cast-form__question-container">
          <div className="cast-form__question cast-form__question__checkbox-list">
            <div className="cast-form__question__checkbox-list__question">
              <input
                className="cast-form__checkbox-input"
                type="checkbox"
                placeholder=""
                name="privacyPolicyConsent"
                {...registerPrivacyPolicy("privacyPolicyConsent", {})}
              />
              <label
                htmlFor="privacyPolicyConsent"
                className="contact-form__checkbox-label p--m"
                type="checkbox"
              >
                I confirm that I have read and agreed to the{" "}
                <a href="https://www.castassessment.com/privacy-policy/">
                  Privacy Policy
                </a>
                .
              </label>
            </div>
            <div className="cast-form__error-message">
              {errors.privacyPolicyConsent && (
                <div className="cast-form__error-message-container">
                  <IoWarningOutline className="icon" />
                  &nbsp;&nbsp;&nbsp;
                  <p className="input__error-message">
                    {errors.privacyPolicyConsent?.message}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <button type="submit" className="cta cta--accent">
          Confirm
        </button>
      </form>
    </ScrollToTop>
  );
}

export default withContext(InstructionsCandidate);
