import React from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Cell,
} from "recharts";

const Scattergraph4Quadrants = React.forwardRef((props, ref) => {
  const data = [
    { x: 10, y: 9, z: 50 },
    { x: 5, y: 8, z: 0 },
  ];

  const dataPersonal = [
    {
      x: props.scattergraphScores.demandingPersonalTotal - 12,
      y: props.scattergraphScores.supportivePersonalTotal - 12,
      z: 0,
    },
  ];
  const dataProfessional = [
    {
      x: props.scattergraphScores.demandingProfessionalTotal - 12,
      y: props.scattergraphScores.supportiveProfessionalTotal - 12,
      z: 0,
    },
  ];

  const SquareShape = (props) => {
    const { cx, cy, size, fill } = props;
    const halfSize = size / 2;
    return (
      <rect
        x={cx - halfSize}
        y={cy - halfSize}
        width={size}
        height={size}
        fill={fill}
        transform={`rotate(45, ${cx}, ${cy})`}
      />
    );
  };

  const COLORS = ["#4EADEA", "#9FCE63"];
  // Personal = blue diamond, Professional = green circle
  const colorPersonal = "#4EADEA";
  const colorProfessional = "#9FCE63";
  return (
    <ScatterChart
      ref={ref}
      width={400}
      height={360}
      margin={{
        top: 30,
        right: 50,
        bottom: 30,
        left: 50,
      }}
      style={{ backgroundColor: "white" }}
    >
      <CartesianGrid />
      {/* The ZAxis specifies the minimun and maximum size of the cell dots */}
      <ZAxis range={[160, 161]} />
      <XAxis
        type="number"
        dataKey="x"
        name="demanding"
        ticks={[0]}
        domain={[-11, 11]}
        hide
        reversed
        // orientation={"left"}
      />
      <YAxis
        type="number"
        dataKey="y"
        name="supportive"
        ticks={[""]}
        hide
        domain={[-11, 11]}
      />
      <Tooltip cursor={{ strokeDasharray: "3 3" }} />
      <Scatter
        name="Supportive/Demanding"
        data={dataPersonal}
        fill="#8884d8"
        shape={<SquareShape size={12} />}
      >
        <Cell
          key={`personal`}
          fill={colorPersonal}
          // radius={0}
        />
      </Scatter>
      <Scatter
        name="Supportive/Demanding"
        data={dataProfessional}
        fill="#8884d8"
      >
        <Cell
          key={`personal`}
          fill={colorProfessional}
          // radius={0}
        />
      </Scatter>
      <Tooltip cursor={{ strokeDasharray: "3 3" }} />
      <ReferenceLine y={0} stroke="#000000" />
      <ReferenceLine x={0} stroke="#000000" />
      <ReferenceLine
        segment={[
          {
            x: -11,
            y: -0.5,
          },
          {
            x: -11,
            y: 0,
          },
        ]}
        label={{
          value: "Undemanding",
          position: "bottom",
        }}
      />
      <ReferenceLine
        segment={[
          {
            x: 11,
            y: -0.5,
          },
          {
            x: 11,
            y: 0,
          },
        ]}
        label={{
          value: "Demanding",
          position: "bottom",
        }}
      />
      <ReferenceLine
        segment={[
          {
            x: 0,
            y: 10.5,
          },
          {
            x: 0,
            y: 10.5,
          },
        ]}
        label={{
          value: "Supportive",
          position: "bottom",
        }}
      />
      <ReferenceLine
        segment={[
          {
            x: 0,
            y: -9.5,
          },
          {
            x: 0,
            y: -9.5,
          },
        ]}
        label={{
          value: "Unsupportive",
          position: "bottom",
        }}
      />
      <ReferenceLine
        segment={[
          {
            x: 5,
            y: 5,
          },
          {
            x: 5,
            y: 5,
          },
        ]}
        label={{
          value: "S-D",
          position: "center",
        }}
      />
      <ReferenceLine
        segment={[
          {
            x: -5,
            y: 5,
          },
          {
            x: -5,
            y: 5,
          },
        ]}
        label={{
          value: "S-Ud",
          position: "center",
        }}
      />
      <ReferenceLine
        segment={[
          {
            x: 5,
            y: -5,
          },
          {
            x: 5,
            y: -5,
          },
        ]}
        label={{
          value: "Us-D",
          position: "center",
        }}
      />
      <ReferenceLine
        segment={[
          {
            x: -5,
            y: -5,
          },
          {
            x: -5,
            y: -5,
          },
        ]}
        label={{
          value: "Us-Ud",
          position: "center",
        }}
      />
      <ReferenceLine
        strokeDasharray="4 4"
        stroke={colorPersonal}
        segment={[
          {
            x: props.scattergraphScores.demandingPersonalTotal - 12,
            y: props.scattergraphScores.supportivePersonalTotal - 12,
          },
          {
            x: 11,
            y: 11,
          },
        ]}
        label={{
          value: "",
          position: "center",
        }}
      />
      <ReferenceLine
        strokeDasharray="4 4"
        stroke={colorProfessional}
        segment={[
          {
            x: props.scattergraphScores.demandingProfessionalTotal - 12,
            y: props.scattergraphScores.supportiveProfessionalTotal - 12,
          },
          {
            x: 11,
            y: 11,
          },
        ]}
        label={{
          value: "",
          position: "center",
        }}
      />
      <ReferenceLine
        segment={[
          {
            x: 11,
            y: 11,
          },
          {
            x: 11,
            y: 11,
          },
        ]}
        label={{
          value: "X",
          position: "center",
        }}
      />
    </ScatterChart>
  );
});

export default Scattergraph4Quadrants;
