// ========== Imports ========== //

import "./CreateForm.css";

import { useState, useEffect } from "react";
import { createForm, getOrganisations } from "../../../../logic";
import Loggito from "../../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import withContext from "../../../../utils/withContext";

// ========== Component ========== //

function CreateForm({ context: { refreshTokenCount }, formType }) {
  // ========== Hook consts ========== //

  const [organisationsData, setOrganisationsData] = useState("");
  const [selectedOrganisationData, setSelectedOrganisationData] = useState("");
  const [fullName, setFullName] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [group, setGroup] = useState("");
  const [number, setNumber] = useState("1");
  const [state, setState] = useState(0);

  // ========== other consts ========== //

  const logger = new Loggito("CreateForm");

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  useEffect(() => {
    organisationsData &&
      setSelectedOrganisationData(
        organisationsData.find((org) => org.name === organisation)
      );
  }, [organisationsData, organisation]);

  // ========== Function to add/show CreateForm input fields ========== //

  const changeState = () => {
    if (state === 0) {
      setState(1);
    } else if (state === 1) {
      setState(0);
    }
  };

  const handleChange = (input, value) => {
    if (input === "fullName") setFullName(value);
    else if (input === "organisation") {
      setOrganisation(value);
      setGroup("");
    } else if (input === "group") setGroup(value);
    else if (input === "number" && value === "") setNumber("1");
    else if (input === "number" && value !== "") setNumber(value);
    return;
  };

  const groupOptions = selectedOrganisationData
    ? selectedOrganisationData.groups
    : null;
  // ========== Functions ========== //

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getOrganisations(sessionStorage.token, (error, organisationsArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            logger.warn(error.message);
            if (
              error.message ===
              "error 401: session timed out, please log in again"
            ) {
              toast.info("session timed out, please log in again");
              delete sessionStorage.token;
              window.location.reload(false);
            } else toast.warn(error.message);
          }
          return;
        }
        setOrganisationsData(organisationsArray);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);

  const handleCreateEvent = () => {
    try {
      // refreshTokenHandler();
      createForm(
        sessionStorage.token,
        fullName,
        organisation,
        group,
        formType,
        number,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              // TODO: check this refresh_token implementation
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                delete sessionStorage.token;
                refreshTokenCount("stop");
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          // TODO: add returned form to array of forms rather than reloading the page
          toast.success("form created successfully");
          window.location.reload(false);
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  const introduceAndClose = () => {
    handleCreateEvent(fullName, organisation, group);
    changeState();
  };

  // ========== jsx ========== //

  return (
    <div className="create-form">
      {state === 0 ? (
        <button className="cta cta--secondary" onClick={changeState}>
          Create form
        </button>
      ) : null}
      {state === 1 ? (
        <div className="create-form-overlay">
          <div className="p--m p--bold create-form-overlay__content">
            <h3 className="h3">Create Candidate Form</h3>
            <div className="input-container">
              <label htmlFor="fullName">Full name of candidate:</label>

              <input
                name="fullName"
                type="text"
                // value={fullName}
                onChange={(e) => handleChange("fullName", e.target.value)}
                className=""
                placeholder="This can be modified later..."
              />
            </div>
            <div className="input-container">
              <label htmlFor="organisation">Organisation:</label>

              <select
                name="organisation"
                type="text"
                // value={organisation}
                onChange={(e) => handleChange("organisation", e.target.value)}
                className="input--select"
              >
                <option value="" disabled selected>
                  Select an organisation...
                </option>
                {organisationsData &&
                  organisationsData.map((org, index) => (
                    <option key={index} value={org.name}>
                      {org.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="input-container">
              <label htmlFor="group">Group:</label>

              <select
                name="group"
                value={group}
                onChange={(e) => handleChange("group", e.target.value)}
                className="input--select"
              >
                <option value="" disabled selected>
                  Select a group...
                </option>
                {groupOptions
                  ? groupOptions.map((group, index) => (
                      <option key={index} value={group.groupName}>
                        {group.groupName + " - " + group.date}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="input-container input-container--number">
              <label htmlFor="group">Number of forms to create (1-50):</label>
              <input
                name="number"
                type="number"
                min="1"
                max="50"
                onChange={(e) => handleChange("number", e.target.value)}
                className=""
                placeholder="1"
                defaultValue={1}
              />
            </div>
            <div className="buttons-container">
              <button className="cta cta--primary" onClick={introduceAndClose}>
                Create
              </button>
              <button className="cta cta--outline" onClick={changeState}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default withContext(CreateForm);
