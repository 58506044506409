import Joi from "joi";

const schemaFormPrivacyPolicy = Joi.object({
  privacyPolicyConsent: Joi.boolean()
    .required()
    .allow(true)
    .valid(true)
    .messages({
      // TODO: add this any.only message to the other validators - used when value does not match allowed values, in this case 'true'
      "any.only": "You must agree to the privacy policy to proceed.",
      "string.base": "You must agree to the privacy policy to proceed",
      "string.empty": "You must agree to the privacy policy to proceed",
    }),
});

export default schemaFormPrivacyPolicy;
