// ========== imports ========== //

import { IoWarningOutline } from "react-icons/io5";

// ========== Page ========== //
function QuestionNumber({
  question,
  questionText,
  register,
  qErrors,
  qErrorsAssessor,
  qErrorsCandidate,
  section,
  min,
  max,
  onSubmit,
  subquestion,
  mergedquestion,
}) {
  // ========== Hook consts ========== //

  return (
    <div
      className={`cast-form__question-container ${
        subquestion ? "cast-form__question-container--subquestion" : null
      } ${
        mergedquestion ? "cast-form__question-container--mergedquestion" : null
      }`}
      id={`${question}_link`}
    >
      <div className="cast-form__question">
        <label className="p--m cast-form__question__qtext" htmlFor={question}>
          {typeof questionText === "string" &&
          section !== "1" &&
          section !== "B" ? (
            <>
              <b>{question}</b>: {questionText}
            </>
          ) : null}
          {typeof questionText === "string" && section === "1" ? (
            <>
              <b>{questionText}</b>
            </>
          ) : null}
          {typeof questionText === "string" && section === "B" ? (
            <>{questionText}</>
          ) : null}
          {typeof questionText === "object"
            ? questionText.map((text, i) => {
                if (i === 0) {
                  return (
                    <li
                      className="cast-form__question__qtext__listitem"
                      key={
                        Date.now().toString() +
                        Math.floor(Math.random() * 1000000).toString()
                      }
                    >
                      <b>{question}</b>: {text}
                    </li>
                  );
                } else
                  return (
                    <li
                      className="cast-form__question__qtext__listitem"
                      key={
                        Date.now().toString() +
                        Math.floor(Math.random() * 1000000).toString()
                      }
                    >
                      {text}
                    </li>
                  );
              })
            : null}
        </label>
        <input
          className="p--m cast-form__question--number"
          type="number"
          min={min}
          max={max}
          placeholder=""
          name={question}
          id={question}
          {...register(question, {
            onChange: (e) => {
              onSubmit();
            },
          })}
        />
        <div className="cast-form__error-message">
          {qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrors?.message}</p>
            </div>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsCandidate && !qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsCandidate}</p>
            </div>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsAssessor && !qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsAssessor}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuestionNumber;
