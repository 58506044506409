// ========== imports ========== //

import React from "react";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { toast } from "react-toastify";

import { ServerError } from "errors";

// ========== Page ========== //

function AssessorNav({ errors, errorsAssessor, currentValues }) {
  // ========== Hook consts ========== //

  // ========== other consts ========== //

  const logger = new Loggito("Form Assessor Nav");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  const questionsArray = [
    ["section1", "detailsFullName", "1a", "detailsFullName"],
    ["section1", "detailsCandidateEmail", "1b", "detailsCandidateEmail"],
    ["section1", "detailsOrganisation", "1c", "detailsOrganisation"],
    ["section1", "detailsRole", "1d", "detailsRole"],
    ["section1", "detailsGender", "1e", "detailsGender"],
    ["section1", "detailsAssessor", "1f", "detailsAssessor"],
    ["section1", "detailsDateAssessed", "1g", "detailsDateAssessed"],

    ["sectionA", "A1a", "A1a"],
    ["sectionA", "A1b", "A1b"],
    ["sectionA", "A2", "A2_score"],
    ["sectionA", "A3", "A3_score"],
    ["sectionA", "A4", "A4_score"],
    ["sectionA", "A5", "A5_score"],
    ["sectionA", "A6", "A6_score"],

    ["sectionB", "B1", "B1_score"],
    ["sectionB", "B2", "B2_score"],
    ["sectionB", "B3", "B3_score"],
    ["sectionB", "B4a", "B4a"],
    ["sectionB", "B4b", "B4b"],
    ["sectionB", "B5", "B5_score"],
    ["sectionB", "B6", "B6_score"],
    ["sectionB", "B7", "B7_score"],
    ["sectionB", "B8", "B8_score"],
    ["sectionB", "B9", "B9_score"],
    ["sectionB", "B10", "B10_score"],
    ["sectionB", "B11", "B11_score"],
    ["sectionB", "B12", "B12_score"],

    ["sectionC", "C1", "C1"],
    ["sectionC", "C2", "C2"],
    ["sectionC", "C3", "C3"],
    ["sectionC", "C4", "C4"],
    ["sectionC", "C5", "C5"],
    ["sectionC", "C6", "C6"],
    ["sectionC", "C7", "C7"],
    ["sectionC", "C8", "C8"],
    ["sectionC", "C9", "C9"],
    ["sectionC", "C10", "C10"],
    ["sectionC", "C11", "C11"],
    ["sectionC", "C12", "C12"],

    ["sectionD", "D1", "D1"],
    ["sectionD", "D2", "D2"],
    ["sectionD", "D3", "D3"],
    ["sectionD", "D4", "D4"],
    ["sectionD", "D5", "D5"],
    ["sectionD", "D6", "D6_score"],
    ["sectionD", "D7a_1", "D7a_1"],
    ["sectionD", "D7a_2", "D7a_2"],
    ["sectionD", "D7a_3", "D7a_3"],
    ["sectionD", "D7b", "D7b_score"],
    ["sectionD", "D8", "D8_score"],
    ["sectionD", "D9", "D9_score"],
    ["sectionD", "D10", "D10_score"],

    ["sectionE", "E1", "E1"],
    ["sectionE", "E2", "E2"],
    ["sectionE", "E3", "E3"],
    ["sectionE", "E4", "E4"],
    ["sectionE", "E5", "E5"],
    ["sectionE", "E6", "E6"],
    ["sectionE", "E7", "E7"],
    ["sectionE", "E8", "E8"],
    ["sectionE", "E9", "E9"],
    ["sectionE", "E10", "E10"],
    ["sectionE", "E11", "E11"],
    ["sectionE", "E12", "E12"],
    ["sectionE", "E13", "E13"],
    ["sectionE", "E14", "E14"],
    ["sectionE", "E15", "E15"],
    ["sectionE", "E16", "E16"],

    ["sectionF", "F1", "F1"],
    ["sectionF", "F2", "F2"],
    ["sectionF", "F3", "F3"],
    ["sectionF", "F4", "F4"],
    ["sectionF", "F5", "F5"],
    ["sectionF", "F6", "F6"],
    ["sectionF", "F7", "F7"],
    ["sectionF", "F8", "F8"],
    ["sectionF", "F9", "F9"],
    ["sectionF", "F10", "F10"],
    ["sectionF", "F11", "F11"],
    ["sectionF", "F12", "F12"],
    ["sectionF", "F13", "F13"],
    ["sectionF", "F14", "F14"],
    ["sectionF", "F15", "F15"],
    ["sectionF", "F16", "F16"],
  ];
  return (
    <div className="assessor-nav-panel p--sm">
      {questionsArray.map((question, i) => {
        return (
          <a href={`#${question[1]}_link`} key={question[1]}>
            <div
              className={`assessor-nav-button ${
                question.length === 4 &&
                errorsAssessor[question[0]][question[3]]
                  ? "assessor-nav-button__error"
                  : ""
              } ${
                question.length === 3 &&
                errorsAssessor[question[0]][question[2]]
                  ? "assessor-nav-button__error"
                  : ""
              } ${
                question[1] === "A1a" ||
                question[1] === "B1" ||
                question[1] === "C1" ||
                question[1] === "D1" ||
                question[1] === "E1" ||
                question[1] === "F1"
                  ? "assessor-nav-button__new-section"
                  : ""
              } }`}
            >
              {question.length === 4 ? question[2] : question[1]}
            </div>
          </a>
        );
      })}
    </div>
  );
}

export default withContext(AssessorNav);
