// ========== imports ========== //
import "./CASTForm.css";

import React, { useState, useEffect } from "react";
import {
  InstructionsCandidate,
  Section1,
  SectionAAutomated,
  SectionBAutomated,
  SectionC,
  SectionDAutomated,
  SectionE,
  SectionF,
  SubmittedScreen,
} from "../FormSections";

import { Spinner } from "../../../Common";

import { useParams, useNavigate, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { getFormResponse } from "../../../../logic";

import { ServerError } from "errors";

import { toast } from "react-toastify";

// ========== Page ========== //

function AutomatedForm() {
  // function AutomatedForm({ accessCode }) {
  // ========== Hook consts ========== //
  const [formSection, setFormSection] = useState(null);
  const [responseLoading, setResponseLoading] = useState(true);
  const [existingValues, setExistingValues] = useState({});

  // const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();
  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    getFormResponseData();
  }, []);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const environment = params.environment;
  let route = params.route;
  const pathname = location.pathname;

  console.log(`environment: ${environment}`);

  const handleApproveSection = (formSectionApproved) => {
    setFormSection(formSectionApproved);
    existingValues.detailsFormSectionApproved = formSectionApproved;
  };

  let endpoint;

  if (location.pathname.slice(1).slice(0, 5) === "admin") {
    endpoint = "candidateassessor";
  } else endpoint = "candidate";

  const getFormResponseData = () => {
    console.log(route);
    try {
      getFormResponse(
        sessionStorage,
        route,
        endpoint,
        (error, formDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              if (location.pathname.slice(1).slice(0, 5) === "admin") {
                navigate("/admin/castresponsestableautomated");
              } else navigate("/candidate/access");
              toast.error(error.message);
              logger.error(error.message);
              // If form cannot be received then an empty screen will be shown
              setResponseLoading(false);
            } else {
              if (location.pathname.slice(1).slice(0, 5) === "admin") {
                navigate("/admin/castresponsestableautomated");
              } else navigate("/candidate/access");
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                delete sessionStorage.token;
                window.location.reload(false);
              } else toast.warn(error.message);
              setResponseLoading(false);
            }
            return;
          }
          if (formDataRetrieved.detailsTypeOfResponse === "Interviewer") {
            if (location.pathname.slice(1).slice(0, 5) === "admin") {
              navigate("/admin/castresponsestableautomated");
            } else navigate("/candidate/access");
            return;
          }

          setExistingValues(formDataRetrieved);
          setFormSection(formDataRetrieved.detailsFormSectionApproved);
          setResponseLoading(false);
        }
      );
    } catch (error) {
      if (location.pathname.slice(1).slice(0, 5) === "admin") {
        navigate("/admin/castresponsestableautomated");
      } else navigate("/candidate/access");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  // ========== other consts ========== //

  const logger = new Loggito("CAST Form Dev");

  const formButtonText = environment === "assessor" ? "Review" : "Submit";

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <div className="candidate-view-page cast-form-background">
      {responseLoading && <Spinner />}

      {!responseLoading && (
        <div className="section cast-form-container">
          {formSection === null ? (
            <InstructionsCandidate onApproveSection={handleApproveSection} />
          ) : null}
          {formSection === "instructions" ? (
            <Section1
              environment={environment}
              onApproveSection={handleApproveSection}
              existingValues={existingValues}
              setExistingValues={setExistingValues}
            />
          ) : null}
          {formSection === "section1" ? (
            <SectionAAutomated
              environment={environment}
              onApproveSection={handleApproveSection}
              existingValues={existingValues}
              formButtonText={formButtonText}
              setExistingValues={setExistingValues}
            />
          ) : null}
          {formSection === "sectionA" ? (
            <SectionBAutomated
              environment={environment}
              onApproveSection={handleApproveSection}
              existingValues={existingValues}
              formButtonText={formButtonText}
              setExistingValues={setExistingValues}
            />
          ) : null}
          {formSection === "sectionB" ? (
            <SectionC
              environment={environment}
              onApproveSection={handleApproveSection}
              existingValues={existingValues}
              formButtonText={formButtonText}
              setExistingValues={setExistingValues}
            />
          ) : null}
          {formSection === "sectionC" ? (
            <SectionDAutomated
              environment={environment}
              onApproveSection={handleApproveSection}
              existingValues={existingValues}
              formButtonText={formButtonText}
              setExistingValues={setExistingValues}
            />
          ) : null}
          {formSection === "sectionD" ? (
            <SectionE
              environment={environment}
              onApproveSection={handleApproveSection}
              existingValues={existingValues}
              formButtonText={formButtonText}
              setExistingValues={setExistingValues}
            />
          ) : null}
          {formSection === "sectionE" ? (
            <SectionF
              environment={environment}
              onApproveSection={handleApproveSection}
              existingValues={existingValues}
              formButtonText={formButtonText}
              setExistingValues={setExistingValues}
            />
          ) : null}
          {formSection === "sectionF" || formSection === "survey" ? (
            <SubmittedScreen
              environment={environment}
              existingValues={existingValues}
              onApproveSection={handleApproveSection}
              setExistingValues={setExistingValues}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}

export default withContext(AutomatedForm);
