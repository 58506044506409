// ========== imports ========== //
import "./CASTForm.css";

import React, { useState, useEffect } from "react";
import {
  Section1,
  SectionA,
  SectionB,
  SectionC,
  SectionD,
  SectionE,
  SectionF,
  SubmittedScreen,
} from "../FormSections";

import { AssessorNav } from "../FormNavComponents";

import { useParams, useNavigate } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";

import { getFormResponse, updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { ServerError } from "errors";

// ========== Page ========== //

const SECRET_KEY = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;
const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

function AssessorForm() {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(false);

  // const [formSubmitted, setFormSubmitted] = useState(0);

  const [
    triggerHandleValidateSectionAssessor,
    setTriggerHandleValidateSectionAssessor,
  ] = useState(0);

  const [existingValues, setExistingValues] = useState({});

  // These values are used to proved current values an errors to the AssessorNav component
  const [currentValues, setCurrentValues] = useState({});
  const [errors, setErrors] = useState({});
  const [errorsAssessor, setErrorsAssessor] = useState({
    section1: {},
    sectionA: {},
    sectionB: {},
    sectionC: {},
    sectionD: {},
    sectionE: {},
    sectionF: {},
  });

  const handleUpdateErrors = (errors) => {
    const newErrorsObject = { ...errorsAssessor, errors };
    setErrors(newErrorsObject);
  };

  const handleUpdateErrorsAssessor = (errors, section) => {
    setErrorsAssessor((prevState) => ({
      ...prevState,
      [section]: {
        ...errors,
      },
    }));
  };

  const handleUpdateCurrentValues = (values) => {
    const newValuesObject = { ...currentValues, values };
    setCurrentValues(newValuesObject);
  };

  // const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    getFormResponseData();
  }, []);

  /*   useEffect(() => {
    saveFormData("approved");
  }, [formSubmitted]); */

  // const params = useParams();

  const params = useParams();
  const navigate = useNavigate();
  let route = params.route;
  // To try to focus() on the question
  /*   let questionFocus = params.question;

  if (questionFocus) {
    const questionComponent = document.getElementById(
      questionFocus.slice(1).slice(0, -5)
    );

    if (questionComponent) questionComponent.focus();
  } */

  const environment = params.environment;
  console.log(environment);

  const getFormResponseData = () => {
    console.log(route);
    try {
      getFormResponse(
        sessionStorage,
        route,
        "assessor",
        (error, formDataRetrieved) => {
          if (error) {
            if (error instanceof ServerError) {
              navigate("/admin/castresponsestableinterviewer");
              toast.error(error.message);
              logger.error(error.message);
            } else {
              navigate("/admin/castresponsestableinterviewer");
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                delete sessionStorage.token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          if (formDataRetrieved.detailsTypeOfResponse === "Candidate") {
            navigate("/admin/castresponsestableinterviewer");
            return;
          }
          setExistingValues(formDataRetrieved);
        }
      );
    } catch (error) {
      navigate("/admin/castresponsestableinterviewer");
      toast.warn(error.message);
      logger.warn(error.message);
    }
  };

  const handleSubmitInterviewerForm = (e) => {
    /*     e.preventDefault();
    debugger;
    // TODO: here this needs to call the handleValidateSectionAssessor function of each of the sections
    setTriggerHandleValidateSectionAssessor((prevState) => prevState + 1);
    if (
      Object.entries(errorsAssessor.section1).length === 0 &&
      Object.entries(errorsAssessor.sectionA).length === 0 &&
      Object.entries(errorsAssessor.sectionB).length === 0 &&
      Object.entries(errorsAssessor.sectionC).length === 0 &&
      Object.entries(errorsAssessor.sectionD).length === 0 &&
      Object.entries(errorsAssessor.sectionE).length === 0 &&
      Object.entries(errorsAssessor.sectionF).length === 0
    )
    */
    // setFormSubmitted((prevState) => prevState + 1);
    setTriggerHandleValidateSectionAssessor((prevState) => prevState + 1);
    // TODO: here this needs to check if there are any errors in the errorsAssessor object - after each of the separate submit functions have been called
  };

  // THIS IS USED SOLELY TO UPDATE THE STATUS TO ALLOW THE REPORT TO BE ACESSED ON FORM SUBMIT
  /*   const saveFormData = async (approved) => {
    const values = { detailsStatus: "Pending report" };

    const endpoint = "assessor";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        values,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              debugger;
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                if (sessionStorage.token) delete sessionStorage.token;
                if (sessionStorage.candidate_token)
                  delete sessionStorage.candidate_token;
                window.location.reload(false);
              } else {
                debugger;
                toast.warn(error.message);
              }
            }
            return;
          }
          process.env.REACT_APP_LOGGITO_LEVEL === "0" &&
            toast.success("Your answers have been saved");

          setExistingValues((prevValues) => ({
            ...prevValues,
            ...values,
          }));
        }
      );
    } catch (error) {
      debugger;
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  }; */

  // ========== other consts ========== //

  const logger = new Loggito("CAST Form Dev");

  const formButtonText = environment === "assessor" ? "Review" : "Submit";

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <div className="cast-form-background">
      <div className="section cast-form-container">
        <Section1
          environment={environment}
          existingValues={existingValues}
          onUpdateErrorsAssessor={handleUpdateErrorsAssessor}
          setExistingValues={setExistingValues}
          triggerHandleValidateSectionAssessor={
            triggerHandleValidateSectionAssessor
          }
        />

        <SectionA
          environment={environment}
          existingValues={existingValues}
          onUpdateErrorsAssessor={handleUpdateErrorsAssessor}
          formButtonText={formButtonText}
          setExistingValues={setExistingValues}
          triggerHandleValidateSectionAssessor={
            triggerHandleValidateSectionAssessor
          }
        />

        <SectionB
          environment={environment}
          existingValues={existingValues}
          onUpdateErrorsAssessor={handleUpdateErrorsAssessor}
          formButtonText={formButtonText}
          setExistingValues={setExistingValues}
          triggerHandleValidateSectionAssessor={
            triggerHandleValidateSectionAssessor
          }
        />
        <SectionC
          environment={environment}
          existingValues={existingValues}
          onUpdateErrorsAssessor={handleUpdateErrorsAssessor}
          formButtonText={formButtonText}
          setExistingValues={setExistingValues}
          triggerHandleValidateSectionAssessor={
            triggerHandleValidateSectionAssessor
          }
        />
        <SectionD
          environment={environment}
          existingValues={existingValues}
          onUpdateErrorsAssessor={handleUpdateErrorsAssessor}
          formButtonText={formButtonText}
          setExistingValues={setExistingValues}
          triggerHandleValidateSectionAssessor={
            triggerHandleValidateSectionAssessor
          }
        />
        <SectionE
          environment={environment}
          existingValues={existingValues}
          onUpdateErrorsAssessor={handleUpdateErrorsAssessor}
          formButtonText={formButtonText}
          setExistingValues={setExistingValues}
          triggerHandleValidateSectionAssessor={
            triggerHandleValidateSectionAssessor
          }
        />
        <SectionF
          environment={environment}
          existingValues={existingValues}
          onUpdateErrorsAssessor={handleUpdateErrorsAssessor}
          formButtonText={formButtonText}
          setExistingValues={setExistingValues}
          triggerHandleValidateSectionAssessor={
            triggerHandleValidateSectionAssessor
          }
        />
        {/* TODO: effectively this logic does not apply here, because the formDataBeingSent state is inside each component and not in this component */}
        {formDataBeingSent ? (
          <button type="button" className="cta cta--accent--clicked">
            Final review & submit
          </button>
        ) : null}
        {environment === "assessor" && !formDataBeingSent ? (
          /*
        &&
         !(
          formSubmitted &&
          Object.entries(errorsAssessor.section1).length === 0 &&
          Object.entries(errorsAssessor.sectionA).length === 0 &&
          Object.entries(errorsAssessor.sectionB).length === 0 &&
          Object.entries(errorsAssessor.sectionC).length === 0 &&
          Object.entries(errorsAssessor.sectionD).length === 0 &&
          Object.entries(errorsAssessor.sectionE).length === 0 &&
          Object.entries(errorsAssessor.sectionF).length === 0
        )  */
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleSubmitInterviewerForm}
          >
            Final review & submit
          </button>
        ) : null}
        {/*     {environment === "assessor" &&
        formSubmitted &&
        Object.entries(errorsAssessor.section1).length === 0 &&
        Object.entries(errorsAssessor.sectionA).length === 0 &&
        Object.entries(errorsAssessor.sectionB).length === 0 &&
        Object.entries(errorsAssessor.sectionC).length === 0 &&
        Object.entries(errorsAssessor.sectionD).length === 0 &&
        Object.entries(errorsAssessor.sectionE).length === 0 &&
        Object.entries(errorsAssessor.sectionF).length === 0 ? (
          <button type="button" className="cta cta--primary">
            Form submitted
          </button>
        ) : null} */}
        <SubmittedScreen
          environment={environment}
          existingValues={existingValues}
          setExistingValues={setExistingValues}
          triggerHandleValidateSectionAssessor={
            triggerHandleValidateSectionAssessor
          }
        />

        <AssessorNav
          // TODO: incorporate regular errors to the validation, and change style if current value exists
          currentValues={currentValues}
          errors={errors}
          errorsAssessor={errorsAssessor}
        />
      </div>
    </div>
  );
}

export default withContext(AssessorForm);
