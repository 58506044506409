// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";
import ScrollToTop from "../../../../utils/ScrollToTop";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSection1,
  validateSection1Assessor,
  validateSection1Candidate,
  validateSection1AutoSave,
} from "../../../../validators";

import {
  QuestionMcqListHorizontal,
  QuestionDateAssessed,
  QuestionSelect,
  QuestionShortText,
} from "./FormQuestions";

import ConfirmSubmission from "./ConfirmSubmission";

// ========== Page ========== //

function Section1({
  environment,
  onApproveSection,
  existingValues,
  setExistingValues,
  onUpdateErrorsAssessor,
  triggerHandleValidateSectionAssessor,
}) {
  // ========== Hook consts ========== //
  // const [formDataBeingSent, setFormDataBeingSent] = useState(false);
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});
  const [renderErrors, setRenderErrors] = useState(0);

  const [confirmSubmissionPopup, setConfirmSubmissionPopup] = useState(false);

  const [submitted, setSubmitted] = useState(null);

  const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    triggerHandleValidateSectionAssessor > 0 && handleValidateSectionAssessor();
  }, [triggerHandleValidateSectionAssessor]);

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    return () => {
      // This function will be called when the component is unmounted
      // saveFormData(null);
      handleValidateSectionAutoSave();
    };
  }, []);

  useEffect(() => {
    if (confirmSubmissionPopup === true) handleValidateSectionCandidate();
    // the submitted state is updated indicating that the candidate has submitted the form at least once meaning that error messages will be displayed and need to be handled
    if (confirmSubmissionPopup === true) setSubmitted(true);
  }, [confirmSubmissionPopup]);

  // These useEffects are to update the state in the AssessorNav component

  useEffect(() => {
    if (environment !== "candidate")
      onUpdateErrorsAssessor(errorsAssessor, "section1");
  }, [errorsAssessor]);

  const params = useParams();
  let route = params.route;

  const location = useLocation();

  const {
    register: registerSection1,
    handleSubmit: handleSubmitSection1,
    reset,
    formState: { errors },
    getValues: getValuesSection1,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSection1),
    reValidateMode: "onChange",
  });

  // This logic is to trigger the onChange event manually when the input value changes on autofill

  /*   const detailsFullName = watch("detailsFullName");
  const detailsCandidateEmail = watch("detailsCandidateEmail");

  useEffect(() => {
    if (detailsFullName) {
      handleChange(detailsFullName);
    }
    if (detailsCandidateEmail) {
      handleChange(detailsCandidateEmail);
    }
  }, [detailsFullName, detailsCandidateEmail]);

  const handleChange = (value) => {
    console.log("Input value changed:", value);
    handleValidateSectionAutoSave();
  }; */

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      const formIdsArray = [
        "detailsFullName",
        "detailsCandidateEmail",
        "detailsOrganisation",
        "detailsRole",
        "detailsGender",
        "detailsAssessor",
        "detailsDateAssessed",
      ];
      for (const [key, value] of Object.entries(existingValues)) {
        if (
          key.slice(0, 7) === "details" &&
          value &&
          formIdsArray.includes(key)
        ) {
          // setValue(key, value, {});
          if (key === "detailsDateAssessed") {
            setValue(key, value.slice(0, 10), {});
          } else setValue(key, value, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  // START SUBMIT AFTER TYPING - THIS SUBMITS THE USER'S RESPONSE AFTER THEY STOP TYPING

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 13 ||
      keyCode === 32 ||
      keyCode === 56 ||
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 96 && keyCode <= 111)
    ) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    // const delayFn = setTimeout(() => saveFormData(null), 3000);
    const delayFn = setTimeout(() => handleValidateSectionAutoSave(), 3000);

    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keyup", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  // START SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const handleUserKeyPressAfterSubmit = useCallback(
    (event) => {
      const { key, keyCode } = event;

      if (
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 13 ||
        keyCode === 32 ||
        keyCode === 56 ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 96 && keyCode <= 111)
      ) {
        if (submitted) {
          if (environment === "assessor") {
            const errors = validateSection1Assessor(getValuesSection1());
            setErrorsAssessor(errors ? errors : {});
          } else if (environment === "candidate") {
            const errors = validateSection1Candidate(getValuesSection1());
            setErrorsCandidate(errors ? errors : {});
          }
        }
      }
    },
    [submitted]
  );

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPressAfterSubmit);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener(
        "keyup",
        handleUserKeyPressAfterSubmit
      );
    };
  }, [handleUserKeyPressAfterSubmit]);

  // END SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const saveFormData = async (approved) => {
    const values = getValuesSection1();

    if (approved === "approved") {
      values.detailsFormSectionApproved = "section1";
      values.detailsStatus = "In progress";
    }

    console.log(values);

    let endpoint;

    if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    )
      endpoint = "candidateassessor";
    else if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
      values.detailsStatus = "In progress";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        values,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                if (sessionStorage.token) delete sessionStorage.token;
                if (sessionStorage.candidate_token)
                  delete sessionStorage.candidate_token;
                window.location.reload(false);
              } else {
                toast.warn(error.message);
              }
            }
            return;
          }
          process.env.REACT_APP_LOGGITO_LEVEL === "0" &&
            toast.success("Your answers to section 1 have been saved");

          setExistingValues((prevValues) => ({
            ...prevValues,
            ...values,
          }));
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    setSubmitted(true);
    const formValues = getValuesSection1();
    const errors = validateSection1Assessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      toast.success("Your answers to section 1 have been saved");
      // if (environment === "candidate") onApproveSection("section1");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesSection1();
    const errors = validateSection1Candidate(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      // TODO: this should be an asunc function so that saveForm can be awaited before moving onto the next steps - check if this is necessary because the async data function itseld is already async - does the alert trigger if the saveFormData returns an error?
      saveFormData("approved");
      toast.success("Your answers to section 1 have been saved");
      onApproveSection("section1");
      return;
    }
  };

  const handleValidateSectionAutoSave = () => {
    const formValues = getValuesSection1();
    const validationErrors = validateSection1AutoSave(formValues);
    if (validationErrors) {
      Object.keys(errors).forEach((key) => delete errors[key]);
      Object.keys(validationErrors).forEach(
        (key) => (validationErrors[key] = { message: validationErrors[key] })
      );
      Object.assign(errors, validationErrors);
      setRenderErrors(renderErrors + 1);
      return;
    } else {
      Object.keys(errors).forEach((key) => delete errors[key]);
      setRenderErrors(renderErrors + 1);
      saveFormData(null);
      return;
    }
  };

  const handleSubmitOnClick = () => {
    // TODO: see if this saveFormData needs to be validated as well
    // saveFormData();
    handleValidateSectionAutoSave();
    if (submitted) {
      if (environment === "assessor") {
        const errors = validateSection1Assessor(getValuesSection1());
        setErrorsAssessor(errors ? errors : {});
      } else if (environment === "candidate") {
        const errors = validateSection1Candidate(getValuesSection1());
        setErrorsCandidate(errors ? errors : {});
      }
    }
  };

  /*   const handleSubmitHandler = () => {
    // handleSubmitSection1((data) => handleSubmitOnClick());
    form.current.dispatchEvent(new Event("submit", { cancelable: true }));
  }; */

  const confirmSubmissionCandidate = (state) => {
    setConfirmSubmissionPopup(state);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section 1");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <ScrollToTop>
      <form
        ref={form}
        // onSubmit={handleSubmitSection1((data) => saveFormData())}
        onSubmit={(event) => {
          event.preventDefault();
          // handleSubmitSection1((data) => saveFormData());
          handleSubmitSection1((data) => handleSubmitOnClick());
        }}
        className="cast-form"
        noValidate={true}
        // autoComplete="off"
      >
        <ConfirmSubmission
          confirmSubmissionPopup={confirmSubmissionPopup}
          onConfirmSubmissionCandidate={confirmSubmissionCandidate}
        />

        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        <div className="cast-form__title-block">
          <h2 className="h2">Section 1</h2>
          <p className="p--m">
            Please complete the details below and save your progress once you
            have finished and are ready to move to the next section.
            {/*    <br />
          &nbsp; */}
          </p>
        </div>
        <QuestionShortText
          question={"detailsFullName"}
          questionText={"* Full name:"}
          register={registerSection1}
          qErrors={errors.detailsFullName}
          section={"1"}
          qErrorsAssessor={errorsAssessor.detailsFullName}
          qErrorsCandidate={errorsCandidate.detailsFullName}
          watch={watch("detailsFullName")}
        />
        <QuestionShortText
          question={"detailsCandidateEmail"}
          questionText={"* Email:"}
          register={registerSection1}
          qErrors={errors.detailsCandidateEmail}
          section={"1"}
          qErrorsAssessor={errorsAssessor.detailsCandidateEmail}
          qErrorsCandidate={errorsCandidate.detailsCandidateEmail}
          watch={watch("detailsCandidateEmail")}
        />
        <QuestionShortText
          question={"detailsOrganisation"}
          questionText={"* Company/Organisation:"}
          register={registerSection1}
          qErrors={errors.detailsOrganisation}
          section={"1"}
          qErrorsAssessor={errorsAssessor.detailsOrganisation}
          qErrorsCandidate={errorsCandidate.detailsOrganisation}
          watch={watch("detailsOrganisation")}
        />
        <QuestionSelect
          onSubmit={handleSubmitSection1((data) => handleSubmitOnClick())}
          question={"detailsRole"}
          questionText={"* Position/Role:"}
          register={registerSection1}
          qErrors={errors.detailsRole}
          labels={[
            ["entry1", "entry level (0-2 years exp.)"],
            ["entry2", "entry level (2+ years exp.)"],
            ["senior", "senior in the team"],
            ["manager", "manager"],
            ["seniormanager", "senior manager"],
            ["managingdirector", "managing director"],
            ["ceo", "CEO"],
          ]}
          section={"1"}
          qErrorsAssessor={errorsAssessor.detailsRole}
          qErrorsCandidate={errorsCandidate.detailsRole}
        />
        <QuestionMcqListHorizontal
          onSubmit={handleSubmitSection1((data) => handleSubmitOnClick())}
          question={"detailsGender"}
          questionText={"Please select your gender:"}
          register={registerSection1}
          qErrors={errors.detailsGender}
          labels={[
            "male",
            "female",
            "non-binary",
            "other",
            "prefer not to say",
          ]}
          section={"1"}
          qErrorsAssessor={errorsAssessor.detailsGender}
          qErrorsCandidate={errorsCandidate.detailsGender}
        />
        {environment === "assessor" ? (
          <QuestionShortText
            question={"detailsAssessor"}
            questionText={"Name of Assessor:"}
            register={registerSection1}
            qErrors={errors.detailsAssessor}
            section={"1"}
            // TODO: check if this works for the short text response, i think it should be fine
            qErrorsAssessor={errorsAssessor.detailsAssessor}
            watch={watch("detailsAssessor")}
          />
        ) : null}
        {environment === "assessor" ? (
          <QuestionDateAssessed
            onSubmit={handleSubmitSection1((data) => handleSubmitOnClick())}
            question={"detailsDateAssessed"}
            questionText={"Date Assessed:"}
            register={registerSection1}
            qErrors={errors.detailsDateAssessed}
            section={"1"}
            qErrorsAssessor={errorsAssessor.detailsDateAssessed}
          />
        ) : null}
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        {formDataBeingSent ? (
          <button type="button" className="cta cta--accent--clicked">
            Start assessment
          </button>
        ) : null}
        {environment === "assessor" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleValidateSectionAssessor}
          >
            Start assessment
          </button>
        ) : null}
        {environment === "candidate" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={() => confirmSubmissionCandidate("active")}
          >
            Start assessment
          </button>
        ) : null}
      </form>
    </ScrollToTop>
  );
}

export default withContext(Section1);
