import "./S3Upload.css";

import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import { uploadFile, deleteFile } from "react-s3";

import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { schemaFileNameInput } from "../../../validators";
import kebabFileName from "../Helpers/kebabFileName";
import { joiResolver } from "@hookform/resolvers/joi";
import Loggito from "../../../utils/Loggito";
import { ServerError } from "errors";

// npm i buffer - to solve the buffer error
window.Buffer = window.Buffer || require("buffer").Buffer;

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const S3Upload = ({ onFileUpload, title, type, currentFileName }) => {
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileTitle, setFileTitle] = useState(null);
  const [previousFileName, setPreviousFileName] = useState(
    currentFileName ? currentFileName : null
  );
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [isUploading, setIsUploading] = useState(null);

  useEffect(() => {}, [currentFileName]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaFileNameInput) });

  const logger = new Loggito("S3 Upload");

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileNameInput = (e) => {
    setFileTitle(e.target.value);
    setFileName(kebabFileName(e.target.value));
  };

  const uploadFile = (file) => {
    try {
      if (!selectedFile)
        throw new Error("A file must be selected before uploading");

      let fileNameToUpload = null;
      if (fileName) fileNameToUpload = fileName;
      else if (currentFileName)
        fileNameToUpload = currentFileName.slice(
          0,
          currentFileName.lastIndexOf(".")
        );

      if (!fileNameToUpload) throw new Error("File name cannot be left blank");

      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: S3_BUCKET,
        Key:
          fileNameToUpload +
          selectedFile.name.slice(
            selectedFile.name.lastIndexOf(".", selectedFile.name.length)
          ),
        ContentType: file.type,
      };

      if (previousFileName) {
        deleteFile(previousFileName, config)
          .then((response) => {
            logger.info(response);
          })
          .catch((err) => {
            toast.error(err);
          });
      }

      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
          setIsUploading(true);
        })
        .send((err, response) => {
          if (err) {
            toast.error(err);
          }
          if (!err) {
            setUploadedFileName(params.Key);
            setIsUploading(null);
            onFileUpload({
              fileUrl: `https://cast-app-bucket.s3.eu-west-2.amazonaws.com/${params.Key}`,
              fileName: params.Key,
              fileType: params.ContentType,
              fileTitle: fileTitle,
            });
            setPreviousFileName(params.Key);
          }
        });
    } catch (error) {
      if (error instanceof ServerError) {
        toast.error(error.message);
      } else {
        toast.warn(error.message);
      }
    }
  };

  const removeFileType = () => {
    for (let i = 0; i < currentFileName.length; i++) {
      if (currentFileName[currentFileName.length - i] === ".")
        return currentFileName.substring(0, currentFileName.length - i);
    }
  };

  return (
    <div className="s3-upload">
      <form
        onSubmit={handleSubmit((data) => uploadFile(selectedFile))}
        className="upload-form flex flex--column"
      >
        <h3 className="h3">{title ? title : "Upload File"}</h3>
        {/* {
          <p className="p--m p--bold">
            Important: File names must be unique, saving a new file with a file
            name that is in-use will overwrite the previous file
          </p>
        } */}
        {/* allow versioning to try to overcome this on S3 */}
        <div>
          <div className="p--m p--break">Upload Progress is {progress}%</div>
          {type === "file" ? (
            <input className="" type="file" onChange={handleFileInput} />
          ) : null}
          {type === "image" ? (
            <input
              className=""
              type="file"
              onChange={handleFileInput}
              accept="image/*"
            />
          ) : null}
        </div>

        {/* here the onChange needed to be in the parent div */}
        <div className="" onChange={handleFileNameInput}>
          <label htmlFor="fileName">File Name:</label>
          <input
            className="file-name-input"
            type="text"
            placeholder=""
            name="fileName"
            defaultValue={currentFileName !== "" ? removeFileType() : null}
            {...register("fileName", {})}
          />
          {errors.fileName && (
            <p className="input__error-message">{errors.fileName?.message}</p>
          )}
        </div>
        {!fileName && currentFileName && selectedFile && (
          <div className="">
            <p className="p--inline">Your file will be saved as:</p>
            <p className="p--inline file-name-input">{currentFileName}</p>
          </div>
        )}
        {fileName && selectedFile && (
          <div className="">
            <p className="p--inline">Your file will be saved as:</p>
            <p className="p--inline file-name-input">
              {fileName}
              {selectedFile.name.slice(
                selectedFile.name.lastIndexOf(".", selectedFile.name.length)
              )}
            </p>
          </div>
        )}
        {!isUploading ? (
          <button className="cta cta--primary" type="submit">
            Upload
          </button>
        ) : (
          <button className="cta cta--disabled" type="button">
            Upload
          </button>
        )}
        <p className="p--m">Uploaded Image:</p>
        {uploadedFileName ? (
          <>
            <img
              className={`${
                type === "image" ? "upload__image" : "upload__image--small"
              }`}
              src={`https://cast-app-bucket.s3.eu-west-2.amazonaws.com/${uploadedFileName}`}
              alt=""
            />
            <a
              href={`https://cast-app-bucket.s3.eu-west-2.amazonaws.com/${uploadedFileName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link to uploaded resource
            </a>
          </>
        ) : null}
        {!uploadedFileName && currentFileName ? (
          <>
            <img
              className={`${
                type === "image" ? "upload__image" : "upload__image--small"
              }`}
              src={`https://cast-app-bucket.s3.eu-west-2.amazonaws.com/${currentFileName}`}
              alt=""
            />
            <a
              href={`https://cast-app-bucket.s3.eu-west-2.amazonaws.com/${currentFileName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link to uploaded resource
            </a>
          </>
        ) : null}
      </form>
    </div>
  );
};

export default S3Upload;

/* Uploaded file properties:

lastModified: 1675436901873
lastModifiedDate: Fri Feb 03 2023 16:08:21 GMT+0100 (Central European Standard Time) {}
name: "Screenshot 2023-02-03 at 16.08.16.png"
size: 678590
type: "image/png"
webkitRelativePath: "" */
