// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";
import ScrollToTop from "../../../../utils/ScrollToTop";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionD,
  schemaFormSectionDAutomated,
  validateSectionDAssessor,
  validateSectionDAutomated,
  validateSectionDAutoSaveAutomated,
} from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionDetailsAdmin,
  QuestionMcq14Strongly,
  QuestionMcqList,
  QuestionMcqListHorizontal,
  QuestionCheckboxList,
} from "./FormQuestions";

import ConfirmSubmission from "./ConfirmSubmission";

// ========== Page ========== //

function SectionDAutomated({
  environment,
  onApproveSection,
  existingValues,
  setExistingValues,
  formButtonText,
}) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});
  const [renderErrors, setRenderErrors] = useState(0);

  const [confirmSubmissionPopup, setConfirmSubmissionPopup] = useState(false);

  const [submitted, setSubmitted] = useState(null);

  const form = useRef();
  const location = useLocation();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    return () => {
      // This function will be called when the component is unmounted
      // saveFormData(null);
      handleValidateSectionAutoSave();
    };
  }, []);

  useEffect(() => {
    if (confirmSubmissionPopup === true) handleValidateSectionCandidate();
    if (confirmSubmissionPopup === true) setSubmitted(true);
  }, [confirmSubmissionPopup]);

  let formSchema;

  if (environment === "assessor") formSchema = schemaFormSectionD;
  else if (environment === "candidate")
    formSchema = schemaFormSectionDAutomated;

  const {
    register: registerD,
    handleSubmit: handleSubmitD,
    reset,
    formState: { errors },
    getValues: getValuesD,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(formSchema),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        if (key[0] === "D") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 13 ||
      keyCode === 32 ||
      keyCode === 56 ||
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 96 && keyCode <= 111)
    ) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    // const delayFn = setTimeout(() => saveFormData(null), 3000);
    const delayFn = setTimeout(() => handleValidateSectionAutoSave(), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keyup", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  // START SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const handleUserKeyPressAfterSubmit = useCallback(
    (event) => {
      const { key, keyCode } = event;

      if (
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 13 ||
        keyCode === 32 ||
        keyCode === 56 ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 96 && keyCode <= 111)
      ) {
        if (submitted) {
          if (environment === "assessor") {
            const errors = validateSectionDAssessor(getValuesD());
            setErrorsAssessor(errors ? errors : {});
          } else if (environment === "candidate") {
            const errors = validateSectionDAutomated(getValuesD());
            setErrorsCandidate(errors ? errors : {});
          }
        }
      }
    },
    [submitted]
  );

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPressAfterSubmit);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener(
        "keyup",
        handleUserKeyPressAfterSubmit
      );
    };
  }, [handleUserKeyPressAfterSubmit]);

  // END SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    // This changes depending on form environment
    const questionNumbersArray = [
      "D1",
      "D2",
      "D3",
      "D4",
      "D5",
      "D6",
      "D6a",
      "D6b",
      "D6c",
      "D6d",
      "D6e",
      "D7",
      "D8",
      "D9",
      "D10",
    ];

    const values = getValuesD();

    let groupedValuesObject = {};

    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    // This is because the scoring of question D5 is not 0-3 and prefereable to adjust the score here than create a new component
    if (existingValues.detailsTypeOfResponse === "Candidate") {
      if (groupedValuesObject.D5.response === "0")
        groupedValuesObject.D5.score = "0";
      else if (groupedValuesObject.D5.response === "1")
        groupedValuesObject.D5.score = "1";
      else if (groupedValuesObject.D5.response === "2")
        groupedValuesObject.D5.score = "2";
      else if (groupedValuesObject.D5.response === "3")
        groupedValuesObject.D5.score = "3";
      else if (groupedValuesObject.D5.response === "4")
        groupedValuesObject.D5.score = "2";
    }

    if (approved === "approved")
      groupedValuesObject.detailsFormSectionApproved = "sectionD";

    let endpoint;

    if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    )
      endpoint = "candidateassessor";
    else if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
      groupedValuesObject.detailsStatus = "In progress";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                if (sessionStorage.token) delete sessionStorage.token;
                if (sessionStorage.candidate_token)
                  delete sessionStorage.candidate_token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          process.env.REACT_APP_LOGGITO_LEVEL === "0" &&
            toast.success("Your answers to section D have been saved");
          setExistingValues((prevValues) => ({
            ...prevValues,
            ...groupedValuesObject,
          }));
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    setSubmitted(true);
    const formValues = getValuesD();
    const errors = validateSectionDAssessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      toast.success("Your answers to section D have been saved");
      if (environment === "candidate") onApproveSection("sectionD");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesD();
    const errors = validateSectionDAutomated(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      toast.success("Your answers to section D have been saved");
      onApproveSection("sectionD");
      return;
    }
  };

  const handleValidateSectionAutoSave = () => {
    const formValues = getValuesD();
    const validationErrors = validateSectionDAutoSaveAutomated(formValues);
    if (validationErrors) {
      Object.keys(errors).forEach((key) => delete errors[key]);
      Object.keys(validationErrors).forEach(
        (key) => (validationErrors[key] = { message: validationErrors[key] })
      );
      Object.assign(errors, validationErrors);
      setRenderErrors(renderErrors + 1);
      return;
    } else {
      Object.keys(errors).forEach((key) => delete errors[key]);
      setRenderErrors(renderErrors + 1);
      saveFormData(null);
      return;
    }
  };

  const handleSubmitOnClick = () => {
    // saveFormData();
    handleValidateSectionAutoSave();
    if (submitted) {
      if (environment === "assessor") {
        const errors = validateSectionDAssessor(getValuesD());
        setErrorsAssessor(errors ? errors : {});
      } else if (environment === "candidate") {
        const errors = validateSectionDAutomated(getValuesD());
        setErrorsCandidate(errors ? errors : {});
      }
    }
  };

  const confirmSubmissionCandidate = (state) => {
    setConfirmSubmissionPopup(state);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section D");

  // ========== useEffects ========== //

  const calculateScoreD5 = (watch) => {
    const score = watch("D5");
    if (score === "0") return "0";
    if (score === "1") return "1";
    if (score === "2") return "2";
    if (score === "3") return "3";
    if (score === "4") return "2";
    return "";
  };

  const calculateScoreD6 = (watch) => {
    let total = 0;
    if (watch("D6a")) {
      total += 1;
    }
    if (watch("D6b")) {
      total += 1;
    }
    if (watch("D6c")) {
      total += 1;
    }
    if (watch("D6d")) {
      total += 1;
    }
    if (watch("D6e")) {
      total += 1;
    }
    if (total === 0) return 0;
    else if (total === 1) return 1;
    else if (total < 4) return 2;
    else if (total <= 5) return 3;
  };

  // ========== jsx ========== //

  return (
    <ScrollToTop>
      <form
        ref={form}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmitD((data) => handleSubmitOnClick());
        }}
        className="cast-form"
        noValidate={true}
      >
        <ConfirmSubmission
          confirmSubmissionPopup={confirmSubmissionPopup}
          onConfirmSubmissionCandidate={confirmSubmissionCandidate}
        />
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        <div className="cast-form__title-block">
          <h2 className="h2">Section D</h2>
          <p className="p--m">
            This section has two types of questions; some where you will be
            asked to write down your answers and some will be statements that
            you score from 0-3. Score these statements from 0-3 based on how
            much you agree or disagree with them.
            <br />
            <br />
            0 strongly disagree <br />1 disagree, <br />2 agree <br />3 strongly
            agree
            <br /> <br />
            On the first 4 questions think of the general trend rather than the
            exception to the rule.
          </p>
        </div>
        <QuestionMcq14Strongly
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D1"}
          questionText={
            "Taking responsibility tends to be a trait of successful people and blaming tends to be a trait of unsuccessful people."
          }
          register={registerD}
          qErrors={errors.D1}
          qErrorsAssessor={errorsAssessor.D1}
          qErrorsCandidate={errorsCandidate.D1}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D1"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D1_score,
              comment: errorsAssessor.D1_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"D1"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D2"}
          questionText={
            "Where you end up in life is usually dictated by the choices you make."
          }
          register={registerD}
          qErrors={errors.D2}
          qErrorsAssessor={errorsAssessor.D2}
          qErrorsCandidate={errorsCandidate.D2}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D2"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D2_score,
              comment: errorsAssessor.D2_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"D2"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D3"}
          questionText={
            "Success is not the key to happiness; happiness is the key to success."
          }
          register={registerD}
          qErrors={errors.D3}
          qErrorsAssessor={errorsAssessor.D3}
          qErrorsCandidate={errorsCandidate.D3}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D3"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D3_score,
              comment: errorsAssessor.D3_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"D3"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D4"}
          questionText={
            "I can fail many times, but not become a failure until I start to blame others."
          }
          register={registerD}
          qErrors={errors.D4}
          qErrorsAssessor={errorsAssessor.D4}
          qErrorsCandidate={errorsCandidate.D4}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D4"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D4_score,
              comment: errorsAssessor.D4_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"D4"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcqListHorizontal
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D5"}
          questionText={
            "If we take a line with pessimism at one end and optimism at the other end with realism in the middle, where do you place yourself? Please choose the answer that most fits you."
          }
          register={registerD}
          qErrors={errors.D5}
          labels={[
            "Pessimist",
            "Pessimist-Realist",
            "Realist",
            "Realist-Optimist",
            "Optimist",
          ]}
          qErrorsAssessor={errorsAssessor.D5}
          qErrorsCandidate={errorsCandidate.D5}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D5"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D5_score,
              comment: errorsAssessor.D5_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"D5"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
            calculatedScore={calculateScoreD5(watch)}
          />
        ) : null}
        <QuestionCheckboxList
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D6"}
          questionText={
            "How are you learning and developing yourself now? Tick any of the below that apply to you."
          }
          register={registerD}
          qErrors={{
            D6: errors.D6,
            D6a: errors.D6a,
            D6b: errors.D6b,
            D6c: errors.D6c,
            D6d: errors.D6d,
            D6e: errors.D6e,
          }}
          qErrorsAssessor={{
            D6: errorsAssessor.D6,
            D6a: errorsAssessor.D6a,
            D6b: errorsAssessor.D6b,
            D6c: errorsAssessor.D6c,
            D6d: errorsAssessor.D6d,
            D6e: errorsAssessor.D6e,
          }}
          qErrorsCandidate={{
            D6: errorsCandidate.D6,
            D6a: errorsCandidate.D6a,
            D6b: errorsCandidate.D6b,
            D6c: errorsCandidate.D6c,
            D6d: errorsCandidate.D6d,
            D6e: errorsCandidate.D6e,
          }}
          labels={[
            ["D6a", "Through experience on the job."],
            ["D6b", "Studying a relevant course."],
            ["D6c", "By working with a mentor/coach/senior professional."],
            ["D6d", "Reading and listening to podcasts."],
            ["D6e", "Keeping a reflective journal."],
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D6"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D6_score,
              comment: errorsAssessor.D6_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"D6"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
            calculatedScore={calculateScoreD6(watch)}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D7"}
          questionText={
            "Do you have a habit you would like to change and if so, do you have a plan to change it?	Please choose the answer that most fits you."
          }
          register={registerD}
          qErrors={errors.D7}
          qErrorsAssessor={errorsAssessor.D7}
          qErrorsCandidate={errorsCandidate.D7}
          labels={[
            "I don’t have any habits that I wish to change.",
            "I find that if I change a bad habit, I only replace it with another bad habit.",
            "I have a habit that would be good to change but I get stuck after making some progress.",
            "I have a bad habit that I am working on, it will need a plan and support.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D7"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D7_score,
              comment: errorsAssessor.D7_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"D7"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D8"}
          questionText={
            "What things in life do you feel gratitude for? Please choose the answer that most fits you."
          }
          register={registerD}
          qErrors={errors.D8}
          qErrorsAssessor={errorsAssessor.D8}
          qErrorsCandidate={errorsCandidate.D8}
          labels={[
            "I don’t think about it too much as I have got to where I am by hard work.",
            "I try to remind myself that I have been fortunate in life.",
            "I feel gratitude for having a good job and quality of life.",
            "I am thankful every day for many things in life.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D8"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D8_score,
              comment: errorsAssessor.D8_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"D8"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D9"}
          questionText={
            "What does it mean to have courage at work? Please choose the answer that most fits you."
          }
          register={registerD}
          qErrors={errors.D9}
          qErrorsAssessor={errorsAssessor.D9}
          qErrorsCandidate={errorsCandidate.D9}
          labels={[
            "Working hard and persevering when you feel like giving up.",
            "Speaking your mind to colleagues and risking a negative reaction.",
            "Holding colleagues accountable even when it means being unpopular.",
            "Taking a personal risk to challenge behaviour that does not seem right..",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D9"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D9_score,
              comment: errorsAssessor.D9_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"D9"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
          question={"D10"}
          questionText={
            "Imagine this scenario: You can't get all the work done that is expected of you. Now imagine the reason for this. What would you do in such a situation? Please choose the answer that most fits you."
          }
          register={registerD}
          qErrors={errors.D10}
          qErrorsAssessor={errorsAssessor.D10}
          qErrorsCandidate={errorsCandidate.D10}
          labels={[
            "I often get many demands put upon me, but I try to get on with it and do the best I can.",
            "It may be that I have overestimated what I can do, so I would work harder to get things done.",
            "A crisis may have arisen so I would re-prioritize and let others know that there will be a delay.",
            "If it happened more than occasionally, I would seek support to help me improve my organizational skills.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitD((data) => handleSubmitOnClick())}
            question={"D10"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerD}
            qErrorsAssessor={{
              score: errorsAssessor.D10_score,
              comment: errorsAssessor.D10_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"D10"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
          />
        ) : null}
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        {formDataBeingSent ? (
          <button type="button" className="cta cta--accent--clicked">
            {formButtonText}
          </button>
        ) : null}
        {environment === "assessor" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleValidateSectionAssessor}
          >
            {formButtonText}
          </button>
        ) : null}
        {environment === "candidate" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={() => confirmSubmissionCandidate("active")}
          >
            {formButtonText}
          </button>
        ) : null}
      </form>
    </ScrollToTop>
  );
}

export default withContext(SectionDAutomated);
