// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";
import ScrollToTop from "../../../../utils/ScrollToTop";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionB,
  schemaFormSectionBAutomated,
  validateSectionBAssessor,
  validateSectionBAutomated,
  validateSectionBAutoSaveAutomated,
} from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionDetailsAdmin,
  QuestionMcq14Strongly,
  QuestionMcqList,
  QuestionMcqYesNo,
  QuestionNumber,
  QuestionShortText,
} from "./FormQuestions";

import ConfirmSubmission from "./ConfirmSubmission";

// ========== Page ========== //

function SectionBAutomated({
  environment,
  onApproveSection,
  existingValues,
  setExistingValues,
  formButtonText,
}) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});
  const [renderErrors, setRenderErrors] = useState(0);

  const [confirmSubmissionPopup, setConfirmSubmissionPopup] = useState(false);

  const [submitted, setSubmitted] = useState(null);

  const form = useRef();
  const location = useLocation();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    return () => {
      // This function will be called when the component is unmounted
      // saveFormData(null);
      handleValidateSectionAutoSave();
    };
  }, []);

  useEffect(() => {
    if (confirmSubmissionPopup === true) handleValidateSectionCandidate();
    if (confirmSubmissionPopup === true) setSubmitted(true);
  }, [confirmSubmissionPopup]);

  let formSchema;

  if (environment === "assessor") formSchema = schemaFormSectionB;
  else if (environment === "candidate")
    formSchema = schemaFormSectionBAutomated;

  const {
    register: registerB,
    handleSubmit: handleSubmitB,
    reset,
    formState: { errors },
    getValues: getValuesB,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(formSchema),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        if (key[0] === "B") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 13 ||
      keyCode === 32 ||
      keyCode === 56 ||
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 96 && keyCode <= 111)
    ) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    // const delayFn = setTimeout(() => saveFormData(null), 3000);
    const delayFn = setTimeout(() => handleValidateSectionAutoSave(), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keyup", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  // START SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const handleUserKeyPressAfterSubmit = useCallback(
    (event) => {
      const { key, keyCode } = event;

      if (
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 13 ||
        keyCode === 32 ||
        keyCode === 56 ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 96 && keyCode <= 111)
      ) {
        if (submitted) {
          if (environment === "assessor") {
            const errors = validateSectionBAssessor(getValuesB());
            setErrorsAssessor(errors ? errors : {});
          } else if (environment === "candidate") {
            const errors = validateSectionBAutomated(getValuesB());
            setErrorsCandidate(errors ? errors : {});
          }
        }
      }
    },
    [submitted]
  );

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPressAfterSubmit);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener(
        "keyup",
        handleUserKeyPressAfterSubmit
      );
    };
  }, [handleUserKeyPressAfterSubmit]);

  // END SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    // This part changes depending on the candidate or ssessor environment
    const questionNumbersArray = [
      "B1a",
      "B1b",
      "B1c",
      "B1d",
      "B1e",
      "B1f",
      "B2a",
      "B2b",
      "B2c",
      "B2d",
      "B2e",
      "B2f",
      "B2g",
      "B2h",
      "B3",
      "B4",
      "B5",
      "B6",
      "B7",
      "B8",
      "B9",
      "B10",
      "B11",
      "B12",
    ];

    const values = getValuesB();

    let groupedValuesObject = {};
    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    console.log(values);

    if (approved === "approved")
      groupedValuesObject.detailsFormSectionApproved = "sectionB";

    let endpoint;

    if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    )
      endpoint = "candidateassessor";
    else if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
      groupedValuesObject.detailsStatus = "In progress";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                if (sessionStorage.token) delete sessionStorage.token;
                if (sessionStorage.candidate_token)
                  delete sessionStorage.candidate_token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          process.env.REACT_APP_LOGGITO_LEVEL === "0" &&
            toast.success("Your answers to section B have been saved");
          setExistingValues((prevValues) => ({
            ...prevValues,
            ...groupedValuesObject,
          }));
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    setSubmitted(true);
    const formValues = getValuesB();
    const errors = validateSectionBAssessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      toast.success("Your answers to section B have been saved");
      if (environment === "candidate") onApproveSection("sectionB");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesB();
    const errors = validateSectionBAutomated(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      toast.success("Your answers to section B have been saved");
      onApproveSection("sectionB");
      return;
    }
  };

  const handleValidateSectionAutoSave = () => {
    const formValues = getValuesB();
    const validationErrors = validateSectionBAutoSaveAutomated(formValues);
    if (validationErrors) {
      Object.keys(errors).forEach((key) => delete errors[key]);
      Object.keys(validationErrors).forEach(
        (key) => (validationErrors[key] = { message: validationErrors[key] })
      );
      Object.assign(errors, validationErrors);
      setRenderErrors(renderErrors + 1);
      return;
    } else {
      Object.keys(errors).forEach((key) => delete errors[key]);
      setRenderErrors(renderErrors + 1);
      saveFormData(null);
      return;
    }
  };

  const handleSubmitOnClick = () => {
    // saveFormData();
    handleValidateSectionAutoSave();
    if (submitted) {
      if (environment === "assessor") {
        const errors = validateSectionBAssessor(getValuesB());
        setErrorsAssessor(errors ? errors : {});
      } else if (environment === "candidate") {
        const errors = validateSectionBAutomated(getValuesB());
        setErrorsCandidate(errors ? errors : {});
      }
    }
  };

  const confirmSubmissionCandidate = (state) => {
    setConfirmSubmissionPopup(state);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section B");

  // ========== useEffects ========== //

  const calculateScoreB1 = (watch) => {
    const B1a = watch("B1a");
    const B1b = watch("B1b");
    const B1c = watch("B1c");
    const B1e = watch("B1e");

    let score = 0;

    if (parseInt(B1a) / parseInt(B1b) < 1) score += 0;
    if (parseInt(B1a) / parseInt(B1b) >= 1 && parseInt(B1a) / parseInt(B1b) < 2)
      score += 1;

    if (parseInt(B1a) / parseInt(B1b) >= 2 && parseInt(B1a) / parseInt(B1b) < 3)
      score += 2;

    if (parseInt(B1a) / parseInt(B1b) >= 3 && parseInt(B1a) / parseInt(B1b) < 5)
      score += 3;
    if (parseInt(B1a) / parseInt(B1b) >= 5) score += 4;
    if (B1c === "Yes") score += 1;
    if (B1e === "Yes") score += 1;

    return score;
  };

  const calculateScoreB2 = (watch) => {
    const B2a = watch("B2a");
    const B2b = watch("B2b");
    const B2c = watch("B2c");
    const B2e = watch("B2e");
    const B2f = watch("B2f");
    const B2g = watch("B2g");

    let score = 0;

    if (B2a) score += 1;
    if (B2b === "Yes") score += 1;
    if (B2c === "Yes") score += 1;
    if (B2e) score += 1;
    if (B2f === "Yes") score += 1;
    if (B2g === "Yes") score += 1;

    return score;
  };

  // ========== jsx ========== //

  return (
    <ScrollToTop>
      <form
        ref={form}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmitB((data) => handleSubmitOnClick());
        }}
        className="cast-form"
        noValidate={true}
      >
        <ConfirmSubmission
          confirmSubmissionPopup={confirmSubmissionPopup}
          onConfirmSubmissionCandidate={confirmSubmissionCandidate}
        />
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        <div className="cast-form__title-block">
          <h2 className="h2">Section B</h2>
          <p className="p--m">
            Through the next questions, you will be asked a bit about your life
            and the challenges you have faced.
            <br />
            &nbsp;
          </p>
        </div>
        <br />
        &nbsp;
        <div className="cast-form__title-block">
          <p className="p--m">
            <b>B1:</b> For years of full-time employment do not count part-time
            or student jobs, etc.
            <br />
            <br />
            If you have been self-employed count that as one organization even
            if you worked with several as a self-employed person.
            <br />
            <br />
            If you moved jobs, departments, or regions for the same organization
            count that as one organization
          </p>
        </div>
        <QuestionNumber
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B1a"}
          questionText={
            "How many years have you been in full-time employment? (Select a number 0-60 years)."
          }
          register={registerB}
          qErrors={errors.B1a}
          qErrorsAssessor={errorsAssessor.B1a}
          qErrorsCandidate={errorsCandidate.B1a}
          min="0"
          max="60"
          section={"B"}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B1a"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B1a_score,
              comment: errorsAssessor.B1a_comment,
            }}
          />
        ) : null}
        <QuestionNumber
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B1b"}
          questionText={
            "How many different organizations have you worked for full-time? (Select a number 0-20)."
          }
          register={registerB}
          qErrors={errors.B1b}
          qErrorsAssessor={errorsAssessor.B1b}
          qErrorsCandidate={errorsCandidate.B1b}
          min="0"
          max="20"
          // subquestion={true}
          mergedquestion={true}
          section={"B"}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B1b"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B1b_score,
              comment: errorsAssessor.B1b_comment,
            }}
          />
        ) : null}
        <QuestionMcqYesNo
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B1c"}
          questionText={
            "Have you been given additional responsibility in a job such as a team lead?"
          }
          register={registerB}
          qErrors={errors.B1c}
          qErrorsAssessor={errorsAssessor.B1c}
          qErrorsCandidate={errorsCandidate.B1c}
          // subquestion={true}
          mergedquestion={true}
          section={"B"}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B1c"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B1c_score,
              comment: errorsAssessor.B1c_comment,
            }}
          />
        ) : null}
        <QuestionShortText
          question={"B1d"}
          questionText={
            "If you answered 'yes' to the previous question, please say what it was (*optional)."
          }
          register={registerB}
          qErrors={errors.B1d}
          qErrorsAssessor={errorsAssessor.B1d}
          qErrorsCandidate={errorsCandidate.B1d}
          // subquestion={true}
          mergedquestion={true}
          watch={watch("B1d")}
          section={"B"}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B1d"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B1d_score,
              comment: errorsAssessor.B1d_comment,
            }}
          />
        ) : null}
        <QuestionMcqYesNo
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B1e"}
          questionText={"Have you been promoted to a role such as a manager?"}
          register={registerB}
          qErrors={errors.B1e}
          qErrorsAssessor={errorsAssessor.B1e}
          qErrorsCandidate={errorsCandidate.B1e}
          // subquestion={true}
          mergedquestion={true}
          section={"B"}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B1e"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B1e_score,
              comment: errorsAssessor.B1e_comment,
            }}
          />
        ) : null}
        <QuestionShortText
          question={"B1f"}
          questionText={
            "If you answered 'yes' to the previous question, please say what it was (*optional)."
          }
          register={registerB}
          qErrors={errors.B1f}
          qErrorsAssessor={errorsAssessor.B1f}
          qErrorsCandidate={errorsCandidate.B1f}
          // subquestion={true}
          mergedquestion={true}
          watch={watch("B1f")}
          section={"B"}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B1f"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B1f_score,
              comment: errorsAssessor.B1f_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <>
            &nbsp;
            <br />
            <QuestionDetailsAdmin
              question={"B1"}
              watch={watch}
              scoreExplanation={""}
              scoreGuide={
                "Average is less than 1 year = 0, 1-2 years = 1, 2-3 years = 2, 3-5 years = 3, more than 5 years = 4. An extra point is awarded if answering 'yesy' to B1c and B1e."
              }
              calculatedScore={calculateScoreB1(watch)}
              // TODO: see if it is necessary to pass existingValues or if watch is sufficient
              // existingValues={existingValues}
            />
          </>
        ) : null}
        <br />
        &nbsp;
        <div className="cast-form__title-block">
          <p className="p--m">
            <b>B2:</b> Please name 2 activities that you pursued for more than 2
            years at any time in your life, including your school years. The
            activities must be outside of the outside of the regular
            classroom/work.
            <br />
            <br />
            They can be any pursuit, including sports, music, volunteering, or
            hobbies.
            <br />
            <br />
            If you have not pursued any activity for more than two years then
            leave section B2 blank, if you have pursued only one activity for
            more than 2 years leave questions B2e - B2h blank.
            {/*    <br />
          &nbsp; */}
          </p>
        </div>
        <QuestionShortText
          question={"B2a"}
          questionText={"Activity 1"}
          register={registerB}
          qErrors={errors.B2a}
          qErrorsAssessor={errorsAssessor.B2a}
          qErrorsCandidate={errorsCandidate.B2a}
          placeholder="Activity 1..."
          // subquestion={true}
          watch={watch("B2a")}
          section={"1"}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B2a"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B2a_score,
              comment: errorsAssessor.B2a_comment,
            }}
          />
        ) : null}
        <QuestionMcqYesNo
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B2b"}
          questionText={
            "Did you make good progress in your ability in the activity?"
          }
          register={registerB}
          qErrors={errors.B2b}
          qErrorsAssessor={errorsAssessor.B2b}
          qErrorsCandidate={errorsCandidate.B2b}
          // subquestion={true}
          mergedquestion={true}
          watch={watch("B2b")}
          section={"B"}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B2b"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B2b_score,
              comment: errorsAssessor.B2b_comment,
            }}
          />
        ) : null}
        <QuestionMcqYesNo
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B2c"}
          questionText={
            "In the activity did you take on a lead role, such as a captain or co-ordinator?"
          }
          register={registerB}
          qErrors={errors.B2c}
          qErrorsAssessor={errorsAssessor.B2c}
          qErrorsCandidate={errorsCandidate.B2c}
          // subquestion={true}
          mergedquestion={true}
          section={"B"}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B2c"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B2c_score,
              comment: errorsAssessor.B2c_comment,
            }}
          />
        ) : null}
        <QuestionShortText
          question={"B2d"}
          questionText={
            "If you answered 'yes' to the previous question, please say what it was (*optional)."
          }
          register={registerB}
          qErrors={errors.B2d}
          qErrorsAssessor={errorsAssessor.B2d}
          qErrorsCandidate={errorsCandidate.B2d}
          // subquestion={true}
          mergedquestion={true}
          watch={watch("B2d")}
          section={"B"}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B2d"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B2d_score,
              comment: errorsAssessor.B2d_comment,
            }}
          />
        ) : null}
        <QuestionShortText
          question={"B2e"}
          questionText={"Activity 2"}
          register={registerB}
          qErrors={errors.B2e}
          qErrorsAssessor={errorsAssessor.B2e}
          qErrorsCandidate={errorsCandidate.B2e}
          placeholder="Activity 2..."
          // subquestion={true}
          watch={watch("B2e")}
          section={"1"}
        />
        {/*       <QuestionShortText
        question={"B2e"}
        questionText={["Activity 2"]}
        register={registerB}
        qErrors={errors.B2e}
        qErrorsAssessor={errorsAssessor.B2e}
        qErrorsCandidate={errorsCandidate.B2e}
        placeholder="Activity 2..."
        // subquestion={true}
        watch={watch("B2e")}
        section={"1"}
      /> */}
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B2e"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B2e_score,
              comment: errorsAssessor.B2e_comment,
            }}
          />
        ) : null}
        <QuestionMcqYesNo
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B2f"}
          questionText={
            "Did you make good progress in your ability in the activity?"
          }
          register={registerB}
          qErrors={errors.B2f}
          qErrorsAssessor={errorsAssessor.B2f}
          qErrorsCandidate={errorsCandidate.B2f}
          // subquestion={true}
          mergedquestion={true}
          section={"B"}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B2f"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B2f_score,
              comment: errorsAssessor.B2f_comment,
            }}
          />
        ) : null}
        <QuestionMcqYesNo
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B2g"}
          questionText={
            "In the activity did you take on a lead role, such as a captain or co-ordinator?"
          }
          register={registerB}
          qErrors={errors.B2g}
          qErrorsAssessor={errorsAssessor.B2g}
          qErrorsCandidate={errorsCandidate.B2g}
          // subquestion={true}
          mergedquestion={true}
          section={"B"}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B2g"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B2g_score,
              comment: errorsAssessor.B2g_comment,
            }}
          />
        ) : null}
        <QuestionShortText
          question={"B2h"}
          questionText={
            "If you answered 'yes' to the previous question, please say what it was (*optional)."
          }
          register={registerB}
          qErrors={errors.B2h}
          qErrorsAssessor={errorsAssessor.B2h}
          qErrorsCandidate={errorsCandidate.B2h}
          // subquestion={true}
          mergedquestion={true}
          watch={watch("B2h")}
          section={"B"}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B2h"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B2h_score,
              comment: errorsAssessor.B2h_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <>
            &nbsp;
            <br />
            <QuestionDetailsAdmin
              question={"B2"}
              watch={watch}
              scoreExplanation={""}
              scoreGuide={
                "Each activity for more than 2 years one point. Any of these in which advancement/promotion was made = 1 more point. The third point for high advancement - such as captain."
              }
              calculatedScore={calculateScoreB2(watch)}
              // TODO: see if it is necessary to pass existingValues or if watch is sufficient
              // existingValues={existingValues}
            />
            <br />
            &nbsp;
          </>
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B3"}
          questionText={
            "How would you describe yourself concerning routines at home and work? Please choose the answer that most fits you."
          }
          register={registerB}
          qErrors={errors.B3}
          qErrorsAssessor={errorsAssessor.B3}
          qErrorsCandidate={errorsCandidate.B3}
          labels={[
            "I tend to go with how I feel rather than follow a routine.",
            "I prioritize what is important rather than have regular routines.",
            "I have some routines but don’t always follow them daily.",
            "I have a clear routine at home and work but adapt when necessary.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B3"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B3_score,
              comment: errorsAssessor.B3_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"B3"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B4"}
          questionText={
            "This question is about the qualities of determination, perseverance, and purpose and whether they can be learned or developed through conscious effort. Please choose the answer that most fits you."
          }
          register={registerB}
          qErrors={errors.B4}
          qErrorsAssessor={errorsAssessor.B4}
          qErrorsCandidate={errorsCandidate.B4}
          labels={[
            "These qualities are a fixed part of our character and can’t be developed.",
            "These qualities tend to either in you or not and can’t be developed very much.",
            "Sometimes these qualities can be developed by working hard on them.",
            "With effort, a plan, and support, these qualities can usually be developed.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B4"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B4_score,
              comment: errorsAssessor.B4_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"B4"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
          />
        ) : null}
        <QuestionMcq14Strongly
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B5"}
          questionText={
            <>
              Please score the following statement from 0-3 according to how
              strongly you agree with it.
              <br />
              <br />I have shown a lot of resilience and determination in my
              life.
            </>
          }
          register={registerB}
          qErrors={errors.B5}
          qErrorsAssessor={errorsAssessor.B5}
          qErrorsCandidate={errorsCandidate.B5}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B5"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B5_score,
              comment: errorsAssessor.B5_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"B5"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B6"}
          questionText={
            "Which of the following sets of characteristics is closest to how people who know you well might describe you?"
          }
          register={registerB}
          qErrors={errors.B6}
          qErrorsAssessor={errorsAssessor.B6}
          qErrorsCandidate={errorsCandidate.B6}
          labels={[
            "Helpful, Caring, Good fun, Lively.",
            "Compassionate, Easy Going, Respectful, Kind.",
            "Kind, Humorous, Hopeful, Determined.",
            "Purposeful, Caring, Adaptable, Reliable.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B6"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B6_score,
              comment: errorsAssessor.B6_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"B6"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B7"}
          questionText={
            "What do you think of competition, in a general sense? Please choose the answer that most fits you."
          }
          register={registerB}
          qErrors={errors.B7}
          qErrorsAssessor={errorsAssessor.B7}
          qErrorsCandidate={errorsCandidate.B7}
          labels={[
            "Competition helps people boost their self-esteem.",
            "Competition is unhelpful in a professional workplace.",
            "Competition might be ok in sports, but we must be careful in how we use it at work.",
            "Competition is good when it helps people to challenge themselves and develop their abilities.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B7"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B7_score,
              comment: errorsAssessor.B7_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"B7"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B8"}
          questionText={
            "Please choose the set you think is most helpful to succeed at something."
          }
          register={registerB}
          qErrors={errors.B8}
          qErrorsAssessor={errorsAssessor.B8}
          qErrorsCandidate={errorsCandidate.B8}
          labels={[
            "Having the fortune to be born with a talent, enjoyment, and getting some lucky breaks.",
            "Being in the right place at the right time and taking the opportunity.",
            "Finding a passion and working relentlessly on it.",
            "Knowing what you want, hard work, practice, not giving up, and using support.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B8"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B8_score,
              comment: errorsAssessor.B8_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"B8"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B9"}
          questionText={
            "How would you define a genius and how do you think a person becomes a genius? Please choose the answer that most fits your view."
          }
          register={registerB}
          qErrors={errors.B9}
          qErrorsAssessor={errorsAssessor.B9}
          qErrorsCandidate={errorsCandidate.B9}
          labels={[
            "A genius is someone with an extraordinary gift that they are born with and who develops their natural talent.",
            "A genius is someone with outstanding ability and who has progressed because they see the world differently.",
            "A genius is someone who accomplishes outstanding achievements and who focuses on what they enjoy doing.",
            "A genius is someone with outstanding ability that is gained from many years of focused work and learning from others.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B9"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B9_score,
              comment: errorsAssessor.B9_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"B9"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B10"}
          questionText={
            "Can you think of a big disappointment in your life? If so, how did you respond to it? Please choose the answer that most fits you."
          }
          register={registerB}
          qErrors={errors.B10}
          qErrorsAssessor={errorsAssessor.B10}
          qErrorsCandidate={errorsCandidate.B10}
          labels={[
            "I had a big disappointment, and I think it is best to push it aside and move on.",
            "I try not to let disappointments bother me too much and I work out my strategy rather than talk about it.",
            "I had a big disappointment and recovered well by using support and working out what to do.",
            "Sometimes, time and patience are necessary and by working with others a big disappointment can be a point of growth.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B10"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B10_score,
              comment: errorsAssessor.B10_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"B10"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B11"}
          questionText={
            "This question is about hope. What does hope mean to you? Please choose the answer that most fits you."
          }
          register={registerB}
          qErrors={errors.B11}
          qErrorsAssessor={errorsAssessor.B11}
          qErrorsCandidate={errorsCandidate.B11}
          labels={[
            "Hope means wishing that things will get better.",
            "Hope means keeping the belief that things will get better.",
            "Hope means not giving up when things are difficult.",
            "Hope means having an optimistic outlook of creating a better future.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B11"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B11_score,
              comment: errorsAssessor.B11_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"B11"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
          question={"B12"}
          questionText={
            "Can you imagine what it would be like to feel hopeless? If so and you began to feel like that, what would you do?	 Please choose the answer that most fits you."
          }
          register={registerB}
          qErrors={errors.B12}
          qErrorsAssessor={errorsAssessor.B12}
          qErrorsCandidate={errorsCandidate.B12}
          labels={[
            "I have never felt hopeless and am not sure what I would do.",
            "I can imagine how awful it must be to feel hopeless; I think I would try to keep going and see if it gets better.",
            "I find it difficult to imagine how awful it would be to feel hopeless, but I think I would seek help.",
            "I have felt hopeless and found ways of working through it by using others for support.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitB((data) => handleSubmitOnClick())}
            question={"B12"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerB}
            qErrorsAssessor={{
              score: errorsAssessor.B12_score,
              comment: errorsAssessor.B12_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"B12"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation..."}
          />
        ) : null}
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        {formDataBeingSent ? (
          <button type="button" className="cta cta--accent--clicked">
            {formButtonText}
          </button>
        ) : null}
        {environment === "assessor" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleValidateSectionAssessor}
          >
            {formButtonText}
          </button>
        ) : null}
        {environment === "candidate" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={() => confirmSubmissionCandidate("active")}
          >
            {formButtonText}
          </button>
        ) : null}
      </form>
    </ScrollToTop>
  );
}

export default withContext(SectionBAutomated);
