import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Loggito from "../../../utils/Loggito";
import "./Subscribers.css";
import { getSubscribers, deleteSubscriber } from "../../../logic";
import { ServerError } from "errors";

function Subscribers() {
  const [subscribers, setSubscribers] = useState([]);

  const logger = new Loggito("Subscribers");

  const handleGetSubscribers = () => {
    getSubscribers(sessionStorage.token, (error, subscribersArray) => {
      if (error) {
        if (error instanceof ServerError) {
          toast.error(error.message);
          logger.error(error.message);
        } else {
          logger.warn(error.message);
          if (
            error.message ===
            "error 401: session timed out, please log in again"
          ) {
            toast.info("session timed out, please log in again");
            delete sessionStorage.token;
            window.location.reload(false);
          } else toast.warn(error.message);
        }
        return;
      }
      setSubscribers(subscribersArray);
      toast.success("subscribers fetched");
    });
  };

  const handleDeleteSubscriber = (event) => {
    event.preventDefault();
    deleteSubscriber(
      sessionStorage.token,
      event.target.elements.email.value,
      (error) => {
        // TODO: onload on logic is never triggered even if the delete event and response is sent correctly
        // TODO: code never reaches this far, it never returns to this function after sending
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            logger.warn(error.message);
            if (
              error.message ===
              "error 401: session timed out, please log in again"
            ) {
              toast.info("session timed out, please log in again");
              delete sessionStorage.token;
              window.location.reload(false);
            } else toast.warn(error.message);
          }
          return;
        }
        event.target.reset();
        toast.success("subscriber deleted");
        handleGetSubscribers();
      }
    );
  };

  return (
    <div>
      Subscribers
      <button className="button button--accent" onClick={handleGetSubscribers}>
        Get subscribers
      </button>
      <form action="" onSubmit={handleDeleteSubscriber}>
        <label htmlFor="deleteSubscriber">
          Enter email of subscriber to delete:
        </label>
        <input type="text" name="email" placeholder="Subscriber email" />
        <button className="button button--accent" type="submit">
          Delete subscriber
        </button>
      </form>
      {subscribers.length !== 0 && (
        <ul className="each-div-edit-event-page">
          {subscribers &&
            subscribers.map((subscriber) => {
              return (
                <li key={subscriber.email}>
                  <span className="">{subscriber.email}</span>
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
}

export default Subscribers;
