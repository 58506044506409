// ========== Imports ========== //

import "./CreateForm.css";

import { useState, useEffect } from "react";
import { createGroup, getOrganisations } from "../../../../logic";
import Loggito from "../../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import withContext from "../../../../utils/withContext";

// ========== Component ========== //

function ManageGroups({ context: { refreshTokenCount } }) {
  // ========== Hook consts ========== //

  const [organisationsData, setOrganisationsData] = useState("");
  const [selectedOrganisationData, setSelectedOrganisationData] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [group, setGroup] = useState("");
  const [date, setDate] = useState("");
  const [state, setState] = useState(0);

  // ========== other consts ========== //

  const logger = new Loggito("ManageGroups");

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  useEffect(() => {
    organisationsData &&
      setSelectedOrganisationData(
        organisationsData.find((org) => org.name === organisation)
      );
  }, [organisationsData, organisation]);

  // ========== Function to add/show input fields ========== //

  const changeState = () => {
    if (state === 0) {
      setState(1);
    } else if (state === 1) {
      setState(0);
    }
  };

  const handleChange = (input, value) => {
    if (input === "organisation") setOrganisation(value);
    else if (input === "group") setGroup(value);
    else if (input === "date") setDate(value);
    // else if (input === "date") setDate(new Date(value));
    return;
  };

  const groupOptions = selectedOrganisationData
    ? selectedOrganisationData.groups
    : null;

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getOrganisations(sessionStorage.token, (error, organisationsArray) => {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            logger.warn(error.message);
            if (
              error.message ===
              "error 401: session timed out, please log in again"
            ) {
              toast.info("session timed out, please log in again");
              delete sessionStorage.token;
              window.location.reload(false);
            } else toast.warn(error.message);
          }
          return;
        }
        setOrganisationsData(organisationsArray);
      });
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, []);

  // ========== Functions ========== //

  const handleCreateEvent = () => {
    try {
      // refreshTokenHandler();
      createGroup(
        sessionStorage.token,
        organisation,
        group,
        date,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              // TODO: check this refresh_token implementation
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                delete sessionStorage.token;
                refreshTokenCount("stop");
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          // TODO: navigate to editNews page
          toast.success("group created successfully");
          window.location.reload(false);
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  const introduceAndClose = () => {
    handleCreateEvent(organisation);
    handleChangeState();
  };

  const handleChangeState = () => {
    changeState();
    setOrganisation("");
  };

  // ========== jsx ========== //

  return (
    <div className="create-form">
      {state === 0 ? (
        <button className="cta cta--secondary" onClick={handleChangeState}>
          Create group
        </button>
      ) : null}
      {state === 1 ? (
        <div className="create-form-overlay">
          <div className="p--m p--bold create-form-overlay__content">
            <h3 className="h3">Create Group Form</h3>
            <div className="input-container">
              <label htmlFor="organisation">Organisation:</label>

              <select
                name="organisation"
                type="text"
                // value={organisation}
                onChange={(e) => handleChange("organisation", e.target.value)}
                className="input--select"
              >
                <option value="" disabled selected>
                  Select an organisation...
                </option>
                {organisationsData.map((org, index) => (
                  <option key={index} value={org.name}>
                    {org.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-container">
              <label htmlFor="group">Group:</label>

              <input
                name="group"
                type="text"
                // value={group}
                onChange={(e) => handleChange("group", e.target.value)}
                className=""
                placeholder="Name of group..."
              />
            </div>
            <div className="input-container">
              <label htmlFor="date">Date:</label>

              <input
                name="date"
                type="date"
                // value={date}
                onChange={(e) => handleChange("date", e.target.value)}
                className=""
                placeholder=""
              />
            </div>

            <div className="buttons-container">
              <button className="cta cta--primary" onClick={introduceAndClose}>
                Save
              </button>
              <button className="cta cta--outline" onClick={handleChangeState}>
                Cancel
              </button>
            </div>
            {groupOptions && groupOptions.length > 0 ? (
              <div className="groups-list-container">
                <h4 className="h4">Existing groups:</h4>
                <ul>
                  {groupOptions
                    ? groupOptions.map((group, index) => (
                        <li>{group.groupName + " - " + group.date}</li>
                      ))
                    : null}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default withContext(ManageGroups);
