const Joi = require("joi");

function validateSectionFCandidate(formValues) {
  // THE SCHEMA HERE HAS THE SAME STRUCTURE AS THE FORM SCHEMAS
  const schema = Joi.object({
    F1: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F1_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F1_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F2: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F2_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F2_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F3: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F3_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F3_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F4: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F4_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F4_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F5: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F5_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F5_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F6: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F6_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F6_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F7: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F7_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F7_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F8: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F8_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F8_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F9: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F9_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F9_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F10: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F10_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F10_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F11: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F11_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F11_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F12: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F12_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F12_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F13: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F13_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F13_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F14: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F14_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F14_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F15: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F15_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F15_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    F16: Joi.string().trim().max(1).valid("0", "1", "2", "3").messages({
      "string.base": "please select a valid response",
      "string.empty": "please select a valid response",
    }),
    F16_comment: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    F16_score: Joi.string()
      .trim()
      .max(1)
      .valid(null, "", "0", "1", "2", "3")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
  });

  const options = { abortEarly: false };

  const { error } = schema.validate(formValues, options);

  if (!error) return null;

  const errors = {};
  for (let item of error.details) {
    errors[item.path[0]] = item.message;
  }
  return errors;
}

export default validateSectionFCandidate;
