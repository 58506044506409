import { Cta } from "../Common";

import "./WhyCast.css";

function Benefits() {
  return (
    <section className="why-cast">
      <div className="section background-texture-layer">
        <div className="block">
          <div className="title-block">
            <h2 className="h2">Why CAST</h2>
          </div>
          <div className="center-container grid grid--1x2">
            <div>
              <p className="major-selling-point">8 years</p>
              <p className="p--m">
                of research carried out on the CAST tool and assessment process.
              </p>
            </div>
            <div>
              <p className="major-selling-point">£100Ks</p>
              <p className="p--m">
                saved for companies year-on-year by developing more effective
                selection and development processes.
              </p>
            </div>
          </div>
          <p className="p--m paragraph--closing">
            Results from the assessment provide important information to
            consider an applicant’s suitability for a role and potential for
            development. This can also be used to help create an Individual
            Development Plan. It is anticipated that the consistent use of the
            assessment in organizations will contribute to significant
            improvements in
          </p>

          <ul className="outcomes-list">
            <li className="p--m p--500 p--left--mobile">retention</li>
            <div className="separating-line">&nbsp;</div>
            <li className="p--m p--500 p--left--mobile">
              reduced absence from work
            </li>
            <div className="separating-line">&nbsp;</div>
            <li className="p--m p--500 p--left--mobile">engagement</li>
            <div className="separating-line">&nbsp;</div>
            <li className="p--m p--500 p--left--mobile">
              quality of performance
            </li>
            <div className="separating-line">&nbsp;</div>
            <li className="p--m p--500 p--left--mobile">
              development
              <br />
              &nbsp;
            </li>
          </ul>

          <Cta text="Make an enquiry" type="cta--accent" to="/contact" />
        </div>
      </div>
    </section>
  );
}

export default Benefits;
