// ========== Imports ========== //

import "./CASTResponsesTable.css";

import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Loggito from "../../../../utils/Loggito";
import { toast } from "react-toastify";
import { ServerError } from "errors";
import { Spinner } from "../../../Common";

import {
  CreateForm,
  DeleteForm,
  ManageOrganisations,
  ManageGroups,
  SearchResponses,
} from "./";

import { getFormResponses } from "../../../../logic";

import { Pagination } from "../../../Common";

// ========== Component ========== //

// This logger created outside of component so that it is also available to the helper printEvents
const logger = new Loggito("FormsTable");

// Receives the eventId and return the table

function CASTResultsTable({ formType }) {
  // ========== Hook consts ========== //

  const [responsesData, setResponsesData] = useState("");

  const [currentPage, setCurrentPage] = useState();

  const [dynamicSortKey, setDynamicSortKey] = useState(null);

  const [initialRetrievedResponses, setInitialRetrievedResponses] = useState(
    []
  );

  const [retrievedResponses, setRetrievedResponses] = useState([]);

  const [pageSize, setPageSize] = useState(10);

  const [category, setCategory] = useState("all");
  const [query, setQuery] = useState(null);

  // TODO: a search function based on

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return responsesData.slice(firstPageIndex, lastPageIndex);
    // the responseData dependency here is to trigger a change in table view on a change in data by sort (or by removing data on delete or searching)
  }, [currentPage, responsesData, pageSize]);
  // ========== other consts ========== //

  const handleSort = (sortKey) => {
    setDynamicSortKey(dynamicSortKey === sortKey ? "-" + sortKey : sortKey);
  };

  // TODO: when sorting the current search filters should be kept

  useEffect(() => {
    if (retrievedResponses.length > 0 && dynamicSortKey) {
      setResponsesData(
        // Here the sort function mutates the original array, so we need to make a copy of it first
        retrievedResponses.sort(dynamicSort(dynamicSortKey)).map(rowTable)
      );
      setCurrentPage(1);
    }
  }, [dynamicSortKey]);

  function dynamicSort(property) {
    // Sort with "key" string or "-key"
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      let result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const handleSearch = (query, category) => {
    setCategory(category);
    setQuery(query);

    const sortedResponses = [];

    if (
      category === "detailsFullName" ||
      category === "detailsOrganisation" ||
      category === "detailsGroup" ||
      category === "detailsStatus" ||
      category === "detailsTypeOfResponse"
    ) {
      sortedResponses.push(
        ...initialRetrievedResponses.filter((response) =>
          response[category].toLowerCase().includes(query.trim().toLowerCase())
        )
      );
      setResponsesData(sortedResponses.map(rowTable));
      setRetrievedResponses(sortedResponses);
      setCurrentPage(1);
    } else if (category === "detailsDateAssessed") {
      sortedResponses.push(
        ...initialRetrievedResponses.filter((response) =>
          response[category]?.slice(0, 10).includes(query.trim().toLowerCase())
        )
      );
      setResponsesData(sortedResponses.map(rowTable));
      setRetrievedResponses(sortedResponses);
      setCurrentPage(1);
    } else if (category === "all") {
      sortedResponses.push(
        ...initialRetrievedResponses.filter(
          (response) =>
            response.detailsFullName
              .toLowerCase()
              .includes(query.trim().toLowerCase()) ||
            response.detailsOrganisation
              .toLowerCase()
              .includes(query.trim().toLowerCase()) ||
            response.detailsGroup
              .toLowerCase()
              .includes(query.trim().toLowerCase()) ||
            response.detailsStatus
              .toLowerCase()
              .includes(query.trim().toLowerCase()) ||
            response.detailsTypeOfResponse
              .toLowerCase()
              .includes(query.trim().toLowerCase()) ||
            response.detailsDateAssessed
              ?.slice(0, 10)
              .includes(query.trim().toLowerCase())
        )
      );
      setResponsesData(sortedResponses.map(rowTable));
      setRetrievedResponses(sortedResponses);
      setCurrentPage(1);
    }
  };

  const handleClearSearch = () => {
    setCategory("all");
    setQuery(null);
    retrievedResponses.length = 0;
    retrievedResponses.push(...initialRetrievedResponses);
    setResponsesData(retrievedResponses.map(rowTable));
    setCurrentPage(1);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.info("Copied to clipboard");
  };

  function rowTable(response, index) {
    // ========== JSX ========== //

    return (
      <tr key={response.route + response.detailsAccessCode}>
        <td>
          {response.detailsFullName.length < 23
            ? response.detailsFullName
            : response.detailsFullName.slice(0, 20) + "..."}
        </td>
        <td>
          {" "}
          {response.detailsOrganisation.length < 23
            ? response.detailsOrganisation
            : response.detailsOrganisation.slice(0, 20) + "..."}
        </td>
        <td>
          {" "}
          {response.detailsGroup.length < 23
            ? response.detailsGroup
            : response.detailsGroup.slice(0, 20) + "..."}
        </td>
        <td>
          {response.detailsDateAssessed
            ? response.detailsDateCreated.slice(0, 10)
            : null}
        </td>
        <td>
          {response.detailsDateAssessed
            ? response.detailsDateAssessed.slice(0, 10)
            : null}
        </td>
        <td
          className="cursor-pointer"
          onClick={() => copyToClipboard(response.route)}
        >
          {response.route ? response.route : null}
        </td>
        <td
          className="cursor-pointer"
          onClick={() => copyToClipboard(response.detailsAccessCode)}
        >
          {response.detailsAccessCode ? response.detailsAccessCode : null}
        </td>
        <td>{response.detailsStatus ? response.detailsStatus : null}</td>
        <td>
          {(response.detailsStatus !== "Pending submission" &&
            response.detailsStatus !== "In progress") ||
          (response.detailsTypeOfResponse === "Interviewer" &&
            response.dynamicApprovedForReport) ? (
            <Link to={`/admin/report/${response.route}`} className="link">
              Edit Report
            </Link>
          ) : null}
        </td>
        <td className="buttons-td">
          {
            <>
              {response.detailsTypeOfResponse === "Candidate" && (
                <Link
                  to={`/admin/candidate-view/candidate/${response.route}`}
                  className="link"
                >
                  View
                </Link>
              )}
              {response.detailsTypeOfResponse === "Interviewer" && (
                <Link
                  to={`/admin/responses/assessor/${response.route}`}
                  className="link"
                >
                   Edit
                </Link>
              )}
              <br />
            </>
          }
        </td>
        <td className="buttons-td">
          <DeleteForm form={response} />
        </td>
      </tr>
    );
  }
  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
    try {
      getFormResponses(
        sessionStorage.token,
        formType,
        (error, responsesArray) => {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                delete sessionStorage.token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          // TODO: decide how the table should be sorted on the first render
          responsesArray.sort(dynamicSort("-detailsDateCreated"));
          // This is the array that is mutated by the sort and search functions
          setRetrievedResponses(responsesArray);
          // This is the array that is re ains unmutated to reset the retrievedResponses working array
          // Setting state from the same array links both arrays to the same memory space even though the state is a different variable
          const responsesArrayCopy = [];
          responsesArrayCopy.push(...responsesArray);
          setInitialRetrievedResponses(responsesArrayCopy);

          setResponsesData(responsesArray.map(rowTable));
          setCurrentPage(1);
        }
      );
    } catch (error) {
      toast.warn(error.message);
      logger.warn(error.message);
    }
  }, [formType]);

  // ========== JSX ========== //

  if (responsesData !== "") {
    return (
      <>
        <div className="responses-table">
          <h1>
            {formType === "Interviewer" ? "Manage Interviewer Responses" : null}
          </h1>
          <h1>
            {formType === "Candidate" ? "Manage Automated Responses" : null}
          </h1>
          <div className="manage-buttons-container">
            <ManageOrganisations />
            <ManageGroups />
            <CreateForm formType={formType} />
          </div>
          <form className="page-size-form">
            <label className="p--m" for="cars">
              Page size:&nbsp;
            </label>
            <select
              name="pageSize"
              id="pageSize"
              onChange={(e) => {
                setCurrentPage(1);
                setPageSize(parseInt(e.target.value));
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
              <option value={50}>50</option>
            </select>
          </form>
          <div className="">
            <SearchResponses
              onQuery={handleSearch}
              handleClearSearch={handleClearSearch}
            />
            {currentTableData.length === 0 ? (
              <p className="p--m p--bold p--centered">
                &nbsp;
                <br />
                Sorry, your search returned no relevant results
              </p>
            ) : null}
          </div>
          {currentTableData.length !== 0 ? (
            <div className="table-container">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th
                      className="header-sort-button"
                      onClick={() => handleSort("detailsFullName")}
                    >
                      Full Name
                    </th>
                    <th
                      className="header-sort-button"
                      onClick={() => handleSort("detailsOrganisation")}
                    >
                      Organisation
                    </th>
                    <th
                      className="header-sort-button"
                      onClick={() => handleSort("detailsGroup")}
                    >
                      Group
                    </th>
                    <th
                      className="header-sort-button"
                      onClick={() => handleSort("detailsDateCreated")}
                    >
                      Date created
                    </th>
                    <th
                      className="header-sort-button"
                      onClick={() => handleSort("detailsDateAssessed")}
                    >
                      Date Assessed
                    </th>
                    <th
                      className="header-sort-button"
                      onClick={() => handleSort("detailsAccessCode")}
                    >
                      Form ID
                    </th>
                    <th
                      className="header-sort-button"
                      onClick={() => handleSort("detailsAccessCode")}
                    >
                      Access Code
                    </th>
                    <th>Status</th>
                    <th>Report</th>
                    <th>Response</th>
                    <th>Manage</th>
                  </tr>
                </thead>
                <tbody>{currentTableData ? currentTableData : null}</tbody>
              </table>
            </div>
          ) : null}
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={responsesData.length}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </>
    );
  } else {
    return (
      <div className="responses-table">
        <h1>Manage Responses</h1>
        <CreateForm />
        <Spinner />;
      </div>
    );
  }
}

export default CASTResultsTable;
