// ========== imports ========== //
import "./EmailSubscriber.css";

import React, { useRef, useState } from "react";

// reaptch is a wrapper with additional key values when compared to reCAPTCHA
// import reCAPTCHA from "react-google-recaptcha";
import Reaptcha from "reaptcha";
import axios from "axios";
import { AiOutlineCopy } from "react-icons/ai";

import Loggito from "../../../utils/Loggito";
import withContext from "../../../utils/withContext";

import { useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
// to install $npm install @hookform/resolvers joi

import { schemaEmailSubscribersForm } from "../../../validators";

import { ServerError } from "errors";

import { getSubscribers } from "../../../logic";
import { RichTextEditorTinyEmail } from "..";
// ========== Page ========== //

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const SITE_KEY = process.env.REACT_APP_SITE_KEY;

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

function EmailSubscribers() {
  // ========== Hook consts ========== //
  // const [captchaToken, setCaptchaToken] = useState();
  const [bodyHtml, setBodyHtml] = useState();
  const [subscribersEmailArray, setSubscribersEmailArray] = useState([]);
  const [subscribersEmailArrayTest, setSubscribersEmailArrayTest] = useState(
    []
  );
  const [testEmailInputError, setTestEmailInputError] = useState();
  const [testEmailIsBeingSent, setTestEmailIsBeingSent] = useState(false);
  const [subscriberEmailIsBeingSent, setSubscriberEmailIsBeingSent] =
    useState(false);
  const form = useRef();

  const logger = new Loggito("Contact");
  // const captchaRef = useRef(null);

  /* const verify = () => {
    captchaRef.current.getResponse().then((res) => {
      setCaptchaToken(res);
    });
  }; */
  useEffect(() => {
    getSubscribers(sessionStorage.token, (error, subscribersArray) => {
      if (error) {
        if (error instanceof ServerError) {
          toast.error(error.message);
          logger.error(error.message);
        } else {
          logger.warn(error.message);
          if (
            error.message ===
            "error 401: session timed out, please log in again"
          ) {
            toast.info("session timed out, please log in again");
            delete sessionStorage.token;
            window.location.reload(false);
          } else toast.warn(error.message);
        }
        return;
      }
      const subscribersEmailsTemp = [];
      for (let i = 0; i < subscribersArray.length; i++) {
        subscribersEmailsTemp[i] = subscribersArray[i].email;
      }
      setSubscribersEmailArray(subscribersEmailsTemp);
    });
  }, []);

  const handleSaveContent = (html) => {
    setBodyHtml(html);
    toast.success("Email content saved correctly");
  };

  const emailSubscribers = async () => {
    if (!bodyHtml) {
      toast.warn(
        "Email body is empty, remember to save the content before sending"
      );
      return;
    }
    setSubscriberEmailIsBeingSent(true);
    const values = {
      subject: form.current.elements.subject.value,
      body: `${bodyHtml}`,
      subscribers: subscribersEmailArray,
      // message: ...
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/utilities/nodemailersubscribers`,
        values
      )
      .then((response) => {
        setSubscriberEmailIsBeingSent(false);
        toast.success(response.data);
      })
      .catch((e) => {
        setSubscriberEmailIsBeingSent(false);
        toast.warn(e);
      });
    setSubscriberEmailIsBeingSent(false);
  };

  const emailSubscribersTest = async () => {
    if (form.current.elements.testEmail.value === "") {
      setTestEmailInputError("Please enter a valid test email address");
      return;
    }
    if (form.current.elements.testEmail.value !== "") {
      setTestEmailInputError("");
    }
    if (
      !/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        form.current.elements.testEmail.value
      )
    ) {
      setTestEmailInputError("Please enter a valid email address");
      return;
    }
    setTestEmailIsBeingSent(true);
    const values = {
      subject: form.current.elements.subject.value,
      body: `${bodyHtml}`,
      subscribers: [form.current.elements.testEmail.value],
      // message: ...
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/utilities/nodemailersubscribers`,
        values
      )
      .then((response) => {
        setTestEmailIsBeingSent(false);
        toast.success(response.data);
      })
      .catch((e) => {
        setTestEmailIsBeingSent(false);
        toast.warn(e);
      });
    setTestEmailIsBeingSent(false);
  };

  const sendEmail = async () => {
    // const token = captchaRef.current.getValue();

    // ========== useEffects ========== //
    const values = {
      subject: form.current.elements.subject.value,
      body: bodyHtml,
      subscribers: subscribersEmailArray,
      // message: ...
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/utilities/nodemailersubscribers`,
        values
      )
      .then((response) => {
        logger.info(response);
      })
      .catch((e) => {
        toast.warn(e);
      });

    /* captchaRef.current.reset();
     */
  };

  // ========== other consts ========== //

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaEmailSubscribersForm) });

  const copyToClipboard = () => {
    const subscribersList = subscribersEmailArray.toString().replace(",", ", ");
    navigator.clipboard.writeText(subscribersList);
    toast.info("The subscribers list has been copied to clipboard");
  };

  // ========== jsx ========== //

  return (
    <div className="emailSubscriber">
      <div className="content--admin">
        <h2 className="h2">Send an email to all subscribers.</h2>
        <form
          ref={form}
          onSubmit={handleSubmit((data) => emailSubscribers())}
          className="emailSubscriber"
          noValidate={true}
        >
          <div className="block-break">
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              placeholder=""
              name="subject"
              {...register("subject", {})}
            />
            {errors.subject && (
              <p className="input__error-message">{errors.subject?.message}</p>
            )}
          </div>
          <RichTextEditorTinyEmail
            onSaveContent={handleSaveContent}
            environment="email"
          />
          {/* <reCAPTCHA
            ref={captchaRef}
            // sitekey={process.env.REACT_APP_SITE_KEY}
          /> */}
          {/* <Reaptcha
            ref={captchaRef}
            sitekey={SITE_KEY}
            onVerify={verify}
            // explicit={"check"}
          /> */}
          <div>
            <label htmlFor="testEmail p--m">Test Email:</label>
            <input
              type="text"
              placeholder=""
              name="testEmail"
              className="p--m"
              {...register("testEmail", {})}
            />
            {errors.testEmail && (
              <p className="input__error-message p--m">
                {errors.testEmail?.message}
              </p>
            )}
            {testEmailInputError ? (
              <p className="input__error-message p--m">{testEmailInputError}</p>
            ) : null}
          </div>
          {testEmailIsBeingSent ? (
            <button href="" type="button" className="cta cta--outline--clicked">
              Send test email
            </button>
          ) : null}
          {!testEmailIsBeingSent ? (
            <button
              href=""
              type="button"
              onClick={emailSubscribersTest}
              className="cta cta--outline"
            >
              Send test email
            </button>
          ) : null}
          {!subscriberEmailIsBeingSent ? (
            <button href="" type="submit" className="cta cta--primary">
              Send email to subscribers
            </button>
          ) : null}
          {subscriberEmailIsBeingSent ? (
            <button href="" type="button" className="cta cta--primary--clicked">
              Send email to subscribers
            </button>
          ) : null}
        </form>
        <h3 className="h3">Subscribers: </h3>
        <span onClick={copyToClipboard}>
          <AiOutlineCopy className="copy-icon" />
        </span>

        <ol className="">
          {subscribersEmailArray
            ? subscribersEmailArray.map((email) => {
                return <li key={email}>{email}</li>;
              })
            : null}
        </ol>
      </div>
    </div>
  );
}

export default withContext(EmailSubscribers);
