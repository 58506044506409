// ========== Imports ========== //

import "./FormAccessPage.css";

import { useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
// to install $npm install @hookform/resolvers joi
import { joiResolver } from "@hookform/resolvers/joi";
import { schemaFormAccessForm } from "../validators";
import { authenticateAccessCode } from "../logic";
import { ServerError } from "errors";
import withContext from "../utils/withContext";
import Loggito from "../utils/Loggito";

// ========== Component ========== //

function FormAccessPage({
  handleNavigationToRegister,
  handleNavigationToForm,
  context: { refreshTokenCount },
  onUpdateAccessCode,
}) {
  // ========== Hook consts ========== //

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaFormAccessForm) });

  // ========== other consts ========== //

  const logger = new Loggito("FormAccessPage");

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== Functions ========== //

  const handleLinkClick = (event) => {
    event.preventDefault();

    handleNavigationToRegister();
  };

  // ========== Function to submit form-access details and log user in while saving token ========== //
  const handleFormSubmit = (data) => {
    try {
      authenticateAccessCode(
        data.route.trim(),
        data.accessCode.trim(),
        (error, token, refresh_token) => {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
            return;
          }

          logger.debug("user logged in");

          sessionStorage.candidate_token = token;
          sessionStorage.candidate_refresh_token = refresh_token;

          refreshTokenCount("start");

          onUpdateAccessCode(data.accessCode.trim());

          handleNavigationToForm();
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };

  // ========== jsx ========== //

  return (
    <main className="form-access-page">
      <div className="form-access-elements">
        <form
          onSubmit={handleSubmit((data) => handleFormSubmit(data))}
          className="form-access-form p--m"
          noValidate={true}
        >
          <label htmlFor="route" className="label p--m p--500">
            Form ID
          </label>
          <div className="input-container">
            <input
              type="text"
              placeholder="form_XYZ..."
              name="route"
              className="p--m"
              {...register("route", {})}
            />
            {errors.route ? (
              <p className="input__error-message p--m">
                {errors.route?.message}
              </p>
            ) : null}
          </div>
          <label htmlFor="accessCode" className="label p--m p--500">
            Access Code
          </label>
          <div className="input-container">
            <input
              type="text"
              placeholder="Access code"
              name="accessCode"
              className="p--m"
              {...register("accessCode", {})}
            />
            {errors.accessCode ? (
              <p className="input__error-message p--m">
                {errors.accessCode?.message}
              </p>
            ) : null}
          </div>

          <button type="submit" className="cta cta--accent cta--submit">
            Access Form
          </button>
        </form>
      </div>
    </main>
  );
}

export default withContext(FormAccessPage);
