// ================== Imports ================== //

import "./SearchResponses.css";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Loggito from "../../../../utils/Loggito";

import { schemaSearchResponsesForm } from "../../../../validators";
// ================== Component ================== //

function SearchResponses({ onQuery, handleClearSearch }) {
  // ================== Consts ================== //
  const logger = new Loggito("Search Responses");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaSearchResponsesForm) });
  // ================== Functions ================== //

  const handleSubmitForm = (data) => {
    onQuery(data.query, data.category);
  };

  const onClearSearch = () => {
    reset();
    handleClearSearch();
  };

  logger.info("return");

  // ================== jsx ================== //

  return (
    <div className="search-responses-container">
      <form onSubmit={handleSubmit((data) => handleSubmitForm(data))}>
        <div className="category-input-container">
          <div className="category-input-select-container">
            <label className="p--m label--select" htmlFor="category">
              Choose a filter:
            </label>

            <select
              className="p--m input--select"
              {...register("category")}
              name="category"
              defaultValue={"all"}
            >
              <option value="all">All Fields</option>
              <option value="detailsFullName">Full Name</option>
              <option value="detailsOrganisation">Organisation</option>
              <option value="detailsGroup">Group</option>
              <option value="detailsDateAssessed">Date Assessed</option>
              <option value="detailsStatus">Status</option>
            </select>
          </div>
          {errors.category && (
            <p className="input__error-message">{errors.category?.message}</p>
          )}
        </div>
        <div className="search-input-container">
          <div className="p--break">
            <input
              className="p--m"
              type="string"
              placeholder="Search query..."
              name="query"
              defaultValue={""}
              {...register("query", {})}
            />
            {errors.query && (
              <p className="p--m input__error-message">
                {errors.query?.message}
              </p>
            )}
          </div>
        </div>
        <button type="submit" className="cta cta--secondary">
          Search
        </button>
        <button
          type="button"
          className="cta cta--accent"
          onClick={onClearSearch}
        >
          Clear search
        </button>
      </form>
    </div>
  );
}

export default SearchResponses;
