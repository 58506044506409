// ========== imports ========== //
import { useEffect, useState, useRef } from "react";

import { IoWarningOutline } from "react-icons/io5";

// ========== Page ========== //
function QuestionTextBox({
  question,
  questionText,
  register,
  qErrors,
  qErrorsAssessor,
  qErrorsCandidate,
  onSubmit,
  subquestion,
  mergedquestion,
  section,
  watch,
}) {
  // ========== Hook consts ========== //
  /* 
  // ALTERNATES VALUE TO TRIGGER TIMER
  // const { keyPressed, setKeyPressed } = useState(false);

  // const inputRef = useRef();

  const { keyReleased, setKeyReleased } = useState(false);

  useEffect(() => {
    // const input = document.querySelector(question);

    // input.addEventListener("keyup", setKeyReleased(!keyReleased));

    const delayFormSubmit = setTimeout(() => {
      onSubmit();
      return () => clearTimeout(delayFormSubmit);
    }, 3000);
  }, [keyReleased]);

  // const input = document.querySelector(question);
  debugger;

  // input.addEventListener("keyup", setKeyReleased(!keyReleased));
  window.addEventListener("keyup", setKeyReleased(!keyReleased));

  // input.addEventListener("keydown", clearTimeout(!keyPressed));

  // return () => clearTimeout(delayFormSubmit);
  // }, [searchTerm]);
  // }, []); */

  return (
    <div
      className={`cast-form__question-container ${
        subquestion ? "cast-form__question-container--subquestion" : null
      } ${
        mergedquestion ? "cast-form__question-container--mergedquestion" : null
      }`}
      id={`${question}_link`}
    >
      <div className="cast-form__question">
        <label className="p--m cast-form__question__qtext" htmlFor={question}>
          {typeof questionText === "string" && section !== "1" ? (
            <>
              <b>{question}</b>: {questionText}
            </>
          ) : null}
          {typeof questionText === "string" && section === "1" ? (
            <>
              <b>{questionText}</b>
            </>
          ) : null}
          {typeof questionText === "object"
            ? questionText.map((text, i) => {
                if (i === 0) {
                  return (
                    <li
                      className="cast-form__question__qtext__listitem"
                      key={
                        Date.now().toString() +
                        Math.floor(Math.random() * 1000000).toString()
                      }
                    >
                      <b>{question}</b>: {text}
                    </li>
                  );
                } else
                  return (
                    <li
                      className="cast-form__question__qtext__listitem"
                      key={
                        Date.now().toString() +
                        Math.floor(Math.random() * 1000000).toString()
                      }
                    >
                      {text}
                    </li>
                  );
              })
            : null}
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "0.5rem",
          }}
        >
          <textarea
            // ref={inputRef}
            id={question}
            {...register(question, {
              /*  onChange: (e) => {
              onSubmit();
            }, */
            })}
            className="p--m"
          />
          {watch || watch === "" ? (
            watch.length > 2000 ? (
              <span style={{ marginLeft: "8px" }} className="p--xs">
                <span style={{ color: "var(--color-accent)" }}>
                  {watch.length}
                </span>
                /2000
              </span>
            ) : (
              <span style={{ marginLeft: "8px" }} className="p--xs">
                {watch.length}/2000
              </span>
            )
          ) : null}
        </div>
        <div className="cast-form__error-message">
          {qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrors?.message}</p>
            </div>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsCandidate && !qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsCandidate}</p>
            </div>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsAssessor && !qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsAssessor}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuestionTextBox;
