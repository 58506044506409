// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";
import ScrollToTop from "../../../../utils/ScrollToTop";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionA,
  schemaFormSectionAAutomated,
  validateSectionAAssessor,
  validateSectionAAutomated,
  validateSectionAAutoSaveAutomated,
} from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionDetailsAdmin,
  QuestionMcqList,
} from "./FormQuestions";

import ConfirmSubmission from "./ConfirmSubmission";

// ========== Page ========== //

function SectionAAutomated({
  environment,
  onApproveSection,
  existingValues,
  setExistingValues,
  formButtonText,
  triggerHandleValidateSectionAssessor,
}) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});
  const [renderErrors, setRenderErrors] = useState(0);

  const [confirmSubmissionPopup, setConfirmSubmissionPopup] = useState(false);

  const [submitted, setSubmitted] = useState(null);

  const form = useRef();
  const location = useLocation();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    return () => {
      // This function will be called when the component is unmounted
      // saveFormData(null);
      handleValidateSectionAutoSave();
    };
  }, []);

  useEffect(() => {
    if (confirmSubmissionPopup === true) handleValidateSectionCandidate();
    if (confirmSubmissionPopup === true) setSubmitted(true);
  }, [confirmSubmissionPopup]);

  useEffect(() => {
    console.log("state:");
    console.log(errorsAssessor);
  }, [errorsAssessor]);

  let formSchema;

  if (environment === "assessor") formSchema = schemaFormSectionA;
  else if (environment === "candidate")
    formSchema = schemaFormSectionAAutomated;

  const {
    register: registerA,
    handleSubmit: handleSubmitA,
    reset,
    formState: { errors },
    getValues: getValuesA,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(formSchema),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        if (key[0] === "A") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
          // setValue(key, value.response, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  // START SUBMIT AFTER TYPING - THIS SUBMITS THE USER'S RESPONSE AFTER THEY STOP TYPING

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 13 ||
      keyCode === 32 ||
      keyCode === 56 ||
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 96 && keyCode <= 111)
    ) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    // const delayFn = setTimeout(() => saveFormData(null), 3000);
    const delayFn = setTimeout(() => handleValidateSectionAutoSave(), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keyup", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  // START SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const handleUserKeyPressAfterSubmit = useCallback(
    (event) => {
      const { key, keyCode } = event;

      if (
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 13 ||
        keyCode === 32 ||
        keyCode === 56 ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 96 && keyCode <= 111)
      ) {
        if (submitted) {
          if (environment === "assessor") {
            const errors = validateSectionAAssessor(getValuesA());
            setErrorsAssessor(errors ? errors : {});
          } else if (environment === "candidate") {
            const errors = validateSectionAAutomated(getValuesA());
            setErrorsCandidate(errors ? errors : {});
          }
        }
      }
    },
    [submitted]
  );

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPressAfterSubmit);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener(
        "keyup",
        handleUserKeyPressAfterSubmit
      );
    };
  }, [handleUserKeyPressAfterSubmit]);

  // END SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    // This part changes depending on the candidate or ssessor environment
    const questionNumbersArray = ["A1a", "A1b", "A2", "A3", "A4", "A5", "A6"];

    const values = getValuesA();

    let groupedValuesObject = {};

    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    if (approved === "approved")
      groupedValuesObject.detailsFormSectionApproved = "sectionA";

    console.log(groupedValuesObject);

    let endpoint;

    if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    )
      endpoint = "candidateassessor";
    else if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
      groupedValuesObject.detailsStatus = "In progress";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                if (sessionStorage.token) delete sessionStorage.token;
                if (sessionStorage.candidate_token)
                  delete sessionStorage.candidate_token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          process.env.REACT_APP_LOGGITO_LEVEL === "0" &&
            toast.success("Your answers to section A have been saved");
          setExistingValues((prevValues) => ({
            ...prevValues,
            ...groupedValuesObject,
          }));
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    setSubmitted(true);
    const formValues = getValuesA();
    const errors = validateSectionAAssessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      toast.success("Your answers to section A have been saved");
      if (environment === "candidate") onApproveSection("sectionA");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesA();
    const errors = validateSectionAAutomated(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      toast.success("Your answers to section A have been saved");
      onApproveSection("sectionA");
      return;
    }
  };

  const handleValidateSectionAutoSave = () => {
    const formValues = getValuesA();
    const validationErrors = validateSectionAAutoSaveAutomated(formValues);
    if (validationErrors) {
      Object.keys(errors).forEach((key) => delete errors[key]);
      Object.keys(validationErrors).forEach(
        (key) => (validationErrors[key] = { message: validationErrors[key] })
      );
      Object.assign(errors, validationErrors);
      setRenderErrors(renderErrors + 1);
      return;
    } else {
      Object.keys(errors).forEach((key) => delete errors[key]);
      setRenderErrors(renderErrors + 1);
      saveFormData(null);
      return;
    }
  };

  const handleSubmitOnClick = () => {
    // saveFormData();
    handleValidateSectionAutoSave();
    if (submitted) {
      if (environment === "assessor") {
        const errors = validateSectionAAssessor(getValuesA());
        setErrorsAssessor(errors ? errors : {});
      } else if (environment === "candidate") {
        const errors = validateSectionAAutomated(getValuesA());
        setErrorsCandidate(errors ? errors : {});
      }
    }
  };

  const confirmSubmissionCandidate = (state) => {
    setConfirmSubmissionPopup(state);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section A");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <ScrollToTop>
      <form
        ref={form}
        // THIS WORKS BUT THE FORM IS SUBMITTED ON PAGE LOAD
        // onSubmit={handleSubmitA((data) => saveFormData())}
        // THIS STOPS THE SAVEFORMDATA() FUNCTION BEING CALLED
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmitA((data) => handleSubmitOnClick());
        }}
        className="cast-form"
        noValidate={true}
      >
        <ConfirmSubmission
          confirmSubmissionPopup={confirmSubmissionPopup}
          onConfirmSubmissionCandidate={confirmSubmissionCandidate}
        />

        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        <div className="cast-form__title-block">
          <h2 className="h2">Section A</h2>
          <p className="p--m">
            In this section, you will be asked a series of questions about
            yourself.
          </p>
        </div>
        <QuestionMcqList
          onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
          question={"A1a"}
          questionText={"Please choose the statement that most fits you."}
          register={registerA}
          qErrors={errors.A1a}
          qErrorsAssessor={errorsAssessor.A1a}
          qErrorsCandidate={errorsCandidate.A1a}
          labels={[
            "I view my work as just a necessity of life - it pays the bills.",
            "I view my job mainly as a way of progressing to other jobs.",
            "I enjoy making a difference, but I am not sure I am in the right job.",
            "My work is one of the most important things in my life.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
            question={"A1a"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerA}
            qErrorsAssessor={{
              score: errorsAssessor.A1a_score,
              comment: errorsAssessor.A1a_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"A1a"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation of scoring goes here..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
          question={"A1b"}
          questionText={"Please choose the statement that most fits you."}
          register={registerA}
          qErrors={errors.A1b}
          qErrorsAssessor={errorsAssessor.A1b}
          qErrorsCandidate={errorsCandidate.A1b}
          labels={[
            "I don’t enjoy my work very much.",
            "I am ambitious to earn a better salary.",
            "I enjoy my work and being part of a team.",
            "I find my work to be meaningful and satisfying.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
            question={"A1b"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerA}
            qErrorsAssessor={{
              score: errorsAssessor.A1b_score,
              comment: errorsAssessor.A1b_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"A1b"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation of scoring goes here..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
          question={"A2"}
          questionText={
            "What motivates you in your work? Please choose the statement that most fits you."
          }
          register={registerA}
          qErrors={errors.A2}
          qErrorsAssessor={errorsAssessor.A2}
          qErrorsCandidate={errorsCandidate.A2}
          labels={[
            "Doing well and being praised.",
            "Advancing my career and earning a good salary.",
            "Doing a good job and helping others.",
            "Doing something that makes a difference to others and developing myself.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
            question={"A2"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerA}
            qErrorsAssessor={{
              score: errorsAssessor.A2_score,
              comment: errorsAssessor.A2_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"A2"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation of scoring goes here..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
          question={"A3"}
          questionText={
            "Think of a role model or mentor in your life (friend, family, colleague, etc.). Please choose the set of qualities that you most admire."
          }
          register={registerA}
          qErrors={errors.A3}
          qErrorsAssessor={errorsAssessor.A3}
          qErrorsCandidate={errorsCandidate.A3}
          /*  labels={[
          "a) clever and talented b) good fun and friendly c) respectful and honest.",
          "a) thoughtful and honest b) caring and compassionate c) good fun and friendly.",
          "a) caring and compassionate b) humourous and kind c) hard-working and purposeful.",
          "a) hard-working and purposeful b) determined and optimistic c) helpful and empathic.",
        ]} */
          labels={[
            "clever, talented, good fun, friendly, respectful, honest.",
            "thoughtful, honest, caring, compassionate, good fun, friendly.",
            "caring, compassionate, humourous, kind, hard-working, purposeful.",
            "hard-working, purposeful, determined, optimistic, helpful, empathic.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
            question={"A3"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerA}
            qErrorsAssessor={{
              score: errorsAssessor.A3_score,
              comment: errorsAssessor.A3_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"A3"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation of scoring goes here..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
          question={"A4"}
          questionText={
            "Which of these statements most reflects what you want to be doing in 10 years?"
          }
          register={registerA}
          qErrors={errors.A4}
          qErrorsAssessor={errorsAssessor.A4}
          qErrorsCandidate={errorsCandidate.A4}
          labels={[
            "I will do my job to the best of my ability for as long as I am working.",
            "I would like to make progress in my work but I’m not sure where that will take me.",
            "I plan to keep working hard and hopefully I will take on more responsibility.",
            "I expect to make significant progress, and I have a clear plan in place.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
            question={"A4"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerA}
            qErrorsAssessor={{
              score: errorsAssessor.A4_score,
              comment: errorsAssessor.A4_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"A4"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation of scoring goes here..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
          question={"A5"}
          questionText={
            "Think of a few words to describe the core values that you live by. Please choose the set of qualities that most fit you."
          }
          register={registerA}
          qErrors={errors.A5}
          qErrorsAssessor={errorsAssessor.A5}
          qErrorsCandidate={errorsCandidate.A5}
          labels={[
            "Humour, compassion, kindness, respect.",
            "Care, empathy, honesty, respect.",
            "Respect, compassion, determination, hope.",
            "Perseverance, kindness, accountability, and optimism.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
            question={"A5"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerA}
            qErrorsAssessor={{
              score: errorsAssessor.A5_score,
              comment: errorsAssessor.A5_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"A5"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation of scoring goes here..."}
          />
        ) : null}
        <QuestionMcqList
          onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
          question={"A6"}
          questionText={
            "Which of these statements most reflects what you would like as your life legacy?"
          }
          register={registerA}
          qErrors={errors.A6}
          qErrorsAssessor={errorsAssessor.A6}
          qErrorsCandidate={errorsCandidate.A6}
          labels={[
            "That people will remember me as an honest person with a good sense of humour.",
            "That people could trust me and come to me for help.",
            "That I was a hard-working and caring person at home and at work.",
            "That I treated everyone well and had an impact that reached many people.",
          ]}
          environment={environment}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitA((data) => handleSubmitOnClick())}
            question={"A6"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerA}
            qErrorsAssessor={{
              score: errorsAssessor.A6_score,
              comment: errorsAssessor.A6_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"A6"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"explanation of scoring goes here..."}
          />
        ) : null}
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        {formDataBeingSent ? (
          <button type="button" className="cta cta--accent--clicked">
            {formButtonText}
          </button>
        ) : null}
        {environment === "assessor" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleValidateSectionAssessor}
          >
            {formButtonText}
          </button>
        ) : null}
        {environment === "candidate" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={() => confirmSubmissionCandidate("active")}
          >
            {formButtonText}
          </button>
        ) : null}
      </form>
    </ScrollToTop>
  );
}

export default withContext(SectionAAutomated);
