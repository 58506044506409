const { FormatError } = require("errors");

const Joi = require("joi");

function validateSurveyAutoSave(formValues) {
  // THE SCHEMA HERE HAS THE SAME STRUCTURE AS THE FORM SCHEMAS

  const schema = Joi.object({
    surveyQ1: Joi.string()
      .trim()
      // .max(1)
      // .valid(null, "", "0", "1", "2", "3", "4")
      .messages({
        "string.base": "please select a valid response",
        "string.empty": "please select a valid response",
      }),
    surveyQ2: Joi.string().trim().max(2000).allow("").allow(null).messages({
      "string.base":
        "please write your response to a maximum of 2000 characters",
      "string.empty":
        "please write your response to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
  });

  const options = { abortEarly: false };

  const { error } = schema.validate(formValues, options);

  if (!error) return null;

  const errors = {};
  for (let item of error.details) {
    errors[item.path[0]] = item.message;
  }
  return errors;
}

export default validateSurveyAutoSave;
