// ========== imports ========== //

import "./ReportCommentsForm.css";

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../../utils/Loggito";
import withContext from "../../../../../utils/withContext";

import { updateCastFormResponse } from "../../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaReportCommentsForm,
  validateReportCommentsForm,
} from "../../../../../validators";

import {
  QuestionDateAssessed,
  QuestionShortText,
} from "../../FormSections/FormQuestions";

import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";

import Scattergraph4Quadrants from "./Scattergraph4Quadrants";
import BarChartReport from "./BarChartReport";

// ========== Page ========== //

function ReportCommentsForm({
  existingValues,
  scattergraphRef,
  barchartRef,
  barchartScores,
  scattergraphScores,
}) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});

  const form = useRef();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  const params = useParams();
  let route = params.route;

  const location = useLocation();

  const {
    register: registerReportComments,
    handleSubmit: handleSubmitReportCommentsForm,
    reset,
    formState: { errors },
    getValues: getValuesReportCommentsForm,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaReportCommentsForm),
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (errors) console.log(errors);
  }, [errors]);
  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      const formIdsArray = [
        "detailsFullName",
        "detailsOrganisation",
        "detailsAssessor",
        "detailsDateAssessed",
        "reportSummaryBarchart",
        "reportSummaryPersonalDevelopment",
        "reportSummaryProfessionalDevelopment",
        "reportSummaryOverall",
        "reportRecommendations",
      ];
      for (const [key, value] of Object.entries(existingValues)) {
        if (
          key.slice(0, 6) === "report" &&
          value &&
          formIdsArray.includes(key)
        ) {
          setValue(key, value, {});
        } else if (
          key.slice(0, 7) === "details" &&
          value &&
          formIdsArray.includes(key)
          /*   ) {
          if (key === "detailsDateAssessed") {
            setValue(key, value.slice(0, 10), {});
          } else setValue(key, value, {});
        } */
        ) {
          if (key === "detailsDateAssessed") {
            setValue(key, value.slice(0, 10), {});
          } else setValue(key, value, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  // START SUBMIT AFTER TYPING - THIS SUBMITS THE USER'S RESPONSE AFTER THEY STOP TYPING
  // TODO: add enter, space and delete to the keys

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 13 ||
      keyCode === 32 ||
      keyCode === 56 ||
      (keyCode >= 65 && keyCode <= 90)
    ) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    const delayFn = setTimeout(() => saveFormData(null), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keydown", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  const saveFormData = async (approved) => {
    const values = getValuesReportCommentsForm();
    // // This stops the value from being saved as null when incomnplete date sent
    if (values.detailsDateAssessed === null) delete values.detailsDateAssessed;

    const endpoint = "assessor";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        values,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                delete sessionStorage.token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          toast.success("Your answers have been saved");
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSection = () => {
    const formValues = getValuesReportCommentsForm();
    const errors = validateReportCommentsForm(formValues);
    debugger;
    if (errors) {
      setErrorsAssessor(errors);
      return;
    } else {
      setErrorsAssessor({});
      saveFormData(null);
      alert("Report comments submitted");
      return;
    }
  };

  /*   const handleValidateSectionCandidate = () => {
    const formValues = getValuesSection1();
    const errors = validateSection1Candidate(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      return;
    } else {
      setErrorsCandidate({});
      saveFormData("approved");
        toast.success("Your answers have been saved");
      onApproveSection("section1");
      return;
    }
  }; */

  // ========== other consts ========== //

  const logger = new Loggito("Report Comments Form");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <form
      ref={form}
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmitReportCommentsForm((data) => saveFormData());
      }}
      className="report-comments-form"
      noValidate={true}
    >
      {Object.keys(errors).length !== 0 && errors.constructor === Object ? (
        <div className="error-block">
          <IoWarningOutline className="icon" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <p className="p--m">
            There are questions that require your attention.
          </p>
        </div>
      ) : null}
      <div className="cast-form__title-block">
        <h3 className="h3">Report Content Form</h3>
      </div>
      <div>
        <QuestionShortText
          question={"detailsFullName"}
          questionText={"Full name of client:"}
          register={registerReportComments}
          qErrors={errors.detailsFullName}
          section={"1"}
          qErrorsAssessor={errorsAssessor.detailsFullName}
          watch={watch("detailsFullName")}
        />
        <QuestionShortText
          question={"detailsOrganisation"}
          questionText={"Company/Organisation:"}
          register={registerReportComments}
          qErrors={errors.detailsOrganisation}
          section={"1"}
          qErrorsAssessor={errorsAssessor.detailsOrganisation}
          watch={watch("detailsOrganisation")}
        />
        <QuestionShortText
          question={"detailsAssessor"}
          questionText={"Name of Assessor:"}
          register={registerReportComments}
          qErrors={errors.detailsAssessor}
          section={"1"}
          // TODO: check if this works for the short text response, i think it should be fine
          qErrorsAssessor={errorsAssessor.detailsAssessor}
          watch={watch("detailsAssessor")}
        />
        {/* TODO: this handleSubmit gives an error in this component, but it doesn't give an error in section1 and it used to work */}
        {/* The full form submit works, it's just the handleSubmit for this specific component that doesn't work */}
        <QuestionDateAssessed
          onSubmit={saveFormData}
          question={"detailsDateAssessed"}
          questionText={"Date Assessed:"}
          register={registerReportComments}
          qErrors={errors.detailsDateAssessed}
          section={"1"}
          qErrorsAssessor={errorsAssessor.detailsDateAssessed}
        />
      </div>
      <div className="barchart-container" ref={barchartRef} id="barchart">
        <BarChartReport barchartScores={barchartScores} />
      </div>
      <button
        className="cta cta--secondary"
        onClick={() =>
          exportComponentAsJPEG(barchartRef, { fileName: "barchart" })
        }
      >
        Export As JPEG
      </button>

      <div className="input-container">
        <label className="p--m" htmlFor="reportSummaryBarchart">
          Bar Chart Summary:
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <textarea
            id="reportSummaryBarchart"
            {...registerReportComments("reportSummaryBarchart", {})}
            className="p--m"
          />
          {watch("reportSummaryBarchart") ? (
            watch("reportSummaryBarchart").length > 2000 ? (
              <span
                style={{ marginLeft: "8px", marginTop: "0.4rem" }}
                className="p--xs"
              >
                <span style={{ color: "var(--color-accent)" }}>
                  {watch("reportSummaryBarchart").length}
                </span>
                /2000
              </span>
            ) : (
              <span
                style={{ marginLeft: "8px", marginTop: "0.4rem" }}
                className="p--xs"
              >
                {watch("reportSummaryBarchart").length}/2000
              </span>
            )
          ) : null}
        </div>
        {/* TODO: these errors are not registering and the form is submitting even when they don't meet the validation rules */}
        {errors.reportSummaryBarchart && (
          <div className="report-comments-form__error-message">
            <p className="input__error-message">
              {errors.reportSummaryBarchart}
            </p>
          </div>
        )}
        {errorsAssessor.reportSummaryBarchart && (
          <div className="report-comments-form__error-message">
            <p className="input__error-message">
              {errorsAssessor.reportSummaryBarchart}
            </p>
          </div>
        )}
      </div>

      <div
        className="scattergraph-container"
        ref={scattergraphRef}
        id="scattergraph"
      >
        <Scattergraph4Quadrants scattergraphScores={scattergraphScores} />
      </div>
      <button
        className="cta cta--secondary"
        onClick={() =>
          exportComponentAsJPEG(scattergraphRef, { fileName: "scattergraph" })
        }
      >
        Export As JPEG
      </button>

      <div className="input-container">
        <label className="p--m" htmlFor="reportSummaryPersonalDevelopment">
          ‘Parenting’, Personal Development Style Summary:
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <textarea
            id="reportSummaryPersonalDevelopment"
            {...registerReportComments("reportSummaryPersonalDevelopment", {})}
            className="p--m"
          />
          {watch("reportSummaryPersonalDevelopment") ? (
            watch("reportSummaryPersonalDevelopment").length > 2000 ? (
              <span
                style={{ marginLeft: "8px", marginTop: "0.4rem" }}
                className="p--xs"
              >
                <span style={{ color: "var(--color-accent)" }}>
                  {watch("reportSummaryPersonalDevelopment").length}
                </span>
                /2000
              </span>
            ) : (
              <span
                style={{ marginLeft: "8px", marginTop: "0.4rem" }}
                className="p--xs"
              >
                {watch("reportSummaryPersonalDevelopment").length}/2000
              </span>
            )
          ) : null}
        </div>
        {errors.reportSummaryPersonalDevelopment && (
          <div className="report-comments-form__error-message">
            <p className="input__error-message">
              {errors.reportSummaryPersonalDevelopment}
            </p>
          </div>
        )}
        {errorsAssessor.reportSummaryPersonalDevelopment && (
          <div className="report-comments-form__error-message">
            <p className="input__error-message">
              {errorsAssessor.reportSummaryPersonalDevelopment}
            </p>
          </div>
        )}
      </div>

      <div className="input-container">
        <label className="p--m" htmlFor="reportSummaryProfessionalDevelopment">
          Professional Development, People Management Style Summary:
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <textarea
            id="reportSummaryProfessionalDevelopment"
            {...registerReportComments(
              "reportSummaryProfessionalDevelopment",
              {}
            )}
            className="p--m"
          />
          {watch("reportSummaryProfessionalDevelopment") ? (
            watch("reportSummaryProfessionalDevelopment").length > 2000 ? (
              <span
                style={{ marginLeft: "8px", marginTop: "0.4rem" }}
                className="p--xs"
              >
                <span style={{ color: "var(--color-accent)" }}>
                  {watch("reportSummaryProfessionalDevelopment").length}
                </span>
                /2000
              </span>
            ) : (
              <span
                style={{ marginLeft: "8px", marginTop: "0.4rem" }}
                className="p--xs"
              >
                {watch("reportSummaryProfessionalDevelopment").length}/2000
              </span>
            )
          ) : null}
        </div>
        {errors.reportSummaryProfessionalDevelopment && (
          <div className="report-comments-form__error-message">
            <p className="input__error-message">
              {errors.reportSummaryProfessionalDevelopment}
            </p>
          </div>
        )}
        {errorsAssessor.reportSummaryProfessionalDevelopment && (
          <div className="report-comments-form__error-message">
            <p className="input__error-message">
              {errorsAssessor.reportSummaryProfessionalDevelopment}
            </p>
          </div>
        )}
      </div>

      <div className="input-container">
        <label className="p--m" htmlFor="reportSummaryOverall">
          Overall Summary:
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <textarea
            id="reportSummaryOverall"
            {...registerReportComments("reportSummaryOverall", {})}
            className="p--m"
          />
          {watch("reportSummaryOverall") ? (
            watch("reportSummaryOverall").length > 2000 ? (
              <span
                style={{ marginLeft: "8px", marginTop: "0.4rem" }}
                className="p--xs"
              >
                <span style={{ color: "var(--color-accent)" }}>
                  {watch("reportSummaryOverall").length}
                </span>
                /2000
              </span>
            ) : (
              <span
                style={{ marginLeft: "8px", marginTop: "0.4rem" }}
                className="p--xs"
              >
                {watch("reportSummaryOverall").length}/2000
              </span>
            )
          ) : null}
        </div>
        {errors.reportSummaryOverall && (
          <div className="report-comments-form__error-message">
            <p className="input__error-message">
              {errors.reportSummaryOverall}
            </p>
          </div>
        )}
        {errorsAssessor.reportSummaryOverall && (
          <div className="report-comments-form__error-message">
            <p className="input__error-message">
              {errorsAssessor.reportSummaryOverall}
            </p>
          </div>
        )}
      </div>

      <div className="input-container">
        <label className="p--m" htmlFor="reportRecommendations">
          Recommendations:
        </label>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <textarea
            id="reportRecommendations"
            {...registerReportComments("reportRecommendations", {})}
            className="p--m"
          />
          {watch("reportRecommendations") ? (
            watch("reportRecommendations").length > 2000 ? (
              <span
                style={{ marginLeft: "8px", marginTop: "0.4rem" }}
                className="p--xs"
              >
                <span style={{ color: "var(--color-accent)" }}>
                  {watch("reportRecommendations").length}
                </span>
                /2000
              </span>
            ) : (
              <span
                style={{ marginLeft: "8px", marginTop: "0.4rem" }}
                className="p--xs"
              >
                {watch("reportRecommendations").length}/2000
              </span>
            )
          ) : null}
        </div>
        {errors.reportRecommendations && (
          <div className="report-comments-form__error-message">
            <p className="input__error-message">
              {errors.reportRecommendations}
            </p>
          </div>
        )}
        {errorsAssessor.reportRecommendations && (
          <div className="report-comments-form__error-message">
            <p className="input__error-message">
              {errorsAssessor.reportRecommendations}
            </p>
          </div>
        )}
      </div>
      {formDataBeingSent ? (
        <button type="button" className="cta cta--accent--clicked">
          Review
        </button>
      ) : null}
      {!formDataBeingSent ? (
        <button
          type="button"
          className="cta cta--accent"
          onClick={handleValidateSection}
        >
          Review
        </button>
      ) : null}
    </form>
  );
}

export default withContext(ReportCommentsForm);
