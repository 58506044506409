// ========== Imports ========== //

import "./DeleteComment.css";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { deleteComment } from "../../../logic";
import { toast } from "react-toastify";
import Loggito from "../../../utils/Loggito";
import { ServerError } from "errors";

// ========== Component ========== //

//Receives the eventId and try to delete that one in our database
function DeleteComment({ commentId }) {
  // ========== Hook consts ========== //

  const [deleteDivStyle, setDeleteDivStyle] = useState({
    display: "none",
    position: "fixed",
    marginTop: "-50px",
    marginLeft: "-50px",
    width: "100px",
    height: "100px",
  });
  const [pageBlockStyle, setPageBlockStyle] = useState({
    display: "none",
  });

  const navigate = useNavigate();
  const location = useLocation();

  // ========== other consts ========== //

  const logger = new Loggito("DeleteComment");

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== Functions ========== //

  function refreshPage() {
    window.location.reload(false);
  }

  // ========== Function to block the screen and show the accept/decline popup ========== //

  function changeDivStatus() {
    if (deleteDivStyle.display === "none") {
      setPageBlockStyle({
        display: "block",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        background: "black",
        opacity: "50%",
      });
      setDeleteDivStyle({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        color: "var(--color-primary-text)",
        flexDirection: "column",
        boxShadow: "0 0 20px 10px hsl(0deg 0% 45%)",
        position: "fixed",
        top: "calc(50% - 50px)",
        left: "calc(50% - 50px)",
        padding: "20px",
        marginTop: "-75px",
        marginLeft: "-100px",
        width: "300px",
        height: "auto",
        borderRadius: "10px",
      });
    } else {
      setDeleteDivStyle({ display: "none" });
      setPageBlockStyle({ display: "none" });
    }
  }

  // ========== Functions ========== //

  function deleteCommentFromDatabase(commentId, location) {
    try {
      deleteComment(sessionStorage.token, commentId, (error) => {
        // TODO: onload on logic is never triggered even if the delete event and response is sent correctly
        // TODO: code never reaches this far, it never returns to this function after sending
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            logger.warn(error.message);
            if (
              error.message ===
              "error 401: session timed out, please log in again"
            ) {
              toast.info("session timed out, please log in again");
              delete sessionStorage.token;
              window.location.reload(false);
            } else toast.warn(error.message);
          }
          return;
        }
        refreshPage();
        toast.success("The comment or thread was successfully deleted");
      });
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  }

  // ========== JSX ========== //

  return (
    <div className="delete-comment">
      <button className="button button--table" onClick={changeDivStatus}>
        Delete
      </button>
      <div style={pageBlockStyle}></div>
      <div style={deleteDivStyle} className="p--m">
        <p className="h3">Are you sure? </p>
        <p className="p--m p--centered">
          You are going to delete the comment. This action cannot be undone.
        </p>
        <div className="button-container">
          <button
            className="button button--white-inverse button--delete-warning"
            onClick={() => deleteCommentFromDatabase(commentId, location)}
          >
            Yes
          </button>
          <button
            className="button button--white-inverse button--delete-warning"
            onClick={changeDivStatus}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteComment;
