// ========== imports ========== //

import React from "react";

import withContext from "../../../../utils/withContext";
import ScrollToTop from "../../../../utils/ScrollToTop";

import SubmittedScreenSurvey from "./SubmittedScreenSurvey";

// ========== Page ========== //

function SubmittedScreen({
  environment,
  onApproveSection,
  existingValues,
  setExistingValues,
  triggerHandleValidateSectionAssessor,
}) {
  // ========== Hook consts ========== //

  return (
    // change this back to a form to create the feedback survey questions
    <ScrollToTop>
      <div className="cast-form">
        {environment === "candidate" ? (
          <div className="cast-form__title-block">
            <h2 className="h2">
              Form Submitted <br />
              &nbsp;
            </h2>
            <p className="p--m">
              Thank you for completing and submitting the assessment.
              <br />
              <br />
              Your answers have been saved and the assessment administrator has
              been notified.
              <br />
              <br />
              We would appreciate it if you could answer the quick survey
              questions below, your opinions matter to us and the feedback will
              help us improve our service.
            </p>
          </div>
        ) : null}
        {existingValues.detailsFormSectionApproved === "sectionF" &&
        environment === "candidate" ? (
          <SubmittedScreenSurvey
            environment={environment}
            onApproveSection={onApproveSection || null}
            existingValues={existingValues}
            setExistingValues={setExistingValues}
            triggerHandleValidateSectionAssessor={
              triggerHandleValidateSectionAssessor
            }
          />
        ) : null}
        {environment === "candidate" &&
        existingValues.detailsFormSectionApproved === "survey" ? (
          <>
            {/* TODO: create this using css instead of nbsp */}
            <div>
              &nbsp;
              <br />
              &nbsp;
            </div>
            <div className="cast-form__title-block">
              <h2 className="h2">
                Feedback sent <br />
                &nbsp;
              </h2>
              <p className="p--m">
                Thank you for taking the time to give us your feedback. <br />{" "}
                &nbsp;
              </p>
            </div>
          </>
        ) : null}
        {environment === "assessor" ? (
          <div>
            &nbsp;
            <br />
            &nbsp;
          </div>
        ) : null}
      </div>
    </ScrollToTop>
  );
}

export default withContext(SubmittedScreen);
