import { toast } from "react-toastify";
import { ServerError } from "errors";
import Loggito from "../../../utils/Loggito";
import { refreshToken } from "../../../logic";

const logger = new Loggito("refreshTokenHandler");

const refreshTokenHandler = () => {
  refreshToken(sessionStorage.refresh_token, (error, token) => {
    if (error) {
      if (error instanceof ServerError) {
        toast.error(error.message);
        logger.error(error.message);
      } else {
        logger.warn(error.message);
        if (
          error.message === "error 401: session timed out, please log in again"
        ) {
          toast.info("session timed out, please log in again");
          delete sessionStorage.token;
          window.location.reload(false);
        } else toast.warn(error.message);
      }
    }
    sessionStorage.token = token;
  });
};

export default refreshTokenHandler;
