const { FormatError } = require("errors");

const Joi = require("joi");

function validateReportCommentsForm(formValues) {
  // THE SCHEMA HERE HAS THE SAME STRUCTURE AS THE FORM SCHEMAS

  const schema = Joi.object({
    detailsFullName: Joi.string().trim().max(100).messages({
      "string.base": "please write your full name",
      "string.empty": "please write your full name",
      "string.max": "your response must be 100 characters or less",
    }),
    detailsOrganisation: Joi.string().trim().max(100).messages({
      "string.base":
        "please write the name of the organisation that you work for",
      "string.empty":
        "please write the name of the organisation that you work for",
      "string.max": "your response must be 100 characters or less",
    }),
    detailsAssessor: Joi.string().trim().max(100).messages({
      "string.base": "please write the full name of the assessor",
      "string.empty": "please write the full name of the assessor",
      "string.max": "your response must be 100 characters or less",
    }),
    detailsDateAssessed: Joi.string()
      .trim()
      .allow("")
      .allow(null)
      .max(100)
      .messages({
        "string.base": "please select a date",
        "string.empty": "please select a date",
      }),
    reportSummaryBarchart: Joi.string().trim().max(2000).messages({
      "string.base": "please write the summary to a maximum of 2000 characters",
      "string.empty":
        "please write the summary to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    reportSummaryPersonalDevelopment: Joi.string().trim().max(2000).messages({
      "string.base": "please write the summary to a maximum of 2000 characters",
      "string.empty":
        "please write the summary to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    reportSummaryProfessionalDevelopment: Joi.string()
      .trim()
      .max(2000)
      .messages({
        "string.base":
          "please write the summary to a maximum of 2000 characters",
        "string.empty":
          "please write the summary to a maximum of 2000 characters",
        "string.max": "your response must be 2000 characters or less",
      }),
    reportSummaryOverall: Joi.string().trim().max(2000).messages({
      "string.base": "please write the summary to a maximum of 2000 characters",
      "string.empty":
        "please write the summary to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
    reportRecommendations: Joi.string().trim().max(2000).messages({
      "string.base": "please write the summary to a maximum of 2000 characters",
      "string.empty":
        "please write the summary to a maximum of 2000 characters",
      "string.max": "your response must be 2000 characters or less",
    }),
  });

  const options = { abortEarly: false };

  const { error } = schema.validate(formValues, options);

  if (!error) return null;

  const errors = {};
  for (let item of error.details) {
    errors[item.path[0]] = item.message;
  }
  return errors;
}

export default validateReportCommentsForm;
