// ========== imports ========== //

import { IoWarningOutline } from "react-icons/io5";

// ========== Page ========== //
function QuestionMcqYesNoMaybe({
  question,
  questionText,
  register,
  qErrors,
  qErrorsAssessor,
  qErrorsCandidate,
  onSubmit,
  subquestion,
  mergedquestion,
}) {
  // ========== Hook consts ========== //

  return (
    /* ASSESSOR BLOCK - OUTSIDE Q CONTAINER */
    <div
      className={`cast-form__question-container ${
        subquestion ? "cast-form__question-container--subquestion" : null
      } ${
        mergedquestion ? "cast-form__question-container--mergedquestion" : null
      }`}
      id={`${question}_link`}
    >
      <div className="cast-form__question cast-form__question--radio">
        <div className="cast-form__question__horizontal-layout">
          <p className="p--m cast-form__question__qtext">
            {<b>{question}</b>}: {questionText}
          </p>
          <div className="radio-options radio-options--yesno" id={question}>
            <div>
              <label htmlFor={question + "_Yes"} className="p--sm">
                Yes
              </label>
              <input
                className="input--radio"
                type="radio"
                id={question + "_Yes"}
                name={question}
                value={"Yes"}
                {...register(question, {
                  onChange: (e) => {
                    onSubmit();
                  },
                })}
              />
            </div>
            <div>
              <label htmlFor={question + "_No"} className="p--sm">
                No
              </label>
              <input
                className="input--radio"
                type="radio"
                id={question + "_No"}
                name={question}
                value={"No"}
                {...register(question, {
                  onChange: (e) => {
                    onSubmit();
                  },
                })}
              />
            </div>
            <div>
              <label htmlFor={question + "_Maybe"} className="p--sm">
                Maybe
              </label>
              <input
                className="input--radio"
                type="radio"
                id={question + "_Maybe"}
                name={question}
                value={"Maybe"}
                {...register(question, {
                  onChange: (e) => {
                    onSubmit();
                  },
                })}
              />
            </div>
          </div>
        </div>
        {/* ERROR BLOCK - WITHIN Q CONTAINER */}
        <div className="cast-form__error-message">
          {qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrors?.message}</p>
            </div>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsCandidate && !qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsCandidate}</p>
            </div>
          )}
        </div>
        <div className="cast-form__error-message">
          {qErrorsAssessor && !qErrors && (
            <div className="cast-form__error-message-container">
              <IoWarningOutline className="icon" />
              &nbsp;&nbsp;&nbsp;
              <p className="input__error-message">{qErrorsAssessor}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuestionMcqYesNoMaybe;
