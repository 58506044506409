// ========== Imports ========== //

import "./DeleteNews.css";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { deleteNews } from "../../../logic";
import { toast } from "react-toastify";
import Loggito from "../../../utils/Loggito";
import { ServerError } from "errors";

import { deleteFile } from "react-s3";
// import S3 from "aws-sdk/clients/s3";

import S3 from "react-aws-s3";
// ========== Component ========== //

//Receives the eventId and try to delete that one in our database
function DeleteNews({ news }) {
  // ========== Hook consts ========== //

  const [deleteDivStyle, setDeleteDivStyle] = useState({
    display: "none",
    position: "fixed",
    top: "50%",
    left: "50%",
    marginTop: "-50px",
    marginLeft: "-50px",
    width: "100px",
    height: "100px",
  });
  const [pageBlockStyle, setPageBlockStyle] = useState({
    display: "none",
  });

  const navigate = useNavigate();
  const location = useLocation();

  // ========== other consts ========== //

  const logger = new Loggito("DeleteNews");

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
  const REGION = process.env.REACT_APP_REGION;
  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

  /*   const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  }; */

  const config = {
    bucketName: S3_BUCKET,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY /* optional */,
    /* s3Url:
      "https://patrick-tomlinson-admin-774143448995.s3-accesspoint.eu-west-2.amazonaws.com/" */
  };
  const ReactS3Client = new S3(config);
  // const eventIdString = eventId.toString();

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== Functions ========== //

  function refreshPage() {
    window.location.reload(false);
  }

  // ========== Function to block the screen and show the accept/decline popup ========== //

  function changeDivStatus() {
    if (deleteDivStyle.display === "none") {
      setPageBlockStyle({
        display: "block",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        background: "black",
        opacity: "50%",
      });
      setDeleteDivStyle({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        color: "var(--color-primary-text)",
        flexDirection: "column",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: "25px",
        width: "85%",
        maxWidth: "400px",
        height: "auto",
        borderRadius: "10px",
        gap: "20px",
      });
    } else {
      setDeleteDivStyle({ display: "none" });
      setPageBlockStyle({ display: "none" });
    }
  }

  // ========== Functions ========== //

  /*   function deleteFileWrapper(fileName) {
    ReactS3Client.deleteFile(fileName, config)
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  } */

  // WORKING
  /*   function deleteFileWrapper(fileName) {
    ReactS3Client.deleteFile(fileName, config)
      .then((response) => {
        console.log("deleted");
        console.log(response);
      })
      .catch((err) => {
        console.log("caught error");
        console.error(err);
      });
  } */

  function deleteFileWrapper(fileName) {
    return new Promise((res, rej) => {
      ReactS3Client.deleteFile(fileName, config)
        .then((r) => {
          logger.info(r);
        })
        .catch((err) => {
          toast.error(err);
        });
    });
  }

  function deleteNewsFromDatabase(location) {
    try {
      // deleteNews(sessionStorage.token, news.route, (error) => {
      deleteNews(sessionStorage.token, news.route, (error) => {
        // TODO: onload on logic is never triggered even if the delete event and response is sent correctly
        // TODO: code never reaches this far, it never returns to this function after sending
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            logger.warn(error.message);
            if (
              error.message ===
              "error 401: session timed out, please log in again"
            ) {
              toast.info("session timed out, please log in again");
              delete sessionStorage.token;
              window.location.reload(false);
            } else toast.warn(error.message);
          }
          return;
        }
        // TODO: delete files from S3

        const files = [];

        if (news.file1.fileName) {
          files.push(news.file1.fileName);
        }
        if (news.file2.fileName) {
          files.push(news.file2.fileName);
        }
        if (news.file3.fileName) {
          files.push(news.file3.fileName);
        }
        if (news.mainImage.fileName) {
          files.push(news.mainImage.fileName);
        }
        //  files.forEach(async (file) => await deleteFileWrapper(file));

        /* if (files) {
          try {
            const deleteFileResponses = await Promise.all(
              files.map((file) => deleteFileWrapper(file))
            );
          } catch (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              toast.warn(error.message);
              logger.warn(error.message);
            }
          }
        } */
        if (files.length > 0) {
          const deletedFileResponses = Promise.all(
            files.map((file) => deleteFileWrapper(file))
          );
          // console.log(deletedFileResponses);
        }

        /*  if (news.file1.fileName) {
              console.log("hi file1");
              deleteFileWrapper(news.file1.fileName);
            }
            if (news.file2.fileName) {
              deleteFileWrapper(news.file2.fileName);
            }
            if (news.file3.fileName) {
              deleteFileWrapper(news.file3.fileName);
            }
            if (news.mainImage.fileName) {
              console.log("getting called but has it deleted???");
              deleteFileWrapper(news.mainImage.fileName);
            }
            deleteFileWrapper("010"); */

        // TODO: these files are not deleting from DB

        // .
        toast.success("The news item was successfully deleted");

        if (files.length === 0) refreshPage();
        else if (files.length > 0) {
          const timeoutId = setTimeout(() => {
            refreshPage();
          }, 1000);
        }
        // refreshPage();
      });
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  }

  // ========== JSX ========== //

  return (
    <div className="delete-news">
      <button className="button--table" onClick={changeDivStatus}>
        Delete
      </button>
      <div style={pageBlockStyle}></div>
      <div style={deleteDivStyle} className="p--m">
        <p className="h3">Are you sure?</p>
        <p className="p--m p--centered">
          You are going to delete the news. This action cannot be undone.
        </p>
        <div className="button-container">
          <button
            className="cta cta--accent"
            onClick={() => deleteNewsFromDatabase(location)}
          >
            Yes
          </button>
          <button className="cta cta--primary" onClick={changeDivStatus}>
            No
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteNews;
