// ========== Imports ========== //

import "./LoginPage.css";

import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sendResetPasswordCode } from "../logic";
import withContext from "../utils/withContext";
import { ServerError } from "errors";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { schemaForgotPasswordForm } from "../validators";
import Loggito from "../utils/Loggito";

// ========== Component ========== //

function ForgotPasswordPage() {
  // ========== Hook consts ========== //
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schemaForgotPasswordForm) });

  // ========== other consts ========== //

  const logger = new Loggito("ForgotPasswordPage");

  // ========== useEffects ========== //

  useEffect(() => {
    logger.info("componentDidMount");
  }, []);

  // ========== Functions ========== //

  // ========== Function to register user and redirect to login ========== //
  const handleFormSubmit = (data) => {
    debugger;
    try {
      sendResetPasswordCode(data.email.trim(), function (error) {
        if (error) {
          if (error instanceof ServerError) {
            toast.error(error.message);
            logger.error(error.message);
          } else {
            toast.warn(error.message);
            logger.warn(error.message);
          }
          return;
        }

        // form.reset();

        toast.success("Reset password code sent successfully");

        // TODO: check this is implemented correctly
        navigate("/admin/login");
      });
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }
  };
  // ========== jsx ========== //

  return (
    <main className="login-page">
      <div className="login-elements">
        <p className="p--m p--centered">
          If you have forgotten your password, a reset code will be sent by
          email with a link to reset it. Please follow the instructions
          provided.
        </p>
        <form
          onSubmit={handleSubmit((data) => handleFormSubmit(data))}
          className="login-form p--m"
          noValidate={true}
        >
          <label htmlFor="email" className="label p--m">
            Email
          </label>
          <input
            type="email"
            placeholder="Email"
            name="email"
            className="p--m"
            {...register("email", {})}
          />
          <p className="input__error-message p--m">{errors.email?.message}</p>
          <button type="submit" className="cta cta--primary cta--submit">
            Send reset code
          </button>
        </form>

        <Link to={"/admin/login"} className="cta cta--outline">
          Return to login
        </Link>
      </div>
    </main>
  );
}

export default withContext(ForgotPasswordPage);
