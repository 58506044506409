// ========== imports ========== //

import React, { useRef, useState, useEffect, useCallback } from "react";

import { useParams, useLocation } from "react-router-dom";

import useAnalyticsPageViewSenderGA4 from "../../../../utils/useAnalyticsPageViewSenderGA4";

import Loggito from "../../../../utils/Loggito";
import withContext from "../../../../utils/withContext";
import ScrollToTop from "../../../../utils/ScrollToTop";

import { updateCastFormResponse } from "../../../../logic";

import { toast } from "react-toastify";

import { IoWarningOutline } from "react-icons/io5";

import { ServerError } from "errors";

import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";

import {
  schemaFormSectionE,
  validateSectionEAssessor,
  validateSectionECandidate,
  validateSectionEAutoSave,
} from "../../../../validators";

import {
  QuestionAssessmentContainer,
  QuestionDetailsAdmin,
  QuestionMcq03,
} from "./FormQuestions";

import ConfirmSubmission from "./ConfirmSubmission";

// ========== Page ========== //

function SectionE({
  environment,
  onApproveSection,
  existingValues,
  setExistingValues,
  onUpdateErrorsAssessor,
  formButtonText,
  triggerHandleValidateSectionAssessor,
}) {
  // ========== Hook consts ========== //
  const [formDataBeingSent, setFormDataBeingSent] = useState(true);

  const [errorsAssessor, setErrorsAssessor] = useState({});
  const [errorsCandidate, setErrorsCandidate] = useState({});
  const [renderErrors, setRenderErrors] = useState(0);

  const [confirmSubmissionPopup, setConfirmSubmissionPopup] = useState(false);

  const [submitted, setSubmitted] = useState(null);

  const form = useRef();
  const location = useLocation();

  const gaPageViewSender = useAnalyticsPageViewSenderGA4();

  useEffect(() => {
    triggerHandleValidateSectionAssessor > 0 && handleValidateSectionAssessor();
  }, [triggerHandleValidateSectionAssessor]);

  useEffect(() => {
    gaPageViewSender("CAST Form Dev");
  }, []);

  useEffect(() => {
    return () => {
      // This function will be called when the component is unmounted
      // saveFormData(null);
      handleValidateSectionAutoSave();
    };
  }, []);

  useEffect(() => {
    if (confirmSubmissionPopup === true) handleValidateSectionCandidate();
    if (confirmSubmissionPopup === true) setSubmitted(true);
  }, [confirmSubmissionPopup]);

  // These useEffects are to update the state in the AssessorNav component

  useEffect(() => {
    if (environment !== "candidate")
      onUpdateErrorsAssessor(errorsAssessor, "sectionE");
  }, [errorsAssessor]);

  const {
    register: registerE,
    handleSubmit: handleSubmitE,
    reset,
    formState: { errors },
    getValues: getValuesE,
    watch,
    setValue,
  } = useForm({
    resolver: joiResolver(schemaFormSectionE),
    reValidateMode: "onChange",
  });

  // THIS WORKS - VALUES MUST BE DEALT WITH SEPARATELY FOR SETVALUE

  useEffect(() => {
    if (Object.entries(existingValues).length > 0) {
      for (const [key, value] of Object.entries(existingValues)) {
        if (key[0] === "E") {
          if (value.response) setValue(key, value.response, {});
          if (value.score) setValue(key + "_score", value.score, {});
          if (value.comment) setValue(key + "_comment", value.comment, {});
        }
      }
      setFormDataBeingSent(false);
    }
  }, [existingValues]);

  const [userText, setUserText] = useState("");

  const handleUserKeyPress = useCallback((event) => {
    const { key, keyCode } = event;

    if (
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 13 ||
      keyCode === 32 ||
      keyCode === 56 ||
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 96 && keyCode <= 111)
    ) {
      setUserText(key);
    }
  }, []);

  useEffect(() => {
    // const delayFn = setTimeout(() => saveFormData(null), 3000);
    const delayFn = setTimeout(() => handleValidateSectionAutoSave(), 3000);
    return () => clearTimeout(delayFn);
  }, [userText]);

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPress);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener("keyup", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // END SUBMIT AFTER TYPING

  // START SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const handleUserKeyPressAfterSubmit = useCallback(
    (event) => {
      const { key, keyCode } = event;

      if (
        keyCode === 8 ||
        keyCode === 9 ||
        keyCode === 13 ||
        keyCode === 32 ||
        keyCode === 56 ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 96 && keyCode <= 111)
      ) {
        if (submitted) {
          if (environment === "assessor") {
            const errors = validateSectionEAssessor(getValuesE());
            setErrorsAssessor(errors ? errors : {});
          } else if (environment === "candidate") {
            const errors = validateSectionECandidate(getValuesE());
            setErrorsCandidate(errors ? errors : {});
          }
        }
      }
    },
    [submitted]
  );

  useEffect(() => {
    // advised to create a constant variable as the form.current is likely to change before cleanup
    const formReferenced = form.current;
    formReferenced.addEventListener("keyup", handleUserKeyPressAfterSubmit);

    // cleanup on dismount component
    return () => {
      formReferenced.removeEventListener(
        "keyup",
        handleUserKeyPressAfterSubmit
      );
    };
  }, [handleUserKeyPressAfterSubmit]);

  // END SUBMIT AFTER TYPING ERROR CORRECTION AFTER SUBMIT - THIS CORRECTS ERRORS ON KEY PRESS

  const params = useParams();
  let route = params.route;

  const saveFormData = async (approved) => {
    const questionNumbersArray = [
      "E1",
      "E2",
      "E3",
      "E4",
      "E5",
      "E6",
      "E7",
      "E8",
      "E9",
      "E10",
      "E11",
      "E12",
      "E13",
      "E14",
      "E15",
      "E16",
    ];

    const values = getValuesE();

    console.log(values);

    let groupedValuesObject = {};

    questionNumbersArray.forEach((questionNumber) => {
      const scoreKey = questionNumber + "_score";
      const commentKey = questionNumber + "_comment";
      groupedValuesObject = {
        ...groupedValuesObject,
        [questionNumber]: {
          response: values[questionNumber],
          score: values[scoreKey],
          comment: values[commentKey],
        },
      };
    });

    if (approved === "approved")
      groupedValuesObject.detailsFormSectionApproved = "sectionE";

    let endpoint;

    if (
      location.pathname.slice(1).slice(0, 5) === "admin" &&
      environment === "candidate"
    )
      endpoint = "candidateassessor";
    else if (location.pathname.slice(1).slice(0, 5) === "admin") {
      endpoint = "assessor";
      groupedValuesObject.detailsStatus = "In progress";
    } else endpoint = "candidate";

    setFormDataBeingSent(true);
    try {
      updateCastFormResponse(
        sessionStorage,
        route,
        groupedValuesObject,
        endpoint,
        function (error) {
          if (error) {
            if (error instanceof ServerError) {
              toast.error(error.message);
              logger.error(error.message);
            } else {
              logger.warn(error.message);
              if (
                error.message ===
                "error 401: session timed out, please log in again"
              ) {
                toast.info("session timed out, please log in again");
                if (sessionStorage.token) delete sessionStorage.token;
                if (sessionStorage.candidate_token)
                  delete sessionStorage.candidate_token;
                window.location.reload(false);
              } else toast.warn(error.message);
            }
            return;
          }
          process.env.REACT_APP_LOGGITO_LEVEL === "0" &&
            toast.success("Your answers to section E have been saved");
          setExistingValues((prevValues) => ({
            ...prevValues,
            ...groupedValuesObject,
          }));
        }
      );
    } catch (error) {
      logger.warn(error.message);
      toast.warn(error.message);
    }

    setFormDataBeingSent(false);
  };

  // THIS SEPARATE VALIDATION HANDLES THE FINAL REVIEW TO CLOSE THE SECTION AND MOVE ONTO THE NEXT

  const handleValidateSectionAssessor = () => {
    setSubmitted(true);
    const formValues = getValuesE();
    const errors = validateSectionEAssessor(formValues);
    if (errors) {
      setErrorsAssessor(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsAssessor({});
      saveFormData(null);
      toast.success("Your answers to section E have been saved");
      if (environment === "candidate") onApproveSection("sectionE");
      return;
    }
  };

  const handleValidateSectionCandidate = () => {
    const formValues = getValuesE();
    const errors = validateSectionECandidate(formValues);
    if (errors) {
      setErrorsCandidate(errors);
      // Scroll to the element with the redirect ID
      const keys = Object.keys(errors);
      const redirectId = keys[0] + "_link";
      const element = document.getElementById(redirectId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      return;
    } else {
      // HERE THE DATA SHOULD BE SAVED ONE FINAL TIME AND THEN THE FOLLWING STEPS, CLOSING THE SECTION AND MOVING ONTO THE FOLLOWING SECTION...
      setErrorsCandidate({});
      saveFormData("approved");
      toast.success("Your answers to section E have been saved");
      onApproveSection("sectionE");
      return;
    }
  };

  const handleValidateSectionAutoSave = () => {
    const formValues = getValuesE();
    const validationErrors = validateSectionEAutoSave(formValues);
    if (validationErrors) {
      Object.keys(errors).forEach((key) => delete errors[key]);
      Object.keys(validationErrors).forEach(
        (key) => (validationErrors[key] = { message: validationErrors[key] })
      );
      Object.assign(errors, validationErrors);
      setRenderErrors(renderErrors + 1);
      return;
    } else {
      Object.keys(errors).forEach((key) => delete errors[key]);
      setRenderErrors(renderErrors + 1);
      saveFormData(null);
      return;
    }
  };

  const handleSubmitOnClick = () => {
    // saveFormData();
    handleValidateSectionAutoSave();
    if (submitted) {
      if (environment === "assessor") {
        const errors = validateSectionEAssessor(getValuesE());
        setErrorsAssessor(errors ? errors : {});
      } else if (environment === "candidate") {
        const errors = validateSectionECandidate(getValuesE());
        setErrorsCandidate(errors ? errors : {});
      }
    }
  };

  const confirmSubmissionCandidate = (state) => {
    setConfirmSubmissionPopup(state);
  };

  // ========== other consts ========== //

  const logger = new Loggito("Form section E");

  // ========== useEffects ========== //

  // ========== jsx ========== //

  return (
    <ScrollToTop>
      <form
        ref={form}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmitE((data) => handleSubmitOnClick());
        }}
        className="cast-form"
        noValidate={true}
      >
        <ConfirmSubmission
          confirmSubmissionPopup={confirmSubmissionPopup}
          onConfirmSubmissionCandidate={confirmSubmissionCandidate}
        />
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        {environment === "assessor" ? (
          <div className="cast-form__title-block">
            <h2 className="h2">Section E</h2>
            <p className="p--m">
              SUPPORTIVE - DEMANDING SCALES (‘Parenting’/Personal Development)
              <br />
              <br />
              In this next section, you will be asked about parenting. This is
              not specifically about your own parents, but rather what you think
              is the most suitable way of parenting. In a general sense,
              parenting can also be thought of in terms of enabling development
              and therefore has something in common with leadership, management,
              and mentoring. You will be presented with a set of statements.
              Please think about how much each statement is likely to support or
              hinder development.
              <br />
              <br />
              I am going to read you a set of statements. I want you to think
              about the essence of each statement and how it is likely to help
              or hinder development. Score each statement from 0-3 according to
              how well they reflect qualities that are likely to help or hinder
              the development of a child. Think in a general sense and not so
              much about the age of a child.
              <br />
              <br />
              0 = may set back development <br />1 = unhelpful for development{" "}
              <br />2 = helpful for development <br />3 = very helpful for
              development.
            </p>
          </div>
        ) : null}
        {environment === "candidate" ? (
          <div className="cast-form__title-block">
            <h2 className="h2">Section E</h2>
            <p className="p--m">
              In this next section, you will be asked about parenting. This is
              not specifically about your own parents, but rather what you think
              is the most suitable way of parenting. In a general sense,
              parenting can also be thought of in terms of enabling development
              and therefore has something in common with leadership, management,
              and mentoring. You will be presented with a set of statements.
              Please think about how much each statement is likely to support or
              hinder development.
              <br />
              <br />
              Don't get too concerned about the issue of how old the
              subject/person may be. Focus on the essence of what is being
              stated.
              <br />
              <br />
              Score these statements from 0-3 according to how well they reflect
              qualities that are likely to support or hinder development.
              <br />
              <br />
              0 = may set back development <br />1 = unhelpful for development{" "}
              <br />2 = helpful for development <br />3 = very helpful for
              development.
            </p>
          </div>
        ) : null}

        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E1"}
          questionText={"I can count on my parents if I have a problem."}
          register={registerE}
          qErrors={errors.E1}
          qErrorsAssessor={errorsAssessor.E1}
          qErrorsCandidate={errorsCandidate.E1}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E1"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"supportive"}
            qErrorsAssessor={{
              score: errorsAssessor.E1_score,
              comment: errorsAssessor.E1_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E1"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E2"}
          questionText={
            "My parents expect me to follow family rules about daily life."
          }
          register={registerE}
          qErrors={errors.E2}
          qErrorsAssessor={errorsAssessor.E2}
          qErrorsCandidate={errorsCandidate.E2}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E2"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"demanding"}
            qErrorsAssessor={{
              score: errorsAssessor.E2_score,
              comment: errorsAssessor.E2_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E2"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E3"}
          questionText={"My parents spend time just talking and being with me."}
          register={registerE}
          qErrors={errors.E3}
          qErrorsAssessor={errorsAssessor.E3}
          qErrorsCandidate={errorsCandidate.E3}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E3"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"supportive"}
            qErrorsAssessor={{
              score: errorsAssessor.E3_score,
              comment: errorsAssessor.E3_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E3"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E4"}
          questionText={"My parents don't like me to tell them my troubles."}
          register={registerE}
          qErrors={errors.E4}
          reversed={true}
          qErrorsAssessor={errorsAssessor.E4}
          qErrorsCandidate={errorsCandidate.E4}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E4"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"unsupportive"}
            qErrorsAssessor={{
              score: errorsAssessor.E4_score,
              comment: errorsAssessor.E4_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E4"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E5"}
          questionText={"My parents hardly ever praise me for doing well."}
          register={registerE}
          qErrors={errors.E5}
          reversed={true}
          qErrorsAssessor={errorsAssessor.E5}
          qErrorsCandidate={errorsCandidate.E5}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E5"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"unsupportive"}
            qErrorsAssessor={{
              score: errorsAssessor.E5_score,
              comment: errorsAssessor.E5_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E5"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E6"}
          questionText={
            "My parents believe I have a right to my point of view."
          }
          register={registerE}
          qErrors={errors.E6}
          qErrorsAssessor={errorsAssessor.E6}
          qErrorsCandidate={errorsCandidate.E6}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E6"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"supportive"}
            qErrorsAssessor={{
              score: errorsAssessor.E6_score,
              comment: errorsAssessor.E6_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E6"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E7"}
          questionText={
            "My parents tell me that their ideas are correct and I shouldn’t question them."
          }
          register={registerE}
          qErrors={errors.E7}
          qErrorsAssessor={errorsAssessor.E7}
          qErrorsCandidate={errorsCandidate.E7}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E7"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"unsupportive"}
            qErrorsAssessor={{
              score: errorsAssessor.E7_score,
              comment: errorsAssessor.E7_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E7"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E8"}
          questionText={"My parents often let me get away with things."}
          register={registerE}
          qErrors={errors.E8}
          qErrorsAssessor={errorsAssessor.E8}
          qErrorsCandidate={errorsCandidate.E8}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E8"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"undemanding"}
            qErrorsAssessor={{
              score: errorsAssessor.E8_score,
              comment: errorsAssessor.E8_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E8"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E9"}
          questionText={
            "My parents are respectful and thoughtful towards my privacy."
          }
          register={registerE}
          qErrors={errors.E9}
          qErrorsAssessor={errorsAssessor.E9}
          qErrorsCandidate={errorsCandidate.E9}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E9"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"supportive"}
            qErrorsAssessor={{
              score: errorsAssessor.E9_score,
              comment: errorsAssessor.E9_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E9"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E10"}
          questionText={
            "My parents don’t give me many choices and make most of the decisions about what I can do."
          }
          register={registerE}
          qErrors={errors.E10}
          qErrorsAssessor={errorsAssessor.E10}
          qErrorsCandidate={errorsCandidate.E10}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E10"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"unsupportive"}
            qErrorsAssessor={{
              score: errorsAssessor.E10_score,
              comment: errorsAssessor.E10_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E10"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E11"}
          questionText={"My parents point out ways I could do better."}
          register={registerE}
          qErrors={errors.E11}
          qErrorsAssessor={errorsAssessor.E11}
          qErrorsCandidate={errorsCandidate.E11}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E11"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"demanding"}
            qErrorsAssessor={{
              score: errorsAssessor.E11_score,
              comment: errorsAssessor.E11_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E11"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E12"}
          questionText={
            "When I do something wrong, my parents don't have any consequences or talk with me about it."
          }
          register={registerE}
          qErrors={errors.E12}
          qErrorsAssessor={errorsAssessor.E12}
          qErrorsCandidate={errorsCandidate.E12}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E12"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"undemanding"}
            qErrorsAssessor={{
              score: errorsAssessor.E12_score,
              comment: errorsAssessor.E12_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E12"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E13"}
          questionText={
            "My parents expect me to try my best even when it’s hard."
          }
          register={registerE}
          qErrors={errors.E13}
          qErrorsAssessor={errorsAssessor.E13}
          qErrorsCandidate={errorsCandidate.E13}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E13"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"demanding"}
            qErrorsAssessor={{
              score: errorsAssessor.E13_score,
              comment: errorsAssessor.E13_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E13"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E14"}
          questionText={
            "I don’t have a curfew or need to tell my parents where I am going."
          }
          register={registerE}
          qErrors={errors.E14}
          qErrorsAssessor={errorsAssessor.E14}
          qErrorsCandidate={errorsCandidate.E14}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E14"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"undemanding"}
            qErrorsAssessor={{
              score: errorsAssessor.E14_score,
              comment: errorsAssessor.E14_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E14"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E15"}
          questionText={"My parents expect me to do chores around the house."}
          register={registerE}
          qErrors={errors.E15}
          qErrorsAssessor={errorsAssessor.E15}
          qErrorsCandidate={errorsCandidate.E15}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E15"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"demanding"}
            qErrorsAssessor={{
              score: errorsAssessor.E15_score,
              comment: errorsAssessor.E15_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E15"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Normal scoring"}
          />
        ) : null}
        <QuestionMcq03
          onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
          question={"E16"}
          questionText={
            "My parents do not mind whether I hand in my homework on time."
          }
          register={registerE}
          qErrors={errors.E16}
          qErrorsAssessor={errorsAssessor.E16}
          qErrorsCandidate={errorsCandidate.E16}
          reversed={true}
        />
        {environment === "assessor" ? (
          <QuestionAssessmentContainer
            onSubmit={handleSubmitE((data) => handleSubmitOnClick())}
            question={"E16"}
            watch={watch}
            scoreType={"Automatically scored"}
            scoreExplanation={""}
            register={registerE}
            category={"undemanding"}
            qErrorsAssessor={{
              score: errorsAssessor.E16_score,
              comment: errorsAssessor.E16_comment,
            }}
          />
        ) : null}
        {environment === "candidate" &&
        location.pathname.slice(1).slice(0, 5) === "admin" ? (
          <QuestionDetailsAdmin
            question={"E16"}
            watch={watch}
            scoreExplanation={""}
            scoreGuide={"Reversed scoring"}
          />
        ) : null}
        {(Object.keys(errorsAssessor).length !== 0 &&
          errorsAssessor.constructor === Object) ||
        (Object.keys(errorsCandidate).length !== 0 &&
          errorsCandidate.constructor === Object) ? (
          <div className="error-block">
            <IoWarningOutline className="icon" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p className="p--m">
              There are questions that require your attention.
            </p>
          </div>
        ) : null}
        {formDataBeingSent ? (
          <button type="button" className="cta cta--accent--clicked">
            {formButtonText}
          </button>
        ) : null}
        {environment === "assessor" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={handleValidateSectionAssessor}
          >
            {formButtonText}
          </button>
        ) : null}
        {environment === "candidate" && !formDataBeingSent ? (
          <button
            type="button"
            className="cta cta--accent"
            onClick={() => confirmSubmissionCandidate("active")}
          >
            {formButtonText}
          </button>
        ) : null}
      </form>
    </ScrollToTop>
  );
}

export default withContext(SectionE);
