import Joi from "joi";

const schemaSearchResponsesForm = Joi.object({
  query: Joi.string().trim().allow("").messages({
    "string.base": "please enter a valid query",
    "string.empty": "please enter a query",
  }),
  category: Joi.string()
    .valid(
      "all",
      "detailsFullName",
      "detailsOrganisation",
      "detailsGroup",
      "detailsDateAssessed",
      "detailsStatus"
    )
    .messages({
      "string.base": "please select a valid field",
      "string.empty": "please select a field",
    }),
});

export default schemaSearchResponsesForm;
